<template>
  <CommonEntitiesLoader @entities="receiveCommonEntities"/>
  <Toast/>
  <div width="90%" style="margin: 30px;">
    <div class="text-overline mb-1">Date Selector</div>
    <div style="display: flex; padding: 2px; align-items: center">
      <v-btn-toggle
          class="mx-auto"
          v-model="customDatePicker.selectedId"
          tile
          color="info"
          group
          mandatory
          @click="
            this.selectedDate = null;
            fetchRegistrations(
              customDatePicker.dates[customDatePicker.selectedId].sortableDate
            );
          "
      >
        <v-btn
            v-for="date in customDatePicker.dates"
            :key="date.id"
            :color="date.color"
        >
          {{ date.date }}
        </v-btn>
      </v-btn-toggle>
      <FormKit
          type="date"
          label="Pick an alternative date"
          :value="
            customDatePicker.dates[customDatePicker.selectedId].sortableDate
          "
          @input="
            (val) => {
              this.selectedDate = val;
              fetchRegistrations(val);
            }
          "
      />
    </div>
  </div>
  <div width="90%" style="margin: 30px">
    <div class="text-overline mb-1" style="display: flex">
      <v-row>
        <v-col>
         {{ "Registrations for "+ selectedDate }} 
        </v-col>
        
      </v-row>
    </div>

    <v-card class="mx-auto mt-9" v-if="rego_data && rego_data.data">
      <Accordion lazy>
        <AccordionTab
            v-if="rego_data.data.notFound"
        >
          <template #header>

            <div class="row">
              <div class="col-2" style="font-size:1.1rem"><b>Not Found</b></div>
              <div class="col-9" style="font-size:0.75rem;margin-top: 8px;"><b>Action taken:</b> None, horses could not be identified in the database, perhaps they need to be added.</div>
              <div class="col-1">{{rego_data.data.notFound.length+"&nbsp;entries"}}</div>
            </div>
          </template>
          <Accordion lazy>
            
            <AccordionTab
                v-for="horse in rego_data.data.notFound"
                :key="(horse.Horse) ? horse.Horse['@_HorseName'] : undefined"
                :style="(horse.Horse) ? '' : 'display:none'"
            >
              <template #header>
                <v-row>
                  
                  <div
                      class="v-col-3 d-flex justify-start"
                      style="font-weight: 600"
                  >
                    <v-icon icon="mdi-horse-variant-fast"></v-icon>{{
                      "&nbsp;" + horse.Horse['@_HorseName']+" ("+((horse.Horse['@_Country']) ?? 'AUS')+")"
                    }}
                  </div>
                                    
                  <div
                      class="v-col-2 d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Foaling Date:</b>
                    {{
                      "&nbsp;" + horse.Horse['@_FoalDate']
                    }}
                  </div>
                  <div
                      v-if="horse.report"
                      class="v-col d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Report:</b>
                    {{
                      "&nbsp;" + horse.report
                    }}
                  </div>
                  
                </v-row>
              </template>
              <div class="formgrid grid">
                <div class="row">
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>FoalDate:</b>{{"&nbsp;" + horse.Horse['@_FoalDate']}}</div>
                      <div class="col-12"><b>NamePrev:</b>{{"&nbsp;" + horse.Horse['@_NamePrev']}}</div>
                      <div class="col-12"><b>LifeNumber:</b>{{"&nbsp;" + horse.Horse['@_LifeNumber']}}</div>
                      <div class="col-12"><b>Age:</b>{{"&nbsp;" + horse.Horse['@_Age']}}</div>
                      <div class="col-12"><b>Sex:</b>{{"&nbsp;" + horse.Horse['@_Sex']}}</div>
                      <div class="col-12"><b>Colour:</b>{{"&nbsp;" + horse.Horse['@_Colour']}}</div>
                      <div class="col-12"><b>HorseStatus:</b>{{"&nbsp;" + horse.Horse['@_HorseStatus']}}</div>
                      <div class="col-12"><b>HorseCode:</b>{{"&nbsp;" + horse.Horse['@_HorseCode']}}</div>
                      <div class="col-12"><b>HorseName:</b>{{"&nbsp;" + horse.Horse['@_HorseName']}}</div>
                    </div>

                  </div>
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>Country:</b>{{"&nbsp;" + (horse.Horse['@_Country'] ?? 'AUS')}}</div>
                      <div class="col-12"><b>Sire:</b>{{"&nbsp;" + horse.Breeding.Sire['@_HorseName']+" ("+((horse.Breeding.Sire['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>Dam:</b>{{"&nbsp;" + horse.Breeding.Dam['@_HorseName']+" ("+((horse.Breeding.Dam['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>DamSire:</b>{{"&nbsp;" + horse.Breeding.GrandSire['@_HorseName']+" ("+((horse.Breeding.GrandSire['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>Breeder:</b>{{"&nbsp;" + horse.BreedersName}}</div>
                      <div class="col-12"><b>Blinkers:</b>{{"&nbsp;" + horse.Blinkers}}</div>
                      <div class="col-12"><b>StableReturnDate:</b>{{"&nbsp;" + horse.StableReturnDate}}</div>
                    </div>
                  </div>
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>Trainer:</b>{{"&nbsp;" + horse.Trainer['Name']}}</div>
                      <div class="col-12"><b>Location:</b>{{"&nbsp;" + horse.Trainer['Location']}}</div>
                      <div class="col-12"><b>State:</b>{{"&nbsp;" + horse.Trainer['State']}}</div>
                      <div class="col-12"><b>PostCode:</b>{{"&nbsp;" + horse.Trainer['PostCode']}}</div>
                      <div class="col-12"><b>Title:</b>{{"&nbsp;" + horse.Trainer['Title']}}</div>
                      <div class="col-12"><b>PreferredName:</b>{{"&nbsp;" + horse.Trainer['PreferredName']}}</div>
                      <div class="col-12"><b>Surname:</b>{{"&nbsp;" + horse.Trainer['Surname']}}</div>
                      <div class="col-12"><b>Initials:</b>{{"&nbsp;" + horse.Trainer['Initials']}}</div>
                      <div class="col-12"><b>TrainerCode:</b>{{"&nbsp;" + horse.Trainer['@_TrainerCode']}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="v-col-12 d-flex justify-start">
                    <b>Owners:</b>
                    {{
                      "&nbsp;" + horse.RacebookOwnersName
                    }}
                  </div>
                  <div class="v-col-12 d-flex justify-start">
                    <b>RacingColours:</b>
                    {{
                      "&nbsp;" + horse.RacingColours
                    }}
                  </div>
                  
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </AccordionTab>
        <AccordionTab
            v-if="rego_data.data.alertHorses"
        >
          <template #header>

            <div class="row">
              <div class="col-2" style="font-size:1.1rem"><b>Horse Alert</b></div>
              <div class="col-9" style="font-size:0.75rem;margin-top: 8px;"><b>Action taken:</b> Horses have been created but require immediate attention, some may have been incorrectly created.</div>
              <div class="col-1">{{rego_data.data.alertHorses.length+"&nbsp;entries"}}</div>
            </div>
          </template>
          <Accordion lazy>
            
            <AccordionTab
                v-for="horse in rego_data.data.alertHorses"
                :key="(horse.Horse) ? horse.Horse['@_HorseName'] : undefined"
                :style="(horse.Horse) ? '' : 'display:none'"
            >
              <template #header>
                <v-row>
                  
                  <div
                      class="v-col-3 d-flex justify-start"
                      style="font-weight: 600"
                  >
                    <v-icon icon="mdi-horse-variant-fast"></v-icon>{{
                      "&nbsp;" + horse.Horse['@_HorseName']+" ("+((horse.Horse['@_Country']) ?? 'AUS')+")"
                    }}
                  </div>
                                    
                  <div
                      class="v-col-2 d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Foaling Date:</b>
                    {{
                      "&nbsp;" + horse.Horse['@_FoalDate']
                    }}
                  </div>
                  <div
                      v-if="horse.report"
                      class="v-col d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Report:</b>
                    {{
                      "&nbsp;" + horse.report
                    }}
                  </div>
                  
                </v-row>
              </template>
              <div class="formgrid grid">
                <div class="row">
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>FoalDate:</b>{{"&nbsp;" + horse.Horse['@_FoalDate']}}</div>
                      <div class="col-12"><b>NamePrev:</b>{{"&nbsp;" + horse.Horse['@_NamePrev']}}</div>
                      <div class="col-12"><b>LifeNumber:</b>{{"&nbsp;" + horse.Horse['@_LifeNumber']}}</div>
                      <div class="col-12"><b>Age:</b>{{"&nbsp;" + horse.Horse['@_Age']}}</div>
                      <div class="col-12"><b>Sex:</b>{{"&nbsp;" + horse.Horse['@_Sex']}}</div>
                      <div class="col-12"><b>Colour:</b>{{"&nbsp;" + horse.Horse['@_Colour']}}</div>
                      <div class="col-12"><b>HorseStatus:</b>{{"&nbsp;" + horse.Horse['@_HorseStatus']}}</div>
                      <div class="col-12"><b>HorseCode:</b>{{"&nbsp;" + horse.Horse['@_HorseCode']}}</div>
                      <div class="col-12"><b>HorseName:</b>{{"&nbsp;" + horse.Horse['@_HorseName']}}</div>
                    </div>

                  </div>
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>Country:</b>{{"&nbsp;" + (horse.Horse['@_Country'] ?? 'AUS')}}</div>
                      <div class="col-12"><b>Sire:</b>{{"&nbsp;" + horse.Breeding.Sire['@_HorseName']+" ("+((horse.Breeding.Sire['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>Dam:</b>{{"&nbsp;" + horse.Breeding.Dam['@_HorseName']+" ("+((horse.Breeding.Dam['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>DamSire:</b>{{"&nbsp;" + horse.Breeding.GrandSire['@_HorseName']+" ("+((horse.Breeding.GrandSire['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>Breeder:</b>{{"&nbsp;" + horse.BreedersName}}</div>
                      <div class="col-12"><b>Blinkers:</b>{{"&nbsp;" + horse.Blinkers}}</div>
                      <div class="col-12"><b>StableReturnDate:</b>{{"&nbsp;" + horse.StableReturnDate}}</div>
                    </div>
                  </div>
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>Trainer:</b>{{"&nbsp;" + horse.Trainer['Name']}}</div>
                      <div class="col-12"><b>Location:</b>{{"&nbsp;" + horse.Trainer['Location']}}</div>
                      <div class="col-12"><b>State:</b>{{"&nbsp;" + horse.Trainer['State']}}</div>
                      <div class="col-12"><b>PostCode:</b>{{"&nbsp;" + horse.Trainer['PostCode']}}</div>
                      <div class="col-12"><b>Title:</b>{{"&nbsp;" + horse.Trainer['Title']}}</div>
                      <div class="col-12"><b>PreferredName:</b>{{"&nbsp;" + horse.Trainer['PreferredName']}}</div>
                      <div class="col-12"><b>Surname:</b>{{"&nbsp;" + horse.Trainer['Surname']}}</div>
                      <div class="col-12"><b>Initials:</b>{{"&nbsp;" + horse.Trainer['Initials']}}</div>
                      <div class="col-12"><b>TrainerCode:</b>{{"&nbsp;" + horse.Trainer['@_TrainerCode']}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="v-col-12 d-flex justify-start">
                    <b>Owners:</b>
                    {{
                      "&nbsp;" + horse.RacebookOwnersName
                    }}
                  </div>
                  <div class="v-col-12 d-flex justify-start">
                    <b>RacingColours:</b>
                    {{
                      "&nbsp;" + horse.RacingColours
                    }}
                  </div>
                  
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </AccordionTab>
        <AccordionTab
            v-if="rego_data.data.nameChange"
        >
          <template #header>

            <div class="row">
              <div class="col-2" style="font-size:1.1rem"><b>Name Changes</b></div>
              <div class="col-9" style="font-size:0.75rem;margin-top: 8px;"><b>Action taken:</b> None, manual change required. Search for the horse in the database, and update both New name and previous names list.</div>
              <div class="col-1">{{rego_data.data.nameChange.length+"&nbsp;entries"}}</div>
            </div>
          </template>
          <Accordion lazy>
            
            <AccordionTab
                v-for="horse in rego_data.data.nameChange"
                :key="(horse.Horse) ? horse.Horse['@_HorseName'] : undefined"
                :style="(horse.Horse) ? '' : 'display:none'"
            >
              <template #header>
                <v-row>
                  
                  <div
                      class="v-col d-flex justify-start"
                      style="font-weight: 600"
                  >
                    <v-icon icon="mdi-horse-variant-fast"></v-icon>
                    {{ "&nbsp;" + horse.Horse['@_HorseName']+" ("+((horse.Horse['@_Country']) ?? 'AUS')+")"}}
                  </div>
                  
                  <div
                      class="v-col-2 d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Foaling Date:</b>
                    {{
                      "&nbsp;" + horse.Horse['@_FoalDate']
                    }}
                  </div>
                  <div
                      v-if="horse.report"
                      class="v-col d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Report:</b>
                    {{
                      "&nbsp;" + horse.report
                    }}
                  </div>
                  
                </v-row>
              </template>
              <div class="formgrid grid">
                <div class="row">
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>FoalDate:</b>{{"&nbsp;" + horse.Horse['@_FoalDate']}}</div>
                      <div class="col-12"><b>NamePrev:</b>{{"&nbsp;" + horse.Horse['@_NamePrev']}}</div>
                      <div class="col-12"><b>LifeNumber:</b>{{"&nbsp;" + horse.Horse['@_LifeNumber']}}</div>
                      <div class="col-12"><b>Age:</b>{{"&nbsp;" + horse.Horse['@_Age']}}</div>
                      <div class="col-12"><b>Sex:</b>{{"&nbsp;" + horse.Horse['@_Sex']}}</div>
                      <div class="col-12"><b>Colour:</b>{{"&nbsp;" + horse.Horse['@_Colour']}}</div>
                      <div class="col-12"><b>HorseStatus:</b>{{"&nbsp;" + horse.Horse['@_HorseStatus']}}</div>
                      <div class="col-12"><b>HorseCode:</b>{{"&nbsp;" + horse.Horse['@_HorseCode']}}</div>
                      <div class="col-12"><b>HorseName:</b>{{"&nbsp;" + horse.Horse['@_HorseName']}}</div>
                    </div>

                  </div>
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>Country:</b>{{"&nbsp;" + (horse.Horse['@_Country'] ?? 'AUS')}}</div>
                      <div class="col-12"><b>Sire:</b>{{"&nbsp;" + horse.Breeding.Sire['@_HorseName']+" ("+((horse.Breeding.Sire['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>Dam:</b>{{"&nbsp;" + horse.Breeding.Dam['@_HorseName']+" ("+((horse.Breeding.Dam['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>DamSire:</b>{{"&nbsp;" + horse.Breeding.GrandSire['@_HorseName']+" ("+((horse.Breeding.GrandSire['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>Breeder:</b>{{"&nbsp;" + horse.BreedersName}}</div>
                      <div class="col-12"><b>Blinkers:</b>{{"&nbsp;" + horse.Blinkers}}</div>
                      <div class="col-12"><b>StableReturnDate:</b>{{"&nbsp;" + horse.StableReturnDate}}</div>
                    </div>
                  </div>
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>Trainer:</b>{{"&nbsp;" + horse.Trainer['Name']}}</div>
                      <div class="col-12"><b>Location:</b>{{"&nbsp;" + horse.Trainer['Location']}}</div>
                      <div class="col-12"><b>State:</b>{{"&nbsp;" + horse.Trainer['State']}}</div>
                      <div class="col-12"><b>PostCode:</b>{{"&nbsp;" + horse.Trainer['PostCode']}}</div>
                      <div class="col-12"><b>Title:</b>{{"&nbsp;" + horse.Trainer['Title']}}</div>
                      <div class="col-12"><b>PreferredName:</b>{{"&nbsp;" + horse.Trainer['PreferredName']}}</div>
                      <div class="col-12"><b>Surname:</b>{{"&nbsp;" + horse.Trainer['Surname']}}</div>
                      <div class="col-12"><b>Initials:</b>{{"&nbsp;" + horse.Trainer['Initials']}}</div>
                      <div class="col-12"><b>TrainerCode:</b>{{"&nbsp;" + horse.Trainer['@_TrainerCode']}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="v-col-12 d-flex justify-start">
                    <b>Owners:</b>
                    {{
                      "&nbsp;" + horse.RacebookOwnersName
                    }}
                  </div>
                  <div class="v-col-12 d-flex justify-start">
                    <b>RacingColours:</b>
                    {{
                      "&nbsp;" + horse.RacingColours
                    }}
                  </div>
                  
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </AccordionTab>
        <AccordionTab
            v-if="rego_data.data.newHorses"
        >
          <template #header>

            <div class="row">
              <div class="col-2" style="font-size:1.1rem"><b>New Horses</b></div>
              <div class="col-9" style="font-size:0.75rem;margin-top: 8px;"><b>Action taken:</b> Horse has been created, some information may need to be manually updated in the database.</div>
              <div class="col-1">{{rego_data.data.newHorses.length+"&nbsp;entries"}}</div>
            </div>
          </template>
          <Accordion lazy>
            
            <AccordionTab
                v-for="horse in rego_data.data.newHorses"
                :key="(horse.Horse) ? horse.Horse['@_HorseName'] : undefined"
                :style="(horse.Horse) ? '' : 'display:none'"
            >
              <template #header>
                <v-row>
                  
                  <div
                      class="v-col-2 d-flex justify-start"
                      style="font-weight: 600"
                  >
                    <v-icon icon="mdi-horse-variant-fast"></v-icon>
                    {{
                       "&nbsp;" + horse.Horse['@_HorseName']+" ("+((horse.Horse['@_Country']) ?? 'AUS')+")"
                    }}
                  </div>
                 
                  <div
                      class="v-col-2 d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Foaling Date:</b>
                    {{
                      "&nbsp;" + horse.Horse['@_FoalDate']
                    }}
                  </div>
                  <div
                      v-if="!horse.report"
                      class="v-col-2 d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Sire:</b>
                    {{
                      "&nbsp;" + horse.Breeding.Sire['@_HorseName']
                    }}
                  </div>
                  <div
                      
                      class="v-col-2 d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Dam:</b>
                    {{
                      "&nbsp;" + horse.Breeding.Dam['@_HorseName']
                    }}
                  </div>
                  <div
                      v-if="horse.report"
                      class="v-col d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Report:</b>
                    {{
                      "&nbsp;" + horse.report
                    }}
                  </div>
                  
                </v-row>
              </template>
              <div class="formgrid grid">
                <div class="row">
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>FoalDate:</b>{{"&nbsp;" + horse.Horse['@_FoalDate']}}</div>
                      <div class="col-12"><b>NamePrev:</b>{{"&nbsp;" + horse.Horse['@_NamePrev']}}</div>
                      <div class="col-12"><b>LifeNumber:</b>{{"&nbsp;" + horse.Horse['@_LifeNumber']}}</div>
                      <div class="col-12"><b>Age:</b>{{"&nbsp;" + horse.Horse['@_Age']}}</div>
                      <div class="col-12"><b>Sex:</b>{{"&nbsp;" + horse.Horse['@_Sex']}}</div>
                      <div class="col-12"><b>Colour:</b>{{"&nbsp;" + horse.Horse['@_Colour']}}</div>
                      <div class="col-12"><b>HorseStatus:</b>{{"&nbsp;" + horse.Horse['@_HorseStatus']}}</div>
                      <div class="col-12"><b>HorseCode:</b>{{"&nbsp;" + horse.Horse['@_HorseCode']}}</div>
                      <div class="col-12"><b>HorseName:</b>{{"&nbsp;" + horse.Horse['@_HorseName']}}</div>
                    </div>

                  </div>
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>Country:</b>{{"&nbsp;" + (horse.Horse['@_Country'] ?? 'AUS')}}</div>
                      <div class="col-12"><b>Sire:</b>{{"&nbsp;" + horse.Breeding.Sire['@_HorseName']+" ("+((horse.Breeding.Sire['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>Dam:</b>{{"&nbsp;" + horse.Breeding.Dam['@_HorseName']+" ("+((horse.Breeding.Dam['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>DamSire:</b>{{"&nbsp;" + horse.Breeding.GrandSire['@_HorseName']+" ("+((horse.Breeding.GrandSire['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>Breeder:</b>{{"&nbsp;" + horse.BreedersName}}</div>
                      <div class="col-12"><b>Blinkers:</b>{{"&nbsp;" + horse.Blinkers}}</div>
                      <div class="col-12"><b>StableReturnDate:</b>{{"&nbsp;" + horse.StableReturnDate}}</div>
                    </div>
                  </div>
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>Trainer:</b>{{"&nbsp;" + horse.Trainer['Name']}}</div>
                      <div class="col-12"><b>Location:</b>{{"&nbsp;" + horse.Trainer['Location']}}</div>
                      <div class="col-12"><b>State:</b>{{"&nbsp;" + horse.Trainer['State']}}</div>
                      <div class="col-12"><b>PostCode:</b>{{"&nbsp;" + horse.Trainer['PostCode']}}</div>
                      <div class="col-12"><b>Title:</b>{{"&nbsp;" + horse.Trainer['Title']}}</div>
                      <div class="col-12"><b>PreferredName:</b>{{"&nbsp;" + horse.Trainer['PreferredName']}}</div>
                      <div class="col-12"><b>Surname:</b>{{"&nbsp;" + horse.Trainer['Surname']}}</div>
                      <div class="col-12"><b>Initials:</b>{{"&nbsp;" + horse.Trainer['Initials']}}</div>
                      <div class="col-12"><b>TrainerCode:</b>{{"&nbsp;" + horse.Trainer['@_TrainerCode']}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="v-col-12 d-flex justify-start">
                    <b>Owners:</b>
                    {{
                      "&nbsp;" + horse.RacebookOwnersName
                    }}
                  </div>
                  <div class="v-col-12 d-flex justify-start">
                    <b>RacingColours:</b>
                    {{
                      "&nbsp;" + horse.RacingColours
                    }}
                  </div>
                  
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </AccordionTab>
        <AccordionTab
            v-if="rego_data.data.updatedHorses"
        >
          <template #header>

            <div class="row">
              <div class="col-2" style="font-size:1.1rem"><b>Updated Horses</b></div>
              <div class="col-9" style="font-size:0.75rem;margin-top: 8px;"><b>Action taken:</b> Database entries have been automatically updated, these entries should not require significant checking.</div>
              <div class="col-1">{{rego_data.data.updatedHorses.length+"&nbsp;entries"}}</div>
            </div>
          </template>
          <Accordion lazy>
            
            <AccordionTab
                v-for="horse in rego_data.data.updatedHorses"
                :key="(horse.RAData) ? horse.RAData.Horse['@_HorseName'] : undefined"
                :style="(horse.RAData) ? '' : 'display:none'"
            >
              <template #header>
                <v-row>
                  
                  <div
                      class="v-col-3 d-flex justify-start"
                      style="font-weight: 600"
                  >
                    <v-icon icon="mdi-horse-variant-fast"></v-icon>
                    {{
                       "&nbsp;" + horse.RAData.Horse['@_HorseName']+" ("+((horse.RAData.Horse['@_Country']) ?? 'AUS')+")"
                    }}
                  </div>
                  
                  
                  <div
                      class="v-col-2 d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Horse ID:</b>
                    {{
                      "&nbsp;" + horse.horseId
                    }}
                  </div>
                  <div
                      class="v-col-7 d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>Updated Data:</b>
                    {{
                      "&nbsp;" + Object.keys(horse.updatedData)
                    }}
                  </div>
                  
                  
                </v-row>
              </template>
              <div class="formgrid grid">
                <div class="row">
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>FoalDate:</b>{{"&nbsp;" + horse.RAData.Horse['@_FoalDate']}}</div>
                      <div class="col-12"><b>NamePrev:</b>{{"&nbsp;" + horse.RAData.Horse['@_NamePrev']}}</div>
                      <div class="col-12"><b>LifeNumber:</b>{{"&nbsp;" + horse.RAData.Horse['@_LifeNumber']}}</div>
                      <div class="col-12"><b>Age:</b>{{"&nbsp;" + horse.RAData.Horse['@_Age']}}</div>
                      <div class="col-12"><b>Sex:</b>{{"&nbsp;" + horse.RAData.Horse['@_Sex']}}</div>
                      <div class="col-12"><b>Colour:</b>{{"&nbsp;" + horse.RAData.Horse['@_Colour']}}</div>
                      <div class="col-12"><b>HorseStatus:</b>{{"&nbsp;" + horse.RAData.Horse['@_HorseStatus']}}</div>
                      <div class="col-12"><b>HorseCode:</b>{{"&nbsp;" + horse.RAData.Horse['@_HorseCode']}}</div>
                      <div class="col-12"><b>HorseName:</b>{{"&nbsp;" + horse.RAData.Horse['@_HorseName']}}</div>
                    </div>

                  </div>
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>Country:</b>{{"&nbsp;" + (horse.RAData.Horse['@_Country'] ?? 'AUS')}}</div>
                      <div class="col-12"><b>Sire:</b>{{"&nbsp;" + horse.RAData.Breeding.Sire['@_HorseName']+" ("+((horse.RAData.Breeding.Sire['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>Dam:</b>{{"&nbsp;" + horse.RAData.Breeding.Dam['@_HorseName']+" ("+((horse.RAData.Breeding.Dam['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>DamSire:</b>{{"&nbsp;" + horse.RAData.Breeding.GrandSire['@_HorseName']+" ("+((horse.RAData.Breeding.GrandSire['@_HorseCountryAbbr']) ?? 'AUS')+")"}}</div>
                      <div class="col-12"><b>Breeder:</b>{{"&nbsp;" + horse.RAData.BreedersName}}</div>
                      <div class="col-12"><b>Blinkers:</b>{{"&nbsp;" + horse.RAData.Blinkers}}</div>
                      <div class="col-12"><b>StableReturnDate:</b>{{"&nbsp;" + horse.RAData.StableReturnDate}}</div>
                    </div>
                  </div>
                  <div class="v-col-4 d-flex justify-start">
                    <div class="row">
                      <div class="col-12"><b>Trainer:</b>{{"&nbsp;" + horse.RAData.Trainer['Name']}}</div>
                      <div class="col-12"><b>Location:</b>{{"&nbsp;" + horse.RAData.Trainer['Location']}}</div>
                      <div class="col-12"><b>State:</b>{{"&nbsp;" + horse.RAData.Trainer['State']}}</div>
                      <div class="col-12"><b>PostCode:</b>{{"&nbsp;" + horse.RAData.Trainer['PostCode']}}</div>
                      <div class="col-12"><b>Title:</b>{{"&nbsp;" + horse.RAData.Trainer['Title']}}</div>
                      <div class="col-12"><b>PreferredName:</b>{{"&nbsp;" + horse.RAData.Trainer['PreferredName']}}</div>
                      <div class="col-12"><b>Surname:</b>{{"&nbsp;" + horse.RAData.Trainer['Surname']}}</div>
                      <div class="col-12"><b>Initials:</b>{{"&nbsp;" + horse.RAData.Trainer['Initials']}}</div>
                      <div class="col-12"><b>TrainerCode:</b>{{"&nbsp;" + horse.RAData.Trainer['@_TrainerCode']}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="v-col-12 d-flex justify-start">
                    <b>Owners:</b>
                    {{
                      "&nbsp;" + horse.RAData.RacebookOwnersName
                    }}
                  </div>
                  <div class="v-col-12 d-flex justify-start">
                    <b>RacingColours:</b>
                    {{
                      "&nbsp;" + horse.RAData.RacingColours
                    }}
                  </div>
                  
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </AccordionTab>
      </Accordion>
    </v-card>
    <v-alert
        prominent
        title="No Registrations available on this date"
        variant="contained-flat"
        rounded="pill"
        type="info"
        v-if="show_no_rego && (rego_data == undefined || rego_data.data.length == 0)"
    >
     Looks like there may be no registrations loaded for this date.
    </v-alert>
  </div>
</template>

<script>
import {API} from "aws-amplify";
import moment from "moment";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";




export default {
  components: {
    Accordion,
    AccordionTab
  },
  data: () => ({
    rego_data: null,
    errors: [],
    customDatePicker: [],
    selectedDate: null,
    selectedRego: null,
    showNoRegoAlert: false,
    errorSummary: null,
    loader: null,
    loading: false,
    date_menu: false,
    show_no_rego: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    showPassword: false,
    model: null,
  }),
  async created() {
    this.prepareDatePicker();
    let d = new Date();
    this.fetchRegistrations(
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
    );
  },
  methods: {
    async fetchRegistrations(rego_date) {
      this.selectedDate = rego_date;
      console.log("Fetching registrations for date " + rego_date);
      return API.get(
          "MrCenApiGateway",
          `/registrations?date=${rego_date}`
      )
          .then((response) => {
            this.rego_data = null;
            this.showNoMeetingsAlert = (response.data.length === 0);
            if (!this.showNoRegoAlert) {
              this.rego_data = response;
            } else {
              this.show_no_rego = true
            }
            console.log(response);
            return response;
          })
          .catch((error) => {
            this.errors.push(error);
            this.errored = true;
            this.alert = true;
          });
    },
    getFormattedDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    prepareDatePicker() {
      console.log("Prepare DatePicker");
      this.customDatePicker = {dates: [], selectedId: null};
      let startDate = moment().subtract(7, "days").format();
      let endDate = moment().add(1, "days").format();
      let today = moment().format();
      let id = 0;
      for (var m = moment(startDate); m.isBefore(endDate); m.add(1, "days")) {
        if (m.format() === today) {
          this.customDatePicker.dates.push({
            id: id,
            date: m.format("ddd DD MMM"),
            sortableDate: m.format("YYYY-MM-DD"),
            today: true,
            color: "warning",
          });
          this.customDatePicker.selectedId = id;
        } else {
          this.customDatePicker.dates.push({
            id: id,
            date: m.format("ddd DD MMM"),
            sortableDate: m.format("YYYY-MM-DD"),
            today: false,
            color: "info",
          });
        }
        id++;
      }
      console.log(this.customDatePicker);
    }
    ,
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    }
    ,
  }
  ,
  computed: {
    //this.meetings_data
    computedDateFormatted() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    }
    ,
  }
  ,
  mounted: () => {
    // this.prepareDatePicker();
  },

}
;
</script>
<style scoped>
.mr-2{
  color: #2196f3;
  background: transparent;
  border: 1px solid #2196f3;
  top: -6px;
}
</style>