<template>
  <Dialog v-model="editedItem" v-model:visible="isVisible" :style="{width: '1200px', 'max-height': '97%'}" header="Client Details"
          :modal="true" :closeOnEscape="true" @hide="closeForm()"
          class="p-fluid client-form">
          <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <!-- <span>valid {{!v$.$invalid}} </span> -->
          <div class="formgrid grid">
            <div class="row">
              <div class="col">
                <div class="field col">          
                  <label for="account_holder" :class="{'p-error':v$.editedItem.account_holder.$invalid}">Account Holder <sup class="star-marker">*</sup></label>
                  <InputText id="account_holder" v-model="editedItem.account_holder" required="true" autofocus :class="{'p-invalid':v$.editedItem.account_holder.$invalid && submitted}" />
                  <small v-if="(v$.editedItem.account_holder.$invalid) || v$.editedItem.account_holder.$pending.$response" class="p-error">{{v$.editedItem.account_holder.required.$message.replace('Value', 'Account holder')}}</small>
                </div>
                <div class="field col">
                  <label for="email" :class="{'p-error':v$.editedItem.email.$invalid}">Email <sup class="star-marker">*</sup></label>
                  <InputText id="email" v-model="editedItem.email" required="true" :class="{'p-invalid':v$.editedItem.email.$invalid && submitted}" />
                  <span v-if="v$.editedItem.email.$error && submitted">
                      <span id="email-error" v-for="(error, index) of v$.editedItem.email.$errors" :key="index">
                      <small class="p-error">{{error.$message}}</small>
                      </span>
                  </span>
                  <small v-else-if="(v$.editedItem.email.$invalid && submitted) || v$.editedItem.email.$pending.$response" class="p-error">{{v$.editedItem.email.required.$message.replace('Value', 'Email')}}</small>
                </div>
                <div class="field col">
                  <label for="company">Company</label>
                  <InputText id="company" v-model="editedItem.company" required="true"/>
                </div>
              </div>
              <div class="col" style="border-left:1px solid grey;">
                <div class="field col">
                  <label for="ftp_address">FTP Address</label>
                  <InputText id="ftp_address" v-model="editedItem.ftp_address" required="true"/>
                </div>
                <div class="field col">
                  <label for="ftp_username">FTP Username</label>
                  <InputText id="ftp_username" v-model="editedItem.ftp_username" required="true"/>
                </div>
                <div class="field col">
                  <label for="ftp_password">FTP Password</label>
                  <InputText id="ftp_password" v-model="editedItem.ftp_password" required="true"/>
                </div>
              </div>
              <div class="col" style="border-left:1px solid grey;">
                <div class="field-checkbox col active-section">
                    <Checkbox id="binary" v-model="editedItem.status" :binary="true" />
                    <label for="binary">Active</label>
                </div>
                <div class="field-checkbox col active-section">
                    <Checkbox id="subFol" v-model="editedItem.ftp_use_sub_folders" :binary="true" />
                    <label for="subFol">Use FTP sub folders</label>
                </div>
                <!-- <div class="field col">
                  <label for="api_key_id">API Key Id</label>
                  <InputText id="api_key_id" v-model="editedItem.api_key_id" required="true" disabled/>
                </div>
                <div class="field col">
                  <label for="api_key">API Key</label>
                  <InputText id="api_key" v-model="editedItem.api_key" required="true" disabled/>
                </div> -->
              </div>
            </div>
            <div class="row"> 
              <div class="field col">
                <multiSelect title="Files" :selectedItems="editedItem.files_access" @changedItems="changedFiles"
                  :items="files" />
              </div>
              <div class="field col">
                <multiSelect title="Regions" :selectedItems="editedItem.region_perms" @changedItems="changedRegions"
                  :items="regions" />
              </div>
              <div class="field col">
                <multiSelect title="Classification" :selectedItems="editedItem.classifications" @changedItems="changedClassifications"
                  :items="classifications" />
                  <multiSelect title="Stage" :selectedItems="editedItem.stage_perms" @changedItems="changedStages"
                  :items="stages" style="margin-top:50px;"/>
              </div>
              <div class="field col">
                <multiSelect title="Fields" :selectedItems="editedItem.fields_access" @changedItems="changedFields"
                  :items="fields" />
              </div>
              

            </div>
          </div>
          </form>

      <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeForm()"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveForm()" :disabled="v$.$invalid == true"/>
    </template>
  </Dialog>

</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Checkbox from 'primevue/checkbox';
import {API} from "aws-amplify";
import multiSelect from "./multi-select.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from '@vuelidate/validators'

export default {
  components: {
    Dialog,
    Button,
    InputText,
    Checkbox,
    multiSelect
  },
  setup:() => ({v$: useVuelidate()}),
  validations() {
    return {
      editedItem:{
        account_holder:{
          required
        },
        email: {
                required,
                email
        }
      }
    }
  },
  data: () => ({
    submitted: false,
    editedItem: {},
    isVisible: null,
    files: [
      "FIELDS",
      "FORM",
      "FORM - 2 RUNS",
      "FORM - 10 RUNS",
      "RESULTS - COLLATED",
      "RESULTS - RACE BY RACE",
      "SCRATCHINGS",
      "GEAR CHANGES"
    ],
    regions: [
      "NSW",
      "ACT",
      "QLD",
      "VIC",
      "SA",
      "WA",
      "TAS",
      "NT",
      "NZ",
      "SGP",
      "HK"
    ],
    fields: [
      "GEAR CHANGES",
      "RATINGS",
      "SILKS",
      "BETTING",
      "SELECTIONS",
      "SPEED MAPS",
      "COMMENT"
    ],
    classifications: [
      "UNVALIDATED MEETINGS",
      "METRO",
      "PROVINCIAL",
      "COUNTRY",
      "INTERNATIONAL",
    ],
    stages:[
      "ACCEPTANCES",
      "WEIGHTS",
      "NOMINATIONS"
    ]
  }),
  props: {
    initialItem: {},
    visible: null,
  }
  ,
  methods: {
    async saveForm() {
      if(this.initialItem._id != null){
        if (!this.editedItem.ftp_sub_folders){
          this.editedItem.ftp_sub_folders = {
            "FIELDS":"mr_fields",
            "FORM":"mr_form",
            "RESULTS - COLLATED":"mr_results",
            "RESULTS - RACE BY RACE":"mr_results",
            "GEAR CHANGES":"mr_gear_changes",
            "SCRATCHINGS":"mr_scratchings"
          }
        }
        try{
          await API.put(
              "MrCenApiGateway",
              `/admin/client?id=${this.initialItem._id}`,
              {
                body: this.editedItem
              });
        }
        catch (error){
          if (error.response && error.response.status === 409) {
        console.error("Conflict error:", error);
      } else {
        console.error("Error:", error);
      }
        }
          
      }else{
          await API.post(
            "MrCenApiGateway",
            `/admin/client`,
            {
              body: this.editedItem
            });
      }
      this.closeForm();
    },
    closeForm() {
      this.isVisible = false;
      this.$emit('visible', false);
    } ,
    changedRegions(val){
      this.editedItem.region_perms = val;
    },
    changedFields(val){
      this.editedItem.fields_access = val;
    },
    changedFiles(val){
      this.editedItem.files_access = val;
    },
    changedClassifications(val){
      this.editedItem.classifications = val;
    },
    changedStages(val){
      this.editedItem.stage_perms = val;
    },
    handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }
        },  
  }
  ,
  watch: {
    initialItem(val) {
      this.editedItem = val;
      console.log(this.editedItem);

    }
    ,
    visible(val) {
      this.isVisible = val;
    }
    ,
    isVisible(val) {
      this.$emit('visible', val);
    }
  }
  ,
  mounted() {

  },
}
</script>

<style scoped>
.active-section{
  margin-top:25px;
}

.section-seperator{
    width: 100%;
    margin: 5px 10px 10px 10px;
    height: 1px;
    border: none;
    background: lightgray;
}

.col-label{
  margin: 5px 15px;
  font-weight: bold;
}

.field > label{
  margin-bottom: 0.1rem;
}

.star-marker{
  font-size: large;
  top: unset;
}

</style>