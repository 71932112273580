<template>
  <Dialog
    v-model="editedItem"
    v-model:visible="isVisible"
    :style="{ width: '800px' }"
    header="Club Details"
    :modal="true"
    :closeOnEscape="true"
    @hide="closeForm()"
    class="p-fluid"
  >
    <div class="formgrid grid">
      <div class="field col">
        <label for="clbid">Club ID</label>
        <InputText
          id="clbid"
          v-model="editedItem.CLB_CLUB_ID"
          required="true"
          :class="{
            'invalid-field': !isClubIDValid(editedItem.CLB_CLUB_ID),
          }"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="clbname">Club Name</label>
        <InputText
          id="clbname"
          v-model="editedItem.CLB_CLUB_NAME"
          required="true"
          :class="{
            'invalid-field': !isClubNameValid(editedItem.CLB_CLUB_NAME),
          }"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="clbabbrev">Club Abbreviation</label>
        <InputText
          id="clbabbrev"
          v-model="editedItem.CLB_CLUB_ABBREV"
          required="true"
          :class="{
            'invalid-field': !isClubAbbreviationValid(
              editedItem.CLB_CLUB_ABBREV
            ),
          }"
        />
      </div>
      <div class="field col">
        <label for="clbabbrev">Club Matching name</label>
        <InputText
          id="clbabbrev"
          v-model="editedItem.CLB_ALT_NAME"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="state">State</label>
        <Dropdown
          id="state"
          v-model="editedItem.CLB_STATE"
          :options="state_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    
      <div class="field col">
        <label for="country">Country</label>
        <AutoComplete
          v-model="editedItem.CLB_COUNTRY_CODE"
          id="country"
          :suggestions="this.filteredCountries"
          @complete="searchCountry($event)"
          :dropdown="true"
          field="label"
          autoHighlight
          forceSelection
        >
        </AutoComplete>
      </div>
    </div>
    <!-- <label for="apprentice">Apprentice Claim</label>
     <div>
       <label>Yes</label>
       <input type="checkbox"  value="Y" v-mode="Apprentice_choice"/>

       <label>No</label>
       <input type="checkbox"  value="N"  v-model="Apprentice_choice"/>
     </div>
   </div>
 </div>
<div class="formgrid grid">
   <div class="field col">
     <label for="amateur">Amateur</label>
     <div>
       <label>Yes</label>
       <input type="checkbox" value="Y" v-model="Amateur_Choice"/>
       <label>No</label>
       <input type="checkbox" value="N"  v-model="Amateur_Choice"/>
     </div>
   </div>
 </div>
 <div class="formgrid grid">
   <div class="field col">
     <label for="active">Active</label>
     <div>
       <label>Yes</label>
       <input type="checkbox" value="Y" v-model="Active_Choice"/>
       <label>No</label>
       <input type="checkbox" value="N" v-model="Active_Choice"/>
     </div>
   </div>
 </div>-->
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeForm()"
      />
      <Button 
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        :disabled="!isFormValid || user_role > 4"
        @click="saveForm()"
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import { API } from "aws-amplify";
import AutoComplete from "primevue/autocomplete";

export default {
  components: {
    Dialog,
    Button,
    InputText,
    Dropdown,
    AutoComplete,
  },
  data: () => ({
    editedItem: {},
    isVisible: null,
    filteredCountries: null,
    selectedCountry: null,
    state_options: [
      { label: "", value: 0 },
      { label: "ACT", value: 1 },
      { label: "NSW", value: 2 },
      { label: "VIC", value: 3 },
      { label: "QLD", value: 4 },
      { label: "SA", value: 5 },
      { label: "WA", value: 6 },
      { label: "TAS", value: 7 },
      { label: "NT", value: 8 },
      { label: "NZ", value: 9 },
      { label: "HK", value: 10 },
      { label: "SGP", value: 11 },
      { label: "AUS", value: 99 },
    ],
  }),
  props: {
    initialItem: {},
    visible: null,
    commonEntities: null,
    current_user: null,
    user_role: null
  },
  methods: {
    async saveForm() {
      if (typeof this.editedItem.CLB_COUNTRY_CODE === "object") {
        this.editedItem.CLB_COUNTRY_CODE = this.convertObject(
          this.editedItem.CLB_COUNTRY_CODE,
          "value"
        );
      }
      this.trimFields()
      console.log(this.editedItem);
      if (this.editedItem._id === "") {
        await API.post("MrCenApiGateway", `/club`, {
          body: this.editedItem,
        }).then((response) => {
          console.log(response);
          this.$toast.add({
            severity: "success",
            summary: `the club ${this.editedItem.CLB_CLUB_NAME} was created`,
            life: 5000,
          });
        });
      } else {
        await API.put("MrCenApiGateway", `/club`, {
          body: this.editedItem,
        }).then(() => {
          this.$toast.add({
            severity: "success",
            summary: `the club ${this.editedItem.CLB_CLUB_NAME} was updated`,
            life: 5000,
          });
        });
      }
      this.closeForm();
    },
    closeForm() {
      this.isVisible = false;
      this.$emit("visible", false);
    },
    searchCountry(event) {
      this.filteredCountries = this.commonEntities.allCountries.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    convertObject(object, key) {
      return object[key];
    },
    isClubNameValid(clubName) {
      return clubName && clubName.trim().length > 0;
    },
    isClubAbbreviationValid(clubAbbreviation) {
      return clubAbbreviation && clubAbbreviation.trim().length > 0;
    },
    isClubIDValid(clubID) {
      const num = Number(clubID);
      return clubID && !isNaN(num) && num > 0;
    },
    setCountryToAustralia() {
      this.editedItem.CLB_COUNTRY_CODE = { label: 'Australia', value: 'AUS' }; // Adjust this based on how countries are structured in your data
    },
    trimFields(){
      for (const key in this.editedItem) if (typeof this.editedItem[key] === 'string' || this.editedItem[key] instanceof String) this.editedItem[key] = this.editedItem[key].trim()
    }
  },
  watch: {
    initialItem(val) {
      this.editedItem = val;
      if (!this.editedItem._id) {
        this.editedItem = {
          CLB_CLUB_ABBREV: "",
          CLB_CLUB_ID: "",
          CLB_CLUB_NAME: "",
          CLB_COUNTRY_CODE: "",
          CLB_ROW_STATUS: "A",
          CLB_STATE: 0,
          CLB_ALT_NAME: "",
          _id: "",
        };
      }

      console.log(this.editedItem);
    },
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      this.$emit("visible", val);
    },
    'editedItem.CLB_STATE'(newValue) {
      const australianStates = [1, 2, 3, 4, 5, 6, 7, 8, 99]; // Numeric values for Australian states
      if (australianStates.includes(newValue)) {
        this.setCountryToAustralia();
      }
      if (newValue === 9) {
          this.editedItem.CLB_COUNTRY_CODE = {
            label: "New Zealand",
            value: "NZ",
          };
        }
        if (newValue === 10) {
          this.editedItem.CLB_COUNTRY_CODE = {
            label: "Hong Kong",
            value: "HK",
          };
        }
        if (newValue === 11) {
          this.editedItem.CLB_COUNTRY_CODE = {
            label: "Singapore",
            value: "SGP",
          };
        }
    }
  },
  computed: {
    isFormValid() {
      return (
        this.editedItem.CLB_CLUB_ID &&
        this.isClubIDValid(this.editedItem.CLB_CLUB_ID) &&
        this.editedItem.CLB_CLUB_ABBREV &&
        this.isClubAbbreviationValid(this.editedItem.CLB_CLUB_ABBREV) &&
        this.editedItem.CLB_CLUB_NAME &&
        this.isClubNameValid(this.editedItem.CLB_CLUB_NAME) &&
        true
      );
    },
  },
  mounted() {},
};
</script>

<style scoped>
#checkboxes {
  display: inline-block;
  margin-right: 5px;
}
#checkboxes label {
  display: inline-block;
}
.invalid-field {
  border-color: red;
  background-color: #ffe6e6;
}
</style>
