<template>
  <Toast />

  <CommonEntitiesLoader @entities="receiveCommonEntities" />
  <Dialog
    v-model="deleteDialog"
    v-model:visible="showDeleteDialog"
    :style="{ width: '600px' }"
    header="Confirm Delete"
    :modal="true"
    class="p-fluid"
    :closeable="false"
    @hide="showDeleteDialog = false"
  >
    <div>
      Are you sure you want to delete the following item:
      {{ deleteDialog }}
    </div>

    <template #footer>
      <Button
        label="Delete"
        icon="pi pi-times"
        class="p-button-text"
        style="background-color: red; color: white"
        @click="apiDelete(deleteDialog)"
      />
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="
          deleteDialog = null;
          showDeleteDialog = false;
        "
      />
    </template>
  </Dialog>
  <div>
    
        <v-btn
          color="primary"
          dark
          class="mb-2"
          style="margin-top: 30px"
          @click="newEntity({}, this.$route.query.entity)"
        >
          Add New {{ getEntityName() }}
        </v-btn>
      
        <div class="field-checkbox col active-section" v-if="this.$route.query.entity == 'horseforms'">
          <Checkbox id="horseFormExp" v-model="horseFormExp" :binary="true" />
          <label for="horseFormExp">Comprehensive horse form</label>
        </div>
      
  </div>
  <div v-if="getEntityName() === 'Horse-Form' && horseFormExp">
    <DataTable
      :value="formattedSearchResults"
      dataKey="_id"
      responsiveLayout="scroll"
      :stripedRows="true"
      :rowHover="true"
      rowGroupMode="subheader"
      groupRowsBy="horse_id"
      :expandableRowGroups="true"
      v-model:expandedRowGroups="expandedHFRowGroups"
      @rowgroupExpand="onHFRowGroupExpand"
      @rowgroupCollapse="onHFRowGroupCollapse"
    >
      <template #empty> No results found. </template>
      <template #loading> Searching... </template>
      <template #groupheader="slotProps">
        <span>
          <v-icon icon="mdi-horse"></v-icon>
          <b>&nbsp;{{ slotProps.data.horse_name }}</b> ({{
            slotProps.data.form_count
          }})</span
        >
      </template>
      <Column>
        <template #body="slotProps">
          <div class="flex-row">
            <div class="flex-column" v-for="i in 3" :key="`column-${i}`">
              <div
                v-for="(value, key) in divideData(slotProps.data, i)"
                :key="`column-${i}-${key}`"
                class="column-content"
                :class="{'red-text': (value || '').toString().startsWith('Unknown')}"
                :style="{ opacity: getOpacity(i) }"
              >
                {{ formatColumnValue(key, value,  slotProps.data) }}
              </div>
            </div>
          </div>
        </template>
      </Column>
      <Column
        headerStyle="width: 4rem; text-align: center"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-text p-button-result-edit"
            @click="editEntity(slotProps.data, this.$route.query.entity)"
          ></Button>
          <Button v-if="user_role < 5"
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-text p-button-result-delete"
            @click="deleteEntity(slotProps.data, this.$route.query.entity)"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
  <div v-else-if="getEntityName() === 'Horse-Form'">
    <DataTable :value="searchResults" dataKey="_id" responsiveLayout="scroll" :stripedRows="true"
               :rowHover="true" rowGroupMode="subheader" groupRowsBy="horse_id"
               :expandableRowGroups="true" v-model:expandedRowGroups="expandedHFRowGroups"
               @rowgroupExpand="onHFRowGroupExpand" @rowgroupCollapse="onHFRowGroupCollapse">
      <template #empty>
        No results found.
      </template>
      <template #loading>
        Searching...
      </template>
      <template #groupheader="slotProps">
      <span>
          <v-icon icon="mdi-horse"></v-icon>
        <b>&nbsp;{{ slotProps.data.horse_name }}</b> ({{ slotProps.data.form_count }})</span>
      </template>
      <Column v-for="col of setHeadersForEntity()" :field="col.field" :header="col.title" :key="col.field"
              sortable></Column>
      <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-result-edit"
                  @click="editEntity(slotProps.data, this.$route.query.entity)"></Button>
          <Button v-if="user_role < 5" type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text p-button-result-delete"
                  @click="deleteEntity(slotProps.data, this.$route.query.entity)"></Button>
        </template>
      </Column>
    </DataTable>
  </div>
  <div v-else>
    <DataTable
      :value="searchResults"
      dataKey="_id"
      responsiveLayout="scroll"
      :paginator="true"
      :stripedRows="true"
      :rowHover="true"
      :rows="10"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
    >
      <template #empty> No results found. </template>
      <template #loading> Searching... </template>
      <Column
        v-for="col of setHeadersForEntity()"
        :field="col.field"
        :header="col.title"
        :key="col.field"
        sortable
      ></Column>
      <Column
        headerStyle="width: 4rem; text-align: center"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-text p-button-result-edit"
            @click="editEntity(slotProps.data, this.$route.query.entity)"
          ></Button>
          <Button v-if="user_role < 5"
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-text p-button-result-delete"
            @click="deleteEntity(slotProps.data, this.$route.query.entity)"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
  <div v-if="getEntityName() === 'Horse'">
    <EditFormHorse
      :visible="showEditFormHorse"
      :initialItem="editedItem"
      :commonEntities="this.commonEntities"
      :current_user="current_user"
      :user_role="user_role"
      @visible="
        (event) => {
          this.showEditFormHorse = event;
        }
      "
    />
  </div>
  <div v-if="getEntityName() === 'Jockey'">
    <EditFormJockey
      :visible="showEditFormJockey"
      :initialItem="editedItem"
      :commonEntities="this.commonEntities"
      :current_user="current_user"
      :user_role="user_role"
      @visible="
        (event) => {
          this.showEditFormJockey = event;
        }
      "
    />
  </div>
  <div v-if="getEntityName() === 'Club'">
    <EditFormClub
      :visible="showEditFormClub"
      :initialItem="editedItem"
      :commonEntities="this.commonEntities"
      :current_user="current_user"
      :user_role="user_role"
      @visible="
        (event) => {
          this.showEditFormClub = event;
        }
      "
    />
  </div>
  <div v-if="getEntityName() === 'Trainer'">
    <EditFormTrainer
      :visible="showEditFormTrainer"
      :initialItem="editedItem"
      :commonEntities="this.commonEntities"
      :current_user="current_user"
      :user_role="user_role"
      @visible="
        (event) => {
          this.showEditFormTrainer = event;
        }
      "
    />
  </div>
  <div v-if="getEntityName() === 'Track'">
    <EditFormTrack
      :visible="showEditFormTrack"
      :initialItem="editedItem"
      :commonEntities="this.commonEntities"
      :current_user="current_user"
      :user_role="user_role"
      @visible="
        (event) => {
          this.showEditFormTrack = event;
        }
      "
    />
  </div>
  <div v-if="getEntityName() === 'Horse-Form'">
    <EditFormHorseForm
      :visible="showEditFormHorseForm"
      :initialItem="editedItem"
      :commonEntities="this.commonEntities"
      :current_user="current_user"
      :user_role="user_role"
      @visible="
        (event) => {
          this.showEditFormHorseForm = event;
        }
      "
    />
  </div>
</template>

<script>
//import {TabulatorFull as Tabulator} from "tabulator-tables"; //import Tabulator library
import "../../../node_modules/tabulator-tables/dist/css/tabulator_materialize.min.css";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import moment from "moment";

// eslint-disable-next-line
import Column from "primevue/column";
import EditFormHorse from "../../components/EditFormHorse";
import EditFormJockey from "../../components/EditFormJockey";
import EditFormClub from "../../components/EditFormClub";
import EditFormTrack from "../../components/EditFormTrack";
import EditFormTrainer from "../../components/EditFormTrainer";
import EditFormHorseForm from "../../components/EditFormHorseForm";
import CommonEntitiesLoader from "../../components/commonEntitiesLoader";
import Checkbox from 'primevue/checkbox';

import { API } from "aws-amplify";

export default {
  headersData: [],
  components: {
    EditFormHorse,
    EditFormJockey,
    EditFormClub,
    EditFormHorseForm,
    EditFormTrack,
    EditFormTrainer,
    DataTable,
    Column,
    Button,
    Toast,
    Dialog,
    CommonEntitiesLoader,
    Checkbox

  },
  data: () => ({
    showEditFormHorse: false,
    showEditFormHorseForm: false,
    showEditFormJockey: false,
    showEditFormTrainer: false,
    showEditFormTrack: false,
    showEditFormClub: false,
    showDeleteDialog: false,
    deleteDialog: null,
    commonEntities: null,
    loading: false,
    expandedHFRowGroups: [],
    horseFormExp: false,
    horses_schema: [
      {
        component: "h4",
        children: "Horse Details",
      },
      {
        component: "div",
        class: "flex-wrapper",
        children: [
          {
            label: "Name",
            name: "HRN_HORSE_NAME",
          },
          {
            label: "ID",
            name: "HRN_HORSE_ID",
            readonly: "readonly",
          },
        ],
      },
      {
        component: "div",
        class: "flex-wrapper",
        children: [
          {
            label: "Foaling Date",
            name: "HOR_FOALING_DATE",
            readonly: "readonly",
            type: "date",
          },
          {
            name: "HOR_COUNTRY_OF_ORIGIN",
            type: "select",
            label: "Country",
            options: {
              AUS: "Australia",
              NZ: "New Zealand",
            },
          },
        ],
      },

      {
        name: "HOR_SEX",
        type: "select",
        label: "Sex",
        options: {
          F: "Filly",
          C: "Colt",
          G: "Gelding",
          M: "Mare",
          R: "Rig",
          H: "Horse",
        },
      },
      {
        component: "div",
        class: "flex-wrapper",
        children: [
          {
            name: "HOR_SIRE_NAME",
            label: "Sire",
          },
          {
            name: "HOR_DAM_NAME",
            label: "Dam",
          },
        ],
      },
      {
        name: "HOR_SIRE_DAM_NAME",
        label: "Sire of Dam",
      },
      {
        component: "div",
        class: "flex-wrapper",
        children: [
          {
            name: "vselectTrainer",
            //  label: "vselectTrainer",
            component: "vSelect",
            options: [],
          },
          {
            name: "HOR_TRAINER_ID",
            label: "Trainer ID",
          },
          { name: "HOR_TRAINER_NAME", label: "Trainer Name" },
        ],
      },

      {
        name: "HOR_OWNER_NAMES",
        label: "Owners",
        type: "textarea",
        class: "formulate-input-full-width",
      },
      {
        name: "HOR_RACING_COLOURS",
        label: "Silks",
        class: "formulate-input-full-width",
      },
    ],
    horseforms_schema: [
      {
        component: "div",
        class: "row",
        children: [
          {
            component: "div",
            class: "column",
            children: [
              {
                component: "h4",
                children: "Meeting Details",
              },
              {
                name: "FRM_FORM_DATE",
                type: "date",
                label: "Meeting Date",
              },
              {
                name: "MTG_TRACK",
                type: "select",
                label: "Track",
              },
              {
                name: "MTG_CLB",
                type: "select",
                label: "Club",
                options: [], //this.allClubs //(this.allClubs == null ? [] : this.allClubs),
              },
              {
                name: "FRM_FINISH_POSITION",
                type: "number",
                label: "Race Position",
              },
            ],
          },
          {
            component: "div",
            class: "column",
            children: [
              {
                component: "h4",
                children: "Race Details",
              },
              {
                name: "FRM_RACE_NO",
                type: "number",
                label: "Race Number",
              },
              {
                name: "RACE_NAME",
                label: "Race Name",
              },
              {
                name: "RACE_SH_NAME",
                label: "Race Short Name",
              },
              {
                name: "FRM_FORM_TIME",
                type: "time",
                label: "Time",
              },
              {
                name: "RACE_DIST",
                type: "number",
                label: "Distance",
              },
              {
                name: "RACE_AGE_REST",
                type: "select",
                label: "Age Restrictions",
              },
              {
                name: "RACE_SEX_REST",
                type: "select",
                label: "Sex Restrictions",
              },
              {
                name: "RACE_WEIGHT_TYPE",
                type: "select",
                label: "Weight Type",
              },
              {
                name: "RACE_STARTERS",
                type: "number",
                label: "Starters",
              },
            ],
          },
          {
            component: "div",
            class: "column",
            children: [
              {
                component: "h4",
                children: "Horse Result",
              },
              {
                name: "FRM_FINISH_POSITION",
                type: "number",
                label: "Finish Position",
              },
              {
                name: "RACE_MARG",
                label: "Margin",
              },
              {
                name: "FRM_BARRIER_NO",
                type: "number",
                label: "Barrier",
              },
              {
                name: "FRM_JOCKEY_NAME",
                label: "Jockey",
                type: "select",
                options: [],
              },
              {
                name: "FRM_WEIGHT_CARRIED",
                type: "number",
                label: "Weight Carried",
              },
              {
                name: "RACE_WEIGHT_ADJ",
                type: "number",
                label: "Weight Adjustment",
              },
              {
                name: "FRM_FAVOURITE_IND",
                type: "checkbox",
                label: "Favourite",
              },
              {
                name: "RACE_REC",
                type: "checkbox",
                label: "Race Record",
              },
              {
                name: "RACE_HRPM",
                type: "number",
                label: "Horse Prizemoney",
              },
              {
                name: "RACE_EVPM",
                type: "number",
                label: "Race Prizemoney",
              },
              {
                name: "RACE_GEAR",
                label: "Gear Changes",
              },
            ],
          },
        ],
      },
    ],
    jockeys_schema: [
      {
        component: "div",
        class: "row",
        children: [
          {
            component: "div",
            class: "column",
            children: [
              {
                component: "div",
                class: "flex-wrapper",
                children: [
                  {
                    name: "JOC_JOCKEY_SURNAME",
                    label: "Surname",
                  },
                  {
                    name: "JOC_JOCKEY_ID",
                    type: "number",
                    readonly: true,
                    label: "Jockey ID",
                  },
                ],
              },
              {
                name: "JOC_JOCKEY_FIRSTNAME",
                label: "First Name",
              },
              {
                name: "JOC_JOCKEY_DISPLAYNAME",
                label: "Display Name",
              },
              {
                component: "div",
                class: "flex-wrapper",
                children: [
                  {
                    name: "JOC_STATE_REGISTERED",
                    type: "select",
                    label: "State",
                    options: {
                      1: "ACT",
                      2: "NSW",
                      3: "VIC",
                      4: "QLD",
                      5: "SA",
                      6: "WA",
                      7: "TAS",
                      8: "NT",
                      9: "NZ",
                      10: "HK",
                      11: "SGP",
                      99: "Aus",
                    },
                  },
                  {
                    name: "JOC_COUNTRY_CODE",
                    type: "select",
                    label: "Country",
                    options: [],
                  },
                ],
              },
              {
                component: "div",
                class: "flex-wrapper",
                children: [
                  {
                    name: "JOC_JOCKEY_APPRENTICE_IND",
                    type: "select",
                    label: "Apprentice Claim",
                    options: ["", "Y"],
                  },
                  {
                    name: "JOC_JOCKEY_AMATEUR_IND",
                    type: "select",
                    label: "Amateur",
                    options: ["", "Y"],
                  },
                  {
                    name: "JOC_JOCKEY_IN_USE_IND",
                    type: "select",
                    label: "Active",
                    options: ["", "Y"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    trainers_schema: [
      {
        component: "div",
        class: "row",
        children: [
          {
            component: "div",
            class: "column",
            children: [
              {
                component: "div",
                class: "flex-wrapper",
                children: [
                  {
                    name: "TRN_TRAINER_SURNAME",
                    label: "Surname",
                  },
                  {
                    name: "TRN_TRAINER_ID",
                    type: "number",
                    readonly: true,
                    label: "Trainer ID",
                  },
                ],
              },
              {
                name: "TRN_TRAINER_FIRSTNAME",
                label: "First Name",
              },
              {
                name: "TRN_TRAINER_DISPLAYNAME",
                label: "Display Name",
              },
              {
                component: "div",
                class: "flex-wrapper",
                children: [
                  {
                    name: "Location",
                    type: "select",
                    label: "Location",
                    options: [],
                  },
                  {
                    name: "State",
                    type: "select",
                    label: "State",
                    options: {
                      1: "ACT",
                      2: "NSW",
                      3: "VIC",
                      4: "QLD",
                      5: "SA",
                      6: "WA",
                      7: "TAS",
                      8: "NT",
                      9: "NZ",
                      10: "HK",
                      11: "SGP",
                      99: "Aus",
                    },
                  },
                ],
              },
              {
                component: "div",
                class: "flex-wrapper",
                children: [
                  {
                    name: "TRN_PARTNERSHIP_DISPLAYNAME",
                    type: "checkbox",
                    label: "Partnership",
                  },
                  {
                    name: "TRN_TRAINER_IN_USE_IND",
                    type: "checkbox",
                    label: "Active",
                  },
                  {
                    name: "RISATrainerID",
                    type: "number",
                    label: "RA ID",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    tracks_schema: [
      {
        component: "div",
        class: "row",
        children: [
          {
            component: "div",
            class: "column",
            children: [
              {
                component: "div",
                class: "flex-wrapper",
                children: [
                  {
                    name: "TRK_TRACK_NAME",
                    label: "Track Name",
                  },
                  {
                    name: "TRK_TRACK_DB_ID",
                    type: "number",
                    label: "Track ID",
                  },
                ],
              },

              {
                component: "div",
                class: "flex-wrapper",
                children: [
                  {
                    name: "TRK_TRACK_3CHAR_ABBREV",
                    label: "3 Char Abbrev",
                  },
                  {
                    name: "TRK_TRACK_6CHAR_ABBREV",
                    label: "6 Char Abbrev",
                  },
                  {
                    name: "TRK_RSB_TRACK_ABBREV",
                    label: "RA Abbrev",
                  },
                ],
              },
              {
                name: "TRK_SURF",
                type: "select",
                label: "Surface",
                options: {
                  Grass: "Grass",
                  Polytrack: "Polytrack",
                  Synthetic: "Synthetic",
                  Dirt: "Dirt",
                  Sand: "Sand",
                },
              },
              {
                name: "TRK_LENGTH_OF_STRAIGHT",
                label: "Length Straight",
              },
              {
                name: "TRK_LENGTH_OF_CIRCUIT",
                label: "Length Circuit",
              },
              {
                name: "TRK_IN_BETWEEN_MARGINS",
                type: "checkbox",
                label: "In Between Margins",
              },
              {
                name: "TRK_DIRECTION_OF_RUNNING",
                type: "select",
                label: "Direction",
                options: {
                  C: "Clock-wise",
                  A: "Anti Clock-wise",
                },
              },
              {
                name: "TRK_CLASS",
                type: "select",
                label: "Classification",
                options: {
                  Metro: "Metro",
                  Country: "Country",
                  Provincial: "Provincial",
                  NA: "N/A",
                },
              },
              {
                name: "TRK_COUNTRY_OF_TRACK",
                type: "select",
                label: "Country",
                options: {
                  AUS: "Australia",
                  FRA: "France",
                  HK: "Hong Kong",
                  NZ: "New Zealand",
                  SING: "Singapore",
                  UK: "United Kingdom",
                  USA: "United States",
                },
              },
              {
                name: "TRK_STATE_OF_TRACK",
                type: "select",
                label: "State",
                options: {
                  1: "ACT",
                  2: "NSW",
                  3: "VIC",
                  4: "QLD",
                  5: "SA",
                  6: "WA",
                  7: "TAS",
                  8: "NT",
                  9: "NZ",
                  10: "HK",
                  11: "SGP",
                  99: "Aus",
                },
              },
            ],
          },
        ],
      },
    ],
    clubs_schema: [
      {
        component: "div",
        class: "row",
        children: [
          {
            component: "div",
            class: "column",
            children: [
              {
                component: "div",
                class: "flex-wrapper",
                children: [
                  {
                    name: "CLB_CLUB_NAME",
                    label: "Club Name",
                  },
                  {
                    name: "CLB_CLUB_ID",
                    readonly: true,
                    label: "Club ID",
                  },
                ],
              },
              {
                name: "CLB_CLUB_ABBREV",
                label: "Club Abbreviation",
              },
              {
                component: "div",
                class: "flex-wrapper",
                children: [
                  {
                    name: "CLB_STATE",
                    type: "select",
                    label: "State",
                    options: {
                      1: "ACT",
                      2: "NSW",
                      3: "VIC",
                      4: "QLD",
                      5: "SA",
                      6: "WA",
                      7: "TAS",
                      8: "NT",
                      9: "NZ",
                      10: "HK",
                      11: "SGP",
                      99: "Aus",
                    },
                  },
                  {
                    name: "CLB_COUNTRY_CODE",
                    type: "select",
                    label: "Country",
                    options: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],

    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: [],
    defaultItem: [],
    displayRowFields: [
      "days_since_last_run",
      "finish_position",
      "meeting_date",
      "track_name",
      "race_number",
      "event_prizemoney",
      "race_name",
      "group",
      "class_value",
      "restrictions_age",
      "distance_metres",
      "track_condition",
      "track_grade",
      "limit_weight",
      "weight_type",
      "margin",
      "jockey_name",
      "barrier",
      "weight_carried",
      "event_duration",
      "sectional_time",
      "positions",
      "horse_prizemoney",
      "decimalprices",
      "rating_unadjusted",
      "otherRunner_1",
      "otherRunner_2",
      "otherRunner_3",
      "official_margin_1",
    ],
  }),
  mounted() {
    console.log("Search Results:");
    console.log(this.searchResults);
  },
  props: {
    searchResults: null,
    current_user: null,
    user_role: null
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    searchResults(val) {
      console.log("Search Results updated:");
      console.log(val);
    },
  },

  methods: {
    onHFRowGroupExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Row Group Expanded",
        detail: "Value: " + event.data,
        life: 3000,
      });
    },
    getOpacity(columnIndex) {
    switch(columnIndex) {
      case 1: return '1';
      case 2: return '0.9';
      case 3: return '0.8';
      default: return '1';
    }
  },
    divideData(data, part) {
      const orderedKeys = this.displayRowFields.filter((key) => key in data);
      const totalKeys = orderedKeys.length;
      const partSize = Math.ceil(totalKeys / 3);
      let startIndex, endIndex;
      if (part === 1) {
        startIndex = 0;
        endIndex = partSize;
      } else if (part === 2) {
        startIndex = partSize;
        endIndex = 2 * partSize;
      } else {
        startIndex = 2 * partSize;
        endIndex = totalKeys;
      }
      const partKeys = orderedKeys.slice(startIndex, endIndex);
      return Object.fromEntries(partKeys.map((key) => [key, data[key]]));
    },
    rowClass(data) {
      if (data.horse_prizemoney == 0) {
        return { "bg-danger": true };
      } else if (data.horse_prizemoney > 5000) {
        return { "bg-blue": true };
      }
    },
    onHFRowGroupCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Row Group Collapsed",
        detail: "Value: " + event.data,
        life: 3000,
      });
    },
    receiveCommonEntities(event) {
      this.commonEntities = event;
    },
    isFieldInFirstRow(field) {
      return this.firstRowFields.includes(field);
    },
    async getEntityDetails(entity, entityId) {
      if (entityId !== undefined || entityId !== 0)
        return API.get(
          "MrCenApiGateway",
          `/${entity.toLowerCase()}?id=${entityId}`
        );
    },
    async getHorseFormDetails(entity, entityId) {
      if (entityId !== undefined || entityId !== 0)
        return API.get("MrCenApiGateway", `/horse-form?horseid=${entityId}`);
    },
    getTrainerById(trainerId) {
      return this.commonEntities.allTrainers.find((t) => t.value === trainerId)
        .label;
    },
    async newEntity(data) {
      console.log(data);
      try {
        let entity = this.getEntityName();
        switch (entity.toLowerCase()) {
          case "horse":
            this.editedItem = {};
            this.showEditFormHorse = true;
            break;
          case "horse-form":
            this.editedItem = {};
            this.showEditFormHorseForm = true;
            break;
          case "jockey":
            this.editedItem = {};
            this.showEditFormJockey = true;
            break;
          case "trainer":
            this.editedItem = {};
            this.showEditFormTrainer = true;
            break;
          case "track":
            this.editedItem = {};
            this.showEditFormTrack = true;
            break;
          case "club":
            this.editedItem = {};
            this.showEditFormClub = true;
            break;
          default:
            console.log("couldnt find entity: " + entity);
            this.$toast.add({
              severity: "error",
              summary: "Cannot Edit Entity",
              detail: "This entity cannot be edited at this time.",
              life: 6000,
            });
            break;
        }
      } catch (err) {
        console.log("showing form error: " + err);
        this.$toast.add({
          severity: "error",
          summary: "Cannot Edit Entity",
          detail:
            "This entity cannot be edited at this time. Details:" +
            err.toString(),
          life: 6000,
        });
      }
    },
    async editEntity(data) {
      console.log(data);
      try {
        let entity = this.getEntityName();
        switch (entity.toLowerCase()) {
          case "horse":
            await this.getEntityDetails(entity, data.HRN_HORSE_ID).then(
              (entityDetails) => {
                entityDetails.data.HOR_FOALING_DATE = moment(
                  entityDetails.data.HOR_FOALING_DATE
                ).format("YYYY-MM-DD");
                entityDetails.data.HOR_DAM_NAME = data.HOR_DAM_NAME;
                entityDetails.data.HOR_SIRE_NAME = data.HOR_SIRE_NAME;
                entityDetails.data.HOR_SIRE_OF_DAM_NAME =
                  data.HOR_SIRE_OF_DAM_NAME;
                if (entityDetails.data.HOR_TRAINER_ID !== undefined)
                  entityDetails.data.HOR_TRAINER_ID = {
                    label: this.getTrainerById(
                      entityDetails.data.HOR_TRAINER_ID
                    ),
                    value: entityDetails.data.HOR_TRAINER_ID,
                  };
                this.editedItem = entityDetails.data;
                this.showEditFormHorse = true;
              }
            );
            break;
          case "horse-form":
            console.log("trying to show the horse form form");
            this.editedItem = data;
            this.showEditFormHorseForm = true;

            break;
          case "jockey":
            await this.getEntityDetails(entity, data._id).then(
              (entityDetails) => {
                this.editedItem = entityDetails.data;
                this.showEditFormJockey = true;
              }
            );
            break;
          case "trainer":
            await this.getEntityDetails(entity, data._id).then(
              (entityDetails) => {
                this.editedItem = entityDetails.data;
                this.showEditFormTrainer = true;
                console.log(entityDetails);
              }
            );
            break;
          case "track":
            await this.getEntityDetails(entity, data._id).then(
              (entityDetails) => {
                this.editedItem = entityDetails.data;
                this.showEditFormTrack = true;
                console.log(entityDetails);
              }
            );
            break;
          case "club":
            await this.getEntityDetails(entity, data._id).then(
              (entityDetails) => {
                this.editedItem = entityDetails.data;
                this.showEditFormClub = true;
                console.log(entityDetails);
              }
            );
            break;
          default:
            console.log("couldnt find entity: " + entity);
            this.$toast.add({
              severity: "error",
              summary: "Cannot Edit Entity",
              detail: "This entity cannot be edited at this time.",
              life: 6000,
            });
            break;
        }
      } catch (err) {
        console.log("showing form error: " + err);
        this.$toast.add({
          severity: "error",
          summary: "Cannot Edit Entity",
          detail:
            "This entity cannot be edited at this time. Details:" +
            err.toString(),
          life: 6000,
        });
      }
    },
    async apiDelete(data) {
      console.log(data);
      await API.del("MrCenApiGateway", `/${data.entity}`, {
        body: data,
      }).then(() => {
        this.$toast.add({
          severity: "success",
          summary: `the ${data.entity} ${data.name} was deleted`,
          life: 5000,
        });
      });

      this.showDeleteDialog = false;
      this.deleteDialog = null;
    },
    deleteEntity(data, entity) {
      console.log(`deleting a : ${entity}`);
      console.log(data);
      var deletingEntity = {};
      var theEntity = entity.toLowerCase(this.getEntityName(entity));

      if (theEntity == "horses") {
        deletingEntity._id = data._id;
        deletingEntity.name = data.HRN_HORSE_NAME;
        deletingEntity.HRN_HORSE_ID = data.HRN_HORSE_ID;
        deletingEntity.entity = "horse";
      } else if (theEntity == "horseforms") {
        deletingEntity.horse_id = data.horse_id;
        deletingEntity.event_id = data.event_id;
        deletingEntity.race_number = data.race["@_number"];
        deletingEntity.date = data.meeting_date;
        deletingEntity.track = data.track_name;
        deletingEntity.name = data.horse_name;
        deletingEntity.entity = "horse-form";
      } else if (theEntity == "jockeys") {
        deletingEntity._id = data._id;
        deletingEntity.name = data.JOC_JOCKEY_DISPLAYNAME;
        deletingEntity.JOC_JOCKEY_ID = data.JOC_JOCKEY_ID;
        deletingEntity.entity = "jockey";
      } else if (theEntity == "trainers") {
        deletingEntity._id = data._id;
        deletingEntity.name = data.TRN_TRAINER_DISPLAYNAME;
        deletingEntity.TRN_TRAINER_ID = data.TRN_TRAINER_ID;
        deletingEntity.entity = "trainer";
      } else if (theEntity == "tracks") {
        deletingEntity._id = data._id;
        deletingEntity.name = data.TRK_TRACK_NAME;
        deletingEntity.TRK_TRACK_DB_ID = data.TRN_TRAINER_ID;
        deletingEntity.entity = "track";
      } else if (theEntity == "clubs") {
        deletingEntity._id = data._id;
        deletingEntity.name = data.CLB_CLUB_NAME;
        deletingEntity.CLB_CLUB_ID = data.CLB_CLUB_ID;
        deletingEntity.entity = "club";
      } else {
        console.log("couldnt find entity: " + entity);
        this.$toast.add({
          severity: "error",
          summary: "Cannot Edit Entity",
          detail: "This entity cannot be edited at this time.",
          life: 6000,
        });
      }
      this.deleteDialog = deletingEntity;
      this.showDeleteDialog = true;
    },
    getAllCountries() {
      return this.allCountries;
    },
    getEntityName() {
      let entityName = this.$route.query.entity.substring(
        0,
        this.$route.query.entity.length - 1
      );

      return this.capitalize(entityName);
    },
    getSchema() {
      var schemaEntity = this.$route.query.entity
      switch (schemaEntity) {
        case "horses": {
          let countryCombo = this.horses_schema[2].children.filter((f) => {
            return f.name == "HOR_COUNTRY_OF_ORIGIN";
          })[0];
          if (countryCombo !== undefined)
            countryCombo.options = this.allCountries;
          let trainervSelect = this.horses_schema[6].children.filter((f) => {
            return f.name == "vselectTrainer";
          })[0];
          if (trainervSelect !== undefined)
            trainervSelect.options = this.allTrainers;

          return this.horses_schema;
        }
        case "horseforms": {
          let clubCombo = this.horseforms_schema[0].children[0].children.filter(
            (f) => {
              return f.name == "MTG_CLB";
            }
          )[0];
          if (clubCombo !== undefined) clubCombo.options = this.allClubs;
          let trackCombo =
            this.horseforms_schema[0].children[0].children.filter((f) => {
              return f.name == "MTG_TRACK";
            })[0];
          if (trackCombo !== undefined) trackCombo.options = this.allTracks;
          return this.horseforms_schema;
        }
        case "jockeys": {
          let countryCombo =
            this.jockeys_schema[0].children[0].children[3].children.filter(
              (f) => {
                return f.name == "JOC_COUNTRY_CODE";
              }
            )[0];
          if (countryCombo !== undefined)
            countryCombo.options = this.allCountries;
          return this.jockeys_schema;
        }
        case "trainers": {
          let trackCombo =
            this.trainers_schema[0].children[0].children[4].children.filter(
              (f) => {
                return f.name == "Location";
              }
            )[0];
          //TODO: should store track id in trainer
          let locationCombo = this.allTracks.map((t) => {
            return { field: t.label, label: t.label };
          });
          if (trackCombo !== undefined) trackCombo.options = locationCombo;
          return this.trainers_schema;
        }
        case "tracks": {
          return this.tracks_schema;
        }
        case "clubs": {
          let countryCombo =
            this.clubs_schema[0].children[0].children[2].children.filter(
              (f) => {
                return f.name == "CLB_COUNTRY_CODE";
              }
            )[0];
          if (countryCombo !== undefined)
            countryCombo.options = this.allCountries;
          return this.clubs_schema;
        }
      }
      return;
    },

    capitalize(str) {
      let cap =
        str == "horseform"
          ? "Horse-Form"
          : str.charAt(0).toUpperCase() + str.slice(1);
      return cap;
    },

    setHeadersForEntity() {
      var headerEntity = (this.$route.query.entity == 'horseforms' && this.horseFormExp) ? "horseform_exp" : this.$route.query.entity
      
      switch (headerEntity) {
        case "clubs": {
          console.log("test");
          return [
            {
              title: "Club Name",
              headerSort: true,
              field: "CLB_CLUB_NAME",
            },
            { title: "Abbreviation", field: "CLB_CLUB_ABBREV" },
            { title: "Country", field: "CLB_COUNTRY_CODE" },
            {
              title: "State",
              field: "CLB_STATE",
            },
          ];
        }
        case "horseforms": {
          return [
            {
              title: "Horse Name",
              headerSort: true,
              field: "horse_name",
            },
            {title: "Date of Meeting", field: "meeting_date"},
            {title: "Track Name", field: "track_name"},
            {
              title: "Finish Position",
              field: "finish_position",
            },
            {
              title: "Race Class",
              field: "class_name",
            },
            {
              title: "Distance",
              field: "distance_metres",
            },

          ];
        }
        case "horseforms_exp": {
          return [
            {
              title: "Days Since Last Run",
              field: "days_since_last_run",
            },
            {
              title: "Finish Position",
              field: "finish_position",
            },
            {
              title: "Starters",
              field: "starters",
            },
            { title: "Date of Meeting", field: "meeting_date" },
            {
              title: "Track Name",
              field: "track_name",
            },
            {
              title: "Race Number",
              field: "race_number",
            },
            {
              title: "Event Prize Money",
              field: "event_prizemoney",
            },
            {
              title: "Race Name",
              field: "race_name",
            },
            {
              title: "Group",
              field: "group",
            },
            {
              title: "Class Value",
              field: "class_value",
            },
            {
              title: "Restrictions Age",
              field: "restrictions_age",
            },
            {
              title: "Distance",
              field: "distance_metres",
            },
            {
              title: "Track Condition",
              field: "track_condition",
            },
            {
              title: "Track Grade",
              field: "track_grade",
            },
            {
              title: "Limit Weight",
              field: "limit_weight",
            },
            {
              title: "Weight Type",
              field: "weight_type",
            },
            {
              title: "Margin",
              field: "margin",
            },
            {
              title: "Jockey Name",
              field: "jockey_name",
            },
            {
              title: "Barrier",
              field: "barrier",
            },

            {
              title: "Weight Carried",
              field: "weight_carried",
            },
            {
              title: "Event Duration",
              field: "event_duration",
            },
            {
              title: "Sectional Time",
              field: "sectional_time",
            },
            {
              title: "Positions",
              field: "positions",
            },
            {
              title: "Horse Prize Money",
              field: "horse_prizemoney",
            },
            {
              title: "Decimal Prices",
              field: "decimalprices",
            },
            {
              title: "Rating Unadjusted",
              field: "rating_unadjusted",
            },
            {
              title: "Other Runner 2",
              field: "otherRunner_1",
            },
            {
              title: "Other Runner 3",
              field: "otherRunner_2",
            },
            {
              title: "Other Runner 4",
              field: "otherRunner_3",
            },
            {
              title: "Official Margin 1",
              field: "official_margin_1",
            },
            {
              title: "Official Margin 2",
              field: "official_margin_2",
            },
          ];
        }
        case "horses": {
          return [
            {
              title: "Horse Name",
              headerSort: true,
              field: "HRN_HORSE_NAME",
            },
            { title: "Country", field: "HOR_COUNTRY_OF_ORIGIN" },
            { title: "Foaling Date", field: "HOR_FOALING_DATE" },
            { title: "Dam", field: "HOR_DAM_COMB" },
            {
              title: "Sire",
              field: "HOR_SIRE_COMB",
            },
            {
              title: "Sire of Dam",
              field: "HOR_SIRE_OF_DAM_COMB",
            },
          ];
        }
        case "jockeys": {
          return [
            {
              title: "Surname",
              headerSort: true,
              field: "JOC_JOCKEY_SURNAME",
            },
            { title: "First Name", field: "JOC_JOCKEY_FIRSTNAME" },
            { title: "Display Name", field: "JOC_JOCKEY_DISPLAYNAME" },
            { title: "Apprentice", field: "JOC_JOCKEY_APPRENTICE_IND" },
            { title: "Riding Weight", field: "JOC_JOCKEY_WEIGHT" },
            { title: "State", field: "JOC_STATE_REGISTERED" },
            { title: "Country", field: "JOC_COUNTRY_CODE" },
          ];
        }
        case "tracks": {
          return [
            {
              title: "Track Name",
              headerSort: true,
              field: "TRK_TRACK_NAME",
            },
            { title: "Country", field: "TRK_COUNTRY_OF_TRACK" },
            { title: "State", field: "TRK_STATE_OF_TRACK" },
            {
              title: "Location Type/Classification",
              field: "TRK_LOCATION",
            },
          ];
        }
        case "trainers": {
          return [
            {
              title: "Surname",
              headerSort: true,
              field: "TRN_TRAINER_SURNAME",
            },
            { title: "First Name", field: "TRN_TRAINER_FIRSTNAME" },
            { title: "Display Name", field: "TRN_TRAINER_DISPLAYNAME" },
            {
              title: "Partnership",
              field: "TRN_PARTNERSHIP_DISPLAYNAME",
            },
          ];
        }

        default: {
          return [];
        }
      }
    },
    formatColumnValue(key, value, slotProps) {
      const fieldName = key;

      switch (fieldName) {
        case "event_prizemoney":
          return this.formatCurrency(value);
        case "finish_position":
          return `Final Position: ${value} of ${slotProps.starters}`;
        case "official_margin_1":
          return `Official Margin : ${value} x ${slotProps.official_margin_2}`;
        case "group":
          return `Group : ${value}`;
        case "horse_prizemoney":
          return `P'money Won: ${this.formatCurrency(value)}`;
        case "distance_metres":
          return `${value} Metres`;
        case "limit_weight":
          return `Limit Weight : ${value} kg`;
        case "weight_type":
          return `Weight Type : ${value}`;
        case "margin":
          return `Margin : ${value}`;
        case "barrier":
          return `Barrier : ${value}`;
        case "weight_carried":
          return `Weight Carried : ${value} kg`;
        case "event_duration":
          return `Event Duration : ${value}`;
        default:
          return value;
      }
    },

    formatCurrency(value) {
      // Ensure the value is a number before formatting as currency
      const numberValue = Number(value);
      return isNaN(numberValue) ? value : `$${numberValue.toFixed(2)}`;
    },
  },
  computed: {
    formattedSearchResults() {
      let trackMap = new Map([
            ["M","Firm"],
            ["G","Good"],
            ["F","Fast"],
            ["S","Slow"],
            ["H","Heavy"],
            ["O","Soft"],
            ["A","Sand"],
            ["R","Fair"],
            ["D","Dead"],
            ["V","Very Heavy"],
            ["L","Holding"],
            ["Y","Synthetic"],
            ["Firm","Firm"],
            ["Good","Good"],
            ["Soft","Soft"],
            ["Heavy","Heavy"],
            ["Synthetic","Synthetic"],
            ["Fast","Fast"],
            ["Sand","Sand"],
            ["Fair","Fair"],
            ["Dead","Dead"],
            ["Slow","Slow"],
            ["Very Heavy","Very Heavy"],
            ["Holding","Holding"],
            ["Dirt","Dirt"],
            ["Wet","Wet"]
        ]);
      let ageMap = new Map([
        [1, "2yo"],
        [2, "2yo+"],
        [3, "2,3,4yo"],
        [4, "3yo"],
        [5, "3yo+"],
        [6, "4yo"],
        [7, "4yo+"],
        [8, "5yo"],
        [9, "5yo+"],
        [10, "6yo"],
        [11, "Aged"],
        [12, "2,3yo"],
        [13, "3,4yo"],
        [14, "3,4,5yo"],
        [15, "4,5yo"],
        [16, "4,5,6yo"],
        [17, "3,4,5,6"]
    ]);
      return this.searchResults.map((item) => {
        // This check ensures that you only try to access '@_name' if 'race' is an object.
        const raceName =
          item.race && item.race["@_name"]
            ? `Race: ${item.race["@_name"]}`
            : "Unknown Race";
        const trackCondition =
          item.track && item.track["@_condition"]
            ? `Track Condition: ${trackMap.get(item.track["@_condition"])}`
            : "Unknown Condition";
        const trackGrade =
          item.track && item.track["@_grading"]
            ? `Track Grade: ${item.track["@_grading"]}`
            : "Unknown Grade";
        const jockeyName =
          item.jockey && item.jockey["@_name"]
            ? `Jockey: ${item.jockey["@_name"]}`
            : "Unknown Jockey";
        const restrictionsAge =
          item.restrictions && item.restrictions["@_age"]
            ? `Age Restriction: ${ageMap.get(item.restrictions["@_age"])}`
            : "Unknown Age";
        const raceNumber =
          item.race && item.race["@_number"]
            ? `Race : ${item.race["@_number"]}`
            : "Unknown number";
        const classValue =
          item.classes && item.classes["class"]
            ? `Class : ${item.classes["class"]}`
            : "Unknown class";
        const trackName =
          item.track && item.track["@_name"]
            ? `Track  : ${item.track["@_name"]}`
            : "Unknown Track";
        const sectionalTime =
          item.sectional && item.sectional["@_time"]
            ? `Sectional Time : ${item.sectional["@_time"]}`
            : "Unknown Sectional Time";
        const ratingUnadjusted =
          item.rating && item.rating["@_unadjusted"]
            ? `Rating Unadjusted: ${item.rating["@_unadjusted"]}`
            : "Unknown Rating";
        const positionValue = item.positions
          ? `800 m - ${item.positions["@_m800"]}, 400 - ${item.positions["@_m400"]}, finished - ${item.finish_position}`
          : "Unknown Position";
        const decimalPrices = item.decimalprices
          ? `Open - ${item.decimalprices["@_opening"]}, Start - ${item.decimalprices["@_starting"]}`
          : "Unknown Prices";
        const daysSinceLastRun = item.days_since_last_run
          ? `Days Since Last Run: ${item.days_since_last_run}`
          : "Unknown Days";
        const runnersDetails =
          item.other_runners && item.other_runners.other_runner
            ? item.other_runners.other_runner.map((runner) => {
                return `Position: ${runner["@_position"]}, Horse: ${runner["@_horse"]}, Weight: ${runner["@_weight"]}`;
              })
            : [];

        // Initialize an object to hold the runners' details
        const runners = {};
        runnersDetails.forEach((detail, index) => {
          // Property names like otherRunner_1, otherRunner_2, etc.
          runners[`otherRunner_${index + 1}`] = detail;
        });
        return {
          ...item,
          race_name: raceName,
          track_condition: trackCondition,
          track_grade: trackGrade,
          jockey_name: jockeyName,
          restrictions_age: restrictionsAge,
          race_number: raceNumber,
          class_value: classValue,
          track_name: trackName,
          sectional_time: sectionalTime,
          rating_unadjusted: ratingUnadjusted,
          days_since_last_run: daysSinceLastRun,
          positions: positionValue,
          decimalprices: decimalPrices,
          ...runners,
        };
      });
    },
  },
};
</script>

<style>
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.formulate-input {
  min-width: 15em;
}

.flex-wrapper {
  max-width: 20em;
  display: flex;
}

.flex-wrapper .formulate-input:first-child {
  margin-right: 0.5em;
}

.flex-wrapper .formulate-input:last-child {
  margin-left: 0.5em;
}

.formulate-input-full-width .formulate-input-element {
  min-width: 30em !important;
  max-width: 100% !important;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 20px;
}

.formulate-input-dropdown {
  background-color: #fff;
  margin: -3px 0 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid #cecece;
  border-top-color: #41b883;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.column-grade-1 {
  background-color: #f0f8ff; /* Light azure */
}

.column-grade-2 {
  background-color: #e6f0fa; /* Slightly darker azure */
}

.column-grade-3 {
  background-color: #ccdfeb; /* Even more darker azure */
}

.formulate-input-dropdown li {
  padding: 0.5em 1em;
  margin: 0;
}

.formulate-input-dropdown li:hover,
.formulate-input-dropdown li[data-is-selected] {
  background-color: rgba(0, 0, 0, 0.05);
}

.page .theme-default-content .formulate-input {
  position: relative;
  z-index: 2;
}
.p-datatable-tbody > tr > td {
  max-height: 45px;
}
.bg-danger {
  background-color: #c9503d !important;
  color: #0a0102 !important;
  border-color: #af0516 !important;
}
.bg-blue {
  background-color: #ffffff !important;
  color: #1308b3 !important;
  border-color: #bee5eb !important;
}
.row-block {
  display: flex;
  flex-wrap: wrap;
}
.column-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Divide into 3 equal columns */
  grid-gap: 10px; /* Adjust for spacing between columns */
}
.column-content {
  transition: opacity 0.5s ease-in-out;
}
.red-text {
  color: red; 
}


.column-wrapper .p-column-title {
  /* Adjust header styling if needed */
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row {
  background: linear-gradient(to right, #f0f8ff, #e6f0fa, #ccdfeb);
  display: flex;
}
.flex-column {
  flex: 1;
  padding: 10px; /* Adjust padding as needed */
}

.column {
  flex: 0 0 calc(100% / 7);
  box-sizing: border-box;
  padding: 4px;
}
</style>
