<script>
import {API} from "aws-amplify";
export default {
  data: () => ({
    allCountries: [],
    allClasses: [],
    allTracks: [],
    allClubs: [],
    allTrainers: [],
    allJockeys: [],
    commonEntities: {
      allCountries: null,
      allClasses: null,
      allTracks: null,
      allClubs: null,
      allTrainers: null,
      allJockeys: null
    }
  }),
  methods: {
    async fetchCommonEntities() {
      console.log('Warm up db queries...');
      API.get("MrCenApiGateway", `/admin/ping`);
      if (this.allCountries == undefined || this.allCountries.length == 0) {
        this.commonEntities.allCountries = await this.fetchAll(
            "countries",
            "COUNTRY_INPUT_VAL",
            "COUNTRY_DISPLAY_NAME",
            false
        );
      }
      if (this.allClasses == undefined || this.allClasses.length == 0) {
        this.commonEntities.allClasses = await this.fetchAll(
            "race_classes",
            "CLA_CLASS_DB_ID",
            "CLA_CLASS_LONG_DISP",
            false
        );
      }
      if (this.allClubs == undefined || this.allClubs.length == 0)
        this.commonEntities.allClubs = await this.fetchAll(
            "clubs",
            "CLB_CLUB_ID",
            "CLB_CLUB_NAME"
        );

      if (this.allTracks == undefined || this.allTracks.length == 0)

        this.commonEntities.allTracks = await this.fetchAll(
            "tracks",
            "TRK_TRACK_DB_ID",
            "TRK_TRACK_NAME"
        );

      if (this.allJockeys == undefined || this.allJockeys.length == 0)

        this.commonEntities.allJockeys = await this.fetchAll(
            "jockeys",
            "JOC_JOCKEY_ID",
            "JOC_JOCKEY_DISPLAYNAME"
        );

      if (this.allTrainers == undefined || this.allTrainers.length == 0)
        this.commonEntities.allTrainers = await this.fetchAll(
            "trainers",
            "TRN_TRAINER_ID",
            "TRN_TRAINER_DISPLAYNAME"
        );

    },

    async fetchAll(entity, valueField, labelField, valueIsNumber = true) {
      console.log("Fetching all " + entity + "...");
      if (entity !== undefined || entity !== "")
        return API.get("MrCenApiGateway", `/all?table=${entity}`).then(
            (response) => {
              if (response.data !== undefined && response.data !== "") {
                console.log("Done fetching " + entity + ". Now accessible in common entities object.");
                var optionsFormatted = response.data
                    .filter((rec) => {
                      if (valueIsNumber) return parseInt(rec[valueField]) !== "NaN";
                      else return rec;
                    })
                    .map((c) => {
                      if (valueIsNumber) {
                        return {
                          value: parseInt(c[valueField]),
                          label: c[labelField],
                        };
                      } else {
                        return {
                          value: c[valueField],
                          label: c[labelField],
                        };
                      }
                    })
                    .sort((a, b) => {
                      if (a.label < b.label) return -1;
                      else if (a.label == b.label) return 0;
                      else return 1;
                    });
                return optionsFormatted;
              }
            }
        );
      else return null;
    },
  },

  mounted() {
    this.fetchCommonEntities().then(() => {
      console.log("Emitting Common entities...")
      console.log(this.commonEntities);
      this.$emit("entities", this.commonEntities);
    })
  }
}
</script>


