import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    UPDATE_INPUT_BADGE(state, badge_value) {
      state.inputBadge.failed = badge_value;
    }
  },
  getters: {
    isSignIn: (state) => {
      return state.user !== null
    },
  },
  actions: {},
  modules: {}
})
