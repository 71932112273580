<template>
  <CommonEntitiesLoader @entities="receiveCommonEntities" />
  <Toast />
  <div width="90%" style="margin: 30px">
    <div class="text-overline mb-1">Date Selector</div>
    <div style="display: flex; padding: 2px; align-items: center">
      <v-btn-toggle
        class="mx-auto"
        v-model="customDatePicker.selectedId"
        tile
        color="info"
        group
        mandatory
        @click="
          this.selectedDate = null;
          fetchMeetings(
            customDatePicker.dates[customDatePicker.selectedId].sortableDate
          );
        "
      >
        <v-btn
          v-for="date in customDatePicker.dates"
          :key="date.id"
          :color="date.color"
        >
          {{ date.date }}
        </v-btn>
      </v-btn-toggle>
      <FormKit
        type="date"
        label="Pick an alternative date"
        :value="
          customDatePicker.dates[customDatePicker.selectedId].sortableDate
        "
        @input="
          (val) => {
            this.selectedDate = val;
            fetchMeetings(val);
          }
        "
      />
    </div>
  </div>
  <div width="90%" style="margin: 30px">
    <div class="text-overline mb-1" style="display: flex">
      <v-row>
        <v-col>
          {{ getMeetingsSectionTitle() }}
        </v-col>
        <v-col>
          <Button
            label="Refresh"
            icon="pi pi-sync"
            iconPos="right"
            @click.stop="fetchMeetings(selectedDate)"
          >
          </Button>
        </v-col>
        <v-col style="max-width: fit-content">
          <Dropdown
            v-model="selectedStageFilter"
            :options="stage_items"
            placeholder="Filter by Stage"
            @change="filterMeetings"
            style="text-transform: none; letter-spacing: normal"
          ></Dropdown>
        </v-col>
      </v-row>
    </div>
    <Dialog
      v-model="errorSummary"
      v-model:visible="showErrorDialog"
      :style="{ width: '600px' }"
      header="Errors"
      :modal="true"
      class="p-fluid"
      :closeable="false"
      @hide="
        errorSummary = null;
        selectedMeeting = null;
      "
    >
      <div>
        {{ errorSummary }}
      </div>

      <template #footer>
        <Button
          label="Clear Errors"
          icon="pi pi-times"
          class="p-button-text"
          @click="
            clearMeetingErrors(
              selectedMeeting._id,
              selectedMeeting.processedMeetingData.meeting.track['@_name']
            );
            errorSummary = null;
            showErrorDialog = false;
          "
        />
        <Button
          label="Close"
          icon="pi pi-times"
          class="p-button-text"
          @click="
            errorSummary = null;
            showErrorDialog = false;
          "
        />
      </template>
    </Dialog>

    <Dialog
      v-model="deletedMeeting"
      v-model:visible="showDeleteMeetingDialog"
      :style="{ width: '600px' }"
      header="Are you sure you wish to Delete this Meeting?"
      :modal="true"
      class="p-fluid"
      :closeable="false"
      @hide="deletedMeeting = null"
    >
      <div>
        {{ deletedMeeting.processedMeetingData.meeting.track["@_name"] }} -
        {{ deletedMeeting.meetingDate }}
      </div>

      <template #footer>
        <Button
          v-if="user_role < 5"
          label="Delete"
          icon="pi pi-times"
          class="p-button-text"
          @click="
            deleteMeeting(
              deletedMeeting._id,
              deletedMeeting.processedMeetingData.meeting.track['@_name']
            );
            deleteSummary = null;
            showDeleteMeetingDialog = false;
          "
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="
            deletedMeeting = null;
            showDeleteMeetingDialog = false;
          "
        />
      </template>
    </Dialog>

    <Dialog
      v-model="selectedMeeting"
      v-model:visible="showEditMeetingDialog"
      :style="{ width: '450px' }"
      header="Edit Meeting Details"
      :modal="true"
      class="p-fluid"
      :closeable="false"
      @hide="selectedMeeting = null"
    >
      <div class="field" v-if="user_role < 7">
        <label for="selectedStage">Stage</label>
        <Dropdown
          id="selectedStage"
          v-model="selectedMeeting.meetingStage"
          :options="stage_map"
          optionLabel="label"
          optionValue="value"
          placeholder="Select a Stage"
        >
        </Dropdown>
      </div>
      <div class="field" v-if="user_role < 7">
        <label for="selectedTab">Tab Indicator</label>
        <Dropdown
          id="selectedTab"
          v-model="selectedMeeting.processedMeetingData.meeting.tab_indicator"
          :options="tab_values"
          optionLabel="label"
          optionValue="value"
          placeholder="Select a Tab Indicator"
        >
        </Dropdown>
      </div>
      <div class="field" v-if="user_role < 7">
        <label for="selectedCond">Expected Condition</label>
        <Dropdown
          id="selectedCond"
          v-model="
            selectedMeeting.processedMeetingData.meeting.track[
              '@_expected_condition'
            ]
          "
          :options="condition_options"
          optionLabel="label"
          optionValue="value"
        >
        </Dropdown>
      </div>
      <div class="field" v-if="user_role < 5">
        <label for="selectedSilks">Silks Ready</label>
        <Dropdown
          id="selectedSilks"
          v-model="selectedMeeting.processedMeetingData.meeting.silksReady"
          :options="silks_values"
          optionLabel="label"
          optionValue="value"
          placeholder="Not Ready"
        >
        </Dropdown>
      </div>

      <div class="field" v-if="user_role < 5">
        <label for="selectedRail">Rail Position</label>
        <InputText
          id="selectedRail"
          v-model="selectedMeeting.processedMeetingData.meeting.rail_position"
        >
        </InputText>
      </div>
      <div class="field">
        <Button
          label="Distribute Gear"
          icon="pi pi-times"
          class="p-button-text"
          @click="
            distributeGears(selectedMeeting);
            showEditMeetingDialog = false;
          "
        />
      </div>
      <div class="field">
        <Button
          label="Generate Comment"
          icon="pi pi-times"
          class="p-button-text"
          @click="
            generateComment(selectedMeeting);
            showEditMeetingDialog = false;
          "
        />
      </div>
      <div class="field" v-if="user_role < 5">
        <Button
          label="Delete Meeting"
          icon="pi pi-times"
          class="p-button-text"
          style="color: red"
          @click="
            deletedMeeting = selectedMeeting;
            showDeleteMeetingDialog = true;
            showEditMeetingDialog = false;
          "
        />
      </div>
      <div class="field" v-if="user_role < 5">
        <Button
          label="Transfer Meeting"
          icon="pi pi-times"
          class="p-button-text"
          style="color: rgb(0, 55, 255)"
          @click="
            showTransferMeetingDialog = true;
            transferredMeeting = selectedMeeting;
            showEditMeetingDialog = false;
            this.fetchDefaultTracks();
          "
        />
      </div>
      <div class="field" v-if="user_role < 5">
        <Button
          label="Obliterate Meeting"
          icon="pi pi-times"
          class="p-button-text"
          style="color: red; margin-top: 80px"
          @click="
            deletedMeeting = selectedMeeting;
            showDeleteMeetingDialog = true;
            showEditMeetingDialog = false;
            deleteOblit = true;
          "
        />
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="showEditMeetingDialog = false"
        />
        <Button
          v-if="user_role < 7"
          label="Update"
          icon="pi pi-check"
          class="p-button-text"
          @click="updateMeeting()"
        />
      </template>
    </Dialog>

    <Dialog
      v-model="meetingHistory"
      v-model:visible="showMeetingHistoryDialog"
      :style="{ width: '80vw' }"
      header="Meeting file history"
      :modal="true"
      class="p-fluid"
      :closeable="false"
      @hide="meetingHistory = null"
    >
      <Accordion>
        <AccordionTab
          v-for="file_item in meetingHistory"
          :key="file_item.time"
          class="file-history-div"
        >
          <template #header>
            <div class="v-col-3 file-history-file-name">
              {{ file_item.file_path.replace("unprocessed/", "") }}
            </div>
            <div class="v-col-2">
              {{ getFormattedDate(file_item.time) }}
            </div>
            <div class="v-col-2">
              {{ file_item.trigger ?? "" }}
            </div>
            <div class="v-col-4">
              {{
                file_item.changes && file_item.changes.notes
                  ? file_item.changes.notes
                  : ""
              }}
            </div>
            <div class="v-col-1 justify-start">
              <Button
                v-if="
                  file_item.trigger != 'USER EDITS' &&
                  checkIfFileName(
                    file_item.file_path.replace('unprocessed/', '')
                  ) &&
                  user_role < 7
                "
                icon="pi pi-refresh"
                class="p-button-rounded p-button-primary mr-2"
                @click.stop="
                  reloadMeeting(file_item);
                  meetingHistory = null;
                  showMeetingHistoryDialog = false;
                "
              />
            </div>
          </template>
          <div v-if="file_item.changes" class="file-history-changelog">
            <!-- <div v-if="file_item.changes.summary">
                {{ file_item.changes.summary }}
              </div> -->
            <div v-if="file_item.changes.meeting">
              <div
                v-for="(item, i) in file_item.changes.meeting"
                :key="i"
                class="row"
              >
                <div class="col-2">
                  <b>{{ i }}</b>
                </div>

                <div v-if="Array.isArray(item)" class="col-4">
                  {{ item[0] }}&nbsp;<b>TO:</b>&nbsp;{{ item[1] }}
                </div>

                <div class="col" v-else v-for="(subitem, k) in item" :key="k">
                  <b>{{ k }}:</b>&nbsp;{{ subitem[0] }}&nbsp;<b>TO:</b>&nbsp;{{
                    subitem[1]
                  }}
                </div>
              </div>
              <!-- {{ file_item.changes.meeting }} -->
            </div>

            <Accordion v-if="file_item.changes.races" lazy>
              <AccordionTab
                v-for="(race, i) in file_item.changes.races"
                :key="i"
              >
                <template #header>
                  <div class="col">
                    <b>{{ i }}</b>
                  </div>
                  <div class="col" v-if="race['@_ra_name']">NAME UPDATE</div>
                  <div class="col" v-if="race.start_time">
                    START TIME UPDATE
                  </div>
                  <!-- <div class="col" v-if="race_counter[i].scratchings > 0">{{ counter[i].scratchings }}&nbsp;scratched</div> -->
                </template>
                <div v-for="(item, j) in race" :key="j">
                  <div
                    v-if="
                      [
                        'horses',
                        'track',
                        'distance',
                        'restrictions',
                        'classes',
                        'prizes',
                        'records',
                        'sectional',
                        'error',
                      ].includes(j)
                    "
                  >
                    <div class="row" v-if="j != 'horses'">
                      <div class="col-2">
                        <b>{{ j }}:</b>
                      </div>
                      <div class="col" v-for="(subitem, k) in item" :key="k">
                        <b>{{ k }}:</b>&nbsp;{{
                          subitem[0]
                        }}&nbsp;<b>TO:</b>&nbsp;{{ subitem[1] }}
                      </div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-3">
                        <b>{{ j }}:</b>
                      </div>
                    </div>
                    <Accordion lazy v-if="j == 'horses'">
                      <AccordionTab v-for="(subitem, k) in item" :key="k">
                        <template #header>
                          <div class="col-3">
                            <b>{{ k }}</b>
                          </div>
                          <div v-if="subitem.notes" class="col-3">
                            {{
                              subitem.notes[1]
                            }}
                          </div>
                          <div class="col-2">
                            {{
                              subitem.scratched && subitem.scratched[1]
                                ? "SCRATCHED"
                                : ""
                            }}
                          </div>
                          <div class="col-2">
                            {{
                              subitem.jockey && subitem.jockey["@_name"]
                                ? "JOCKEY UPDATE"
                                : ""
                            }}
                          </div>
                          <div class="col-2">
                            {{ subitem.statistics ? "STATS UPDATE" : "" }}
                          </div>
                        </template>
                        <div v-for="(hsnip, l) in subitem" :key="l" class="row">
                          <div class="col-2">
                            <b>{{ l }}</b>
                          </div>
                          <div v-if="Array.isArray(hsnip)" class="col-4">
                            <div v-if="Array.isArray(hsnip[0])">
                              <span v-for="(snp, m) of hsnip" :key="m"
                                >{{ snp[0] }}&nbsp;<b>TO:</b>&nbsp;{{
                                  snp[1]
                                }}</span
                              >
                            </div>
                            <span v-else
                              >{{ hsnip[0] }}&nbsp;<b>TO:</b>&nbsp;{{
                                hsnip[1]
                              }}</span
                            >
                          </div>

                          <div
                            class="col"
                            v-else
                            v-for="(subsnip, m) in hsnip"
                            :key="m"
                          >
                            <b>{{ m }}:</b>&nbsp;{{
                              subsnip[0]
                            }}&nbsp;<b>TO:</b>&nbsp;{{ subsnip[1] }}
                          </div>
                        </div>

                        <!-- {{ subitem }} -->
                      </AccordionTab>
                    </Accordion>
                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="col-2">
                        <b>{{ j }}:</b>
                      </div>
                      <div class="col-4">
                        {{ item[0] }}&nbsp;<b>TO:</b>&nbsp;{{ item[1] }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- {{ race }} -->
              </AccordionTab>
            </Accordion>
          </div>
        </AccordionTab>
      </Accordion>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="
            meetingHistory = null;
            showMeetingHistoryDialog = false;
          "
        />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="showTransferMeetingDialog"
      :style="{ width: '450px' }"
      header="Transfer Meeting"
      :modal="true"
      class="p-fluid"
      @hide="showTransferMeetingDialog = false"
    >
      <div>
        <div class="field">
          <label for="transferDate">Transfer Date</label>
          <InputText
            id="transferDate"
            type="date"
            v-model="transferMeetingDate"
            :min="tomorrowDate"
          />
        </div>
        <div class="field">
          <label for="trackSelection">Select Track</label>
          <Dropdown
            id="trackSelection"
            v-model="selectedTrack"
            :options="trackOptions"
            optionLabel="label"
            optionValue="value"
            placeholder="Select a Track"
          />
        </div>
      </div>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="showTransferMeetingDialog = false"
        />
        <Button
          label="Transfer"
          icon="pi pi-check"
          class="p-button-text"
          @click="transferMeeting()"
        />
      </template>
    </Dialog>

    <v-card class="mx-auto mt-9" v-if="meetings_data && meetings_data.data">
      <Accordion lazy>
        <AccordionTab v-for="meeting in meetings_data.data" :key="meeting._id">
          <template #header>
            <v-row>
              <div v-if="meeting.meetingErrorCount > 0">
                <button
                  class="pi pi-info-circle"
                  style="
                    color: red;
                    position: absolute;
                    margin: -5px 0 0 -25px;
                  "
                  @click.stop="
                    selectedMeeting = meeting;
                    errorSummary =
                      meeting.meetingErrorCount +
                      ' errors\n' +
                      meeting.processedMeetingData.meeting.errors.join('\n');
                    showErrorDialog = true;
                  "
                ></button>
              </div>
              <div
                v-if="
                  meeting.meetingLocked && meeting.meetingLocked != 'unlocked'
                "
              >
                <p class="p-locked-meet">
                  {{
                    meeting.meetingLocked == "load"
                      ? "LOCKED FOR PEGASUS LOAD"
                      : meeting.meetingLocked == "update"
                      ? "LOCKED FOR UPDATES"
                      : "LOCKED BY USER " + meeting.meetingLocked
                  }}
                </p>
              </div>
              <div
                class="v-col-2 v-col-xl d-flex justify-start"
                style="font-weight: 600"
              >
                <v-icon icon="mdi-map-marker"></v-icon>
                {{
                  "&nbsp;" +
                  meeting.processedMeetingData.meeting.track["@_name"]
                }}
              </div>
              <div
                class="v-col-1 d-none d-lg-flex justify-start"
                style="font-weight: normal"
              >
                {{
                  "&nbsp;" +
                  meeting.processedMeetingData.meeting.track["@_state"]
                }}
              </div>
              <div
                class="v-col-1 d-none d-lg-flex justify-start"
                style="font-weight: normal"
              >
                {{
                  getClassifications(
                    meeting.processedMeetingData.meeting.track["@_location"]
                  )
                }}
              </div>
              <div
                class="v-col-2 v-col-lg-1 d-flex justify-start"
                style="font-weight: normal"
              >
                {{
                  getMeetingStage(meeting.processedMeetingData.meeting.stage)
                }}
              </div>
              <div
                class="v-col-1 d-flex justify-start"
                style="font-weight: normal"
              >
                {{
                  getTabIndicator(
                    meeting.processedMeetingData.meeting.tab_indicator
                  )
                }}
              </div>
              <div
                class="v-col-2 v-col-lg-1 d-flex justify-start"
                style="font-weight: normal"
              >
                <i class="pi pi-calendar"></i>
                {{
                  "&nbsp;" +
                  checkDates(meeting.updatedAt, meeting.createdAt) +
                  "&nbsp;&nbsp;"
                }}
              </div>
              <div class="v-col-5 v-col-xl-3 d-flex justify-start">
                <div class="v-row" v-if="user_role < 8">
                  <div class="col">
                    <Transition>
                      <Button
                        v-if="
                          !meeting.meetingLocked ||
                          meeting.meetingLocked == 'unlocked'
                        "
                        icon="pi pi-pencil"
                        :class="[
                          'p-button-rounded p-button-primary p-button-save',
                          {
                            'p-button-locked': meeting.meetingLocked === 'load',
                          },
                        ]"
                        @click.stop="
                          claimMeeting(
                            meeting._id,
                            current_user.username,
                            'claim'
                          )
                        "
                        v-tooltip.top="'Edit Meeting'"
                        :disabled="meeting.meetingLocked === 'load'"
                      />
                      <!-- </Transition>
                    <Transition> -->
                      <Button
                        v-else
                        icon="pi pi-save"
                        class="p-button-rounded p-button-primary p-button-save"
                        @click.stop="
                          claimMeeting(
                            meeting._id,
                            current_user.username,
                            'save'
                          )
                        "
                        v-tooltip.top="'Save changes'"
                      />
                    </Transition>
                  </div>
                  <div class="col">
                    <Button
                      v-if="
                        user_role < 5 &&
                        meeting.meetingLocked == 'unlocked' &&
                        !meeting.meetingStage.includes('Results')
                      "
                      icon="pi pi-refresh"
                      class="p-button-rounded p-button-primary p-button-refresh-log"
                      @click.stop="refreshMeetingTrigger(meeting._id)"
                      v-tooltip.top="'Refresh Meeting Manually'"
                      :disabled="meeting.meetingLocked === 'load'"
                    />
                  </div>
                  <Transition name="status-bar">
                    <div v-if="showStatusBar" class="status-bar">
                      Refresh triggered successfully!
                    </div>
                  </Transition>
                  <div class="col">
                    <Transition>
                      <Button
                        v-if="
                          meeting.meetingLocked &&
                          meeting.meetingLocked != 'unlocked' &&
                          meeting.meetingLocked != 'load'
                        "
                        icon="pi pi-times"
                        class="p-button-rounded p-button-primary p-button-cancel"
                        @click.stop="
                          claimMeeting(
                            meeting._id,
                            current_user.username,
                            'cancel'
                          )
                        "
                        v-tooltip.top="'Revert changes'"
                      />
                    </Transition>
                  </div>
                  <div class="col">
                    <Button
                      icon="pi pi-list"
                      class="p-button-rounded p-button-primary p-button-details"
                      @click.stop="
                        selectedMeeting = meeting;
                        showEditMeetingDialog = true;
                      "
                      v-tooltip.top="'Update meeting details'"
                      :disabled="meeting.meetingLocked === 'load'"
                    />
                  </div>
                  <div class="col">
                    <Button
                      icon="pi pi-file"
                      class="p-button-rounded p-button-primary mr-2"
                      @click.stop="
                        files_list = [];
                        fetchChangelog(meeting._id, meeting.meetingLoadHistory);
                        showMeetingHistoryDialog = true;
                      "
                      v-tooltip.top="'Meeting change history'"
                      :disabled="meeting.meetingLocked === 'load'"
                    />
                  </div>
                  <div class="col">
                    <Button
                      icon="pi pi-check"
                      :class="
                        meeting.validated
                          ? 'p-button-success p-button-rounded p-button-primary'
                          : 'p-button-success p-button-unvalidated p-button-rounded p-button-primary'
                      "
                      style="margin-left: 5px"
                      @click.stop="
                        validateMeeting(meeting);
                        meeting.validated = true;
                      "
                      v-tooltip.top="'Approve meeting'"
                      :disabled="meeting.meetingLocked === 'load'"
                    />
                  </div>
                  <div class="col">
                    <div
                      :class="{
                        'distribute-button-container': true,
                        'distributing-active': meeting.isDistributing,
                      }"
                    >
                      <Button
                        icon="pi pi-send"
                        class="p-button-rounded p-button-primary p-button-distribute"
                        @click.stop="distributeMeeting(meeting)"
                        v-tooltip.top="'Distribute Meeting'"
                        :disabled="
                          meeting.meetingLocked === 'load' ||
                          meeting.isDistributing
                        "
                      />
                      <span
                        v-if="meeting.isDistributing"
                        class="distributing-message"
                        >Distributing...</span
                      >
                    </div>
                  </div>
                  <div class="col">
                    <Dropdown
                      v-model="selectedValidationStatus"
                      :options="getValidationStatusOptions(meeting)"
                      optionLabel="label"
                      optionValue="value"
                      placeholder="XML Validation Status"
                      @change="handleValidationStatusChange(meeting)"
                      @before-show="onDropdownBeforeShow"
                      appendTo="body"
                    />
                  </div>
                  <!-- <div class="col" v-if="showProceedButton(meeting)">
                    <Button
                      label="Proceed with Distribution"
                      class="p-button-warning"
                      @click="proceedWithDistribution(meeting)"
                    />
                  </div> -->
                </div>
              </div>
              <div
                v-if="meeting.meetingStage.toLowerCase().includes('results')"
              >
                <button
                  v-if="user_role < 5"
                  class="pi pi-chevron-circle-right"
                  style="
                    color: green;
                    position: absolute;
                    margin: 5px 0 0 -25px;
                  "
                  @click.stop="
                    approveMeetingResults(
                      meeting._id,
                      meeting.processedMeetingData.meeting.track['@_name']
                    )
                  "
                ></button>
              </div>
            </v-row>
          </template>
          <div class="formgrid grid">
            <div
              class="v-col-1 d-flex justify-start"
              style="font-weight: 600"
            ></div>
            <div class="v-col-2 d-flex justify-start" style="font-weight: 600">
              <b>Track rating:</b>
              {{
                "&nbsp;" +
                meeting.processedMeetingData.meeting.track[
                  "@_expected_condition"
                ]
              }}
            </div>
            <div class="v-col d-flex justify-start" style="font-weight: 600">
              <b>Rail position:</b>
              {{
                "&nbsp;" + meeting.processedMeetingData.meeting.rail_position
              }}
            </div>
          </div>
          <MeetingDetails
            :meetingId="meeting._id"
            :meetingLocked="meeting.meetingLocked ?? 'unlocked'"
            :commonEntities="commonEntities"
            :current_user="current_user"
            :user_role="user_role"
          />
        </AccordionTab>
      </Accordion>
    </v-card>
    <v-alert
      prominent
      title="No Meetings available on this date"
      variant="contained-flat"
      rounded="pill"
      type="info"
      v-if="
        show_no_meetings &&
        (meetings_data == undefined || meetings_data.data.length == 0)
      "
    >
      Looks like there may be no meetings available on this date.
    </v-alert>
  </div>
  <ConfirmDialog></ConfirmDialog>
  <ConfirmDialog
    v-if="selectedValidationStatus === 'proceed'"
    message="Are you sure you want to proceed with distribution despite validation errors?"
    @confirm="proceedWithDistribution"
    @cancel="selectedValidationStatus = null"
  />
</template>

<script>
import { API, Auth } from "aws-amplify";
import moment from "moment";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Toast from "primevue/toast";
import InputText from "primevue/inputtext";
import Tooltip from "primevue/tooltip";
import { nextTick } from "vue";
import MeetingDetails from "./MeetingDetails.vue";
import CommonEntitiesLoader from "@/components/commonEntitiesLoader";

import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
export default {
  components: {
    CommonEntitiesLoader,
    MeetingDetails,
    Dropdown,
    InputText,
    Accordion,
    AccordionTab,
    Button,
    Dialog,
    Toast,
    ConfirmDialog,
  },
  directives: {
    tooltip: Tooltip,
  },
  data: () => ({
    meetings_data: null,
    currentMeetingId: null,
    xmlValidationStatus: {},
    selectedValidationStatus: null,
    meeting_errors: ["Horse not found", "Jockey not found"],
    tab_values: [
      { value: "N", label: "Non-Tab" },
      { value: "T", label: "Tab" },
      { value: "R", label: "Trial" },
    ],
    silks_values: [
      { value: false, label: "Not Ready" },
      { value: true, label: "Ready" },
    ],
    errors: [],
    commonEntities: null,
    customDatePicker: [],
    files_list: [],
    selectedDate: null,
    selectedMeeting: null,
    deletedMeeting: null,
    deleteOblit: false,
    transferredMeeting: null,
    showTransferMeetingDialog: false,
    showEditMeetingDialog: false,
    showNoMeetingsAlert: false,
    meetingHistory: null,
    showMeetingHistoryDialog: false,
    errorSummary: null,
    showErrorDialog: false,
    showDeleteMeetingDialog: false,
    loader: null,
    loading: false,
    date_menu: false,
    show_no_meetings: false,
    selectedStageFilter: null,
    originalMeetingsData: null,
    showStatusBar: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    stage_items: [
      "All Stages",
      "Nominations",
      "Weights",
      "Acceptance",
      "Final Fields",
      "Interim Results",
      "Results",
      "Rider Update",
      "Scratch Update",
    ],
    power_users: [
      "mediality",
      "pwillis@medialityracing.com.au",
      "mvarghese@medialityracing.com.au",
      "jsmallhorn@medialityracing.com.au",
      "lwilling@medialityracing.com.au",
      "dhappell@medialityracing.com.au",
      "kbromley@medialityracing.com.au",
      "mdeleon@medialityracing.com.au",
    ],
    stage_map: [
      { value: "Nominations", label: "Nominations" },
      { value: "Weights", label: "Weights" },
      { value: "Acceptances", label: "Acceptances" },
      { value: "FinalFields", label: "Final Fields" },
      { value: "InterimResults", label: "Interim Results" },
      { value: "Results", label: "Results" },
      { value: "Postponed", label: "Postponed" },
      { value: "Abandoned", label: "Abandoned" },
      { value: "DELETED", label: "DELETED" },
    ],
    condition_options: [
      { value: "M1", label: "M1" },
      { value: "M2", label: "M2" },
      { value: "G3", label: "G3" },
      { value: "G4", label: "G4" },
      { value: "O5", label: "O5" },
      { value: "O6", label: "O6" },
      { value: "O7", label: "O7" },
      { value: "H8", label: "H8" },
      { value: "H9", label: "H9" },
      { value: "H10", label: "H10" },
      { value: "H11", label: "H11" },
      { value: "Y0", label: "Y0" },
      { value: "F0", label: "F0" },
      { value: "G0", label: "G0" },
      { value: "D0", label: "D0" },
      { value: "S0", label: "S0" },
      { value: "H0", label: "H0" },
    ],
    showPassword: false,
    model: null,
    current_user: null,
    user_role: null,
    timer: null,
    transferMeetingDate: null,
    selectedTrack: null,
    trackOptions: [],
  }),
  setup() {
    const confirm = useConfirm();
    return { confirm };
  },
  async created() {
    //  this.meetings_data = await this.fetchMeetings("2022-02-12");
    //this.races = this.raceData.processedMeetingData.meeting.races.race;
    ///this.getMeetingDataExpansions();

    this.prepareDatePicker();
    let d = new Date();
    this.fetchMeetings(
      d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
    );
    this.current_user = await Auth.currentUserInfo();
    this.user_role =
      this.current_user &&
      this.current_user.attributes &&
      this.current_user.attributes["custom:permissions"]
        ? this.current_user.attributes["custom:permissions"]
        : 10;
    this.timer = setInterval(() => {
      this.scanForLocksAndErrors();
    }, 5000);
  },
  methods: {
    receiveCommonEntities(event) {
      this.commonEntities = event;
      if (
        this.commonEntities.allTracks &&
        this.commonEntities.allTracks.length > 0
      ) {
        this.trackOptions = this.commonEntities.allTracks.map((track) => ({
          value: track.value,
          label: track.label,
        }));
      }
    },
    async refreshMeetingTrigger(meetingId) {
      try {
        // Call the API endpoint to refresh the log for the given meetingId
        console.log(meetingId)
        this.showStatusBar = true;
        setTimeout(() => {
          this.showStatusBar = false;
        }, 60000); // 60000 ms = 1 minute
        let call = await API.post("MrCenApiGateway", `/generatestats`, {
          body: {
            params:{
              id: meetingId,
              user: this.current_user.username,
              body: {
                    id: meetingId,
                    checkstats: true,
                    // AUTOMATED DISTRIBUTION FLAG
                    distribute: false,
                    files: '',
                    user: 'PEGASUS',
                    compareType: 'update'
                }
            }
          },
        });
        console.log(call)
        this.$toast.add({
          severity: "success",
          summary: "Log refreshed successfully",
          life: 5000,
        });
      } catch (error) {
        console.log("Error refreshing log:", error);
      }
    },
    filterMeetings() {
      if (
        !this.selectedStageFilter ||
        this.selectedStageFilter === "All Stages"
      ) {
        this.meetings_data = JSON.parse(
          JSON.stringify(this.originalMeetingsData)
        );
      } else {
        this.meetings_data.data = this.originalMeetingsData.data.filter(
          (meeting) => {
            //as the staging values contains space and ends with s removing it before comparison
            const meetingStage = meeting.meetingStage
              .replace(/\s+/g, "")
              .toLowerCase()
              .replace(/s$/, "");
            const selectedStageFilter = this.selectedStageFilter
              .replace(/\s+/g, "")
              .toLowerCase()
              .replace(/s$/, "");
            return meetingStage === selectedStageFilter;
          }
        );
      }
    },
    async validateXML(meeting) {
      // Return an object with success status and errors (if any)
      console.log("Validating XML for meeting:", meeting);
    },
    async validateMeeting(meeting) {
      var val = await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: {
          level: "processed_meeting",
          meetingId: meeting._id,
          validated: true,
          user: this.current_user.username,
        },
      })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary:
              "The meeting " +
              meeting.processedMeetingData.meeting.track["@_name"] +
              " was successfully approved.",
            life: 5000,
          });
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to approve this meeting: " +
              error.toString()
          );
          this.$toast.add({
            severity: "error",
            summary:
              "There was a problem approving the meeting at " +
              meeting.processedMeetingData.meeting.track["@_name"],
            life: 5000,
          });
          return false;
        });
      console.log(val);
      // if (val){
      return true;
      // } else {
      //   return false
      // }
    },
    async distributeMeeting(meeting) {
      console.log("Distributing meeting:", meeting._id);
      meeting.isDistributing = true;

      try {
        const queryString = new URLSearchParams({
          id: meeting._id,
          xmlName: true,
          xmlValidation: true,
          meeting_stage: meeting.meetingStage,
        }).toString();

        console.log("Query string:", queryString);
        const response = await API.put(
          "MrCenApiGateway",
          `/distribute?${queryString}`,
          {}
        );

        console.log("API Response:", response);
        let result, fileNames, validateEndPoint, validation_bucket;

        if (typeof response === "string") {
          // If the response is a string, try to parse it as JSON
          try {
            const parsedResponse = JSON.parse(response);
            result = parsedResponse.message.result;
            fileNames = parsedResponse.message.fileNames;
            validateEndPoint = parsedResponse.VALIDATE_END_POINT;
            validation_bucket = parsedResponse.VALIDATION_BUCKET;
          } catch (parseError) {
            console.error("Error parsing response as JSON:", parseError);
            throw new Error("Unexpected response format");
          }
        } else if (response && response.message.result && response.message.fileNames) {
          // If the response is already an object with result and fileNames
          result = response.message.result;
          fileNames = response.message.fileNames;
          validateEndPoint = response.VALIDATE_END_POINT;
          validation_bucket = response.VALIDATION_BUCKET;
        } else {
          throw new Error("Unexpected response format");
        }

        console.log("Distribution result:", result);
        console.log("File Names:", fileNames);
        console.log("Validation Endpoint:", validateEndPoint);
        console.log("Validation bucket:", validation_bucket);
        const validationResults = {};

        for (const fileName of fileNames) {
          console.log(`Validating file ${fileName}...`);
          try {
            const validationResponse = await fetch(
              validateEndPoint,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  bucket_name: validation_bucket,
                  object_key: fileName,
                }),
              }
            );

            const validationResult = await validationResponse.json();
            console.log("Validation Result:", validationResult);
            validationResults[fileName] =
              validationResult || "Unknown validation result";
          } catch (error) {
            console.error(`Error validating file ${fileName}:`, error);
            validationResults[fileName] = "Failed to fetch";
          }
        }

        console.log("Validation Results:", validationResults);

        // Store the validation results
        this.xmlValidationStatus[meeting._id] = {
          results: validationResults,
          time: new Date().toLocaleString(),
        };

        // Proceed with distribution only if all validations passed
        const allValidationsPassed = Object.values(validationResults).every(
          (result) => result === "XML is valid against the XSD schema."
        );

        if (allValidationsPassed) {
          await this.finalizeDistribution(meeting);
        } else {
          this.$toast.add({
            severity: "warn",
            summary: "XML Validation Failed",
            detail: "Please check the XML Validation Status for details.",
            life: 5000,
          });
        }
      } catch (error) {
        console.log(error.message);
        if (error.message && error.message.includes("Request failed with status code 400")){
          this.$toast.add({
          severity: "error",
          summary: "Meeting not approved or Trial and not at Result stage",
          detail: error.toString(),
          life: 5000,
        });
        }
        else{
        console.error("Error in distribution process:", error);
        this.$toast.add({
          severity: "error",
          summary: "Distribution Error",
          detail: error.toString(),
          life: 5000,
        });
        } 
      } finally {
        meeting.isDistributing = false;
      }
    },

    getValidationStatusOptions(meeting) {
      const validationStatus = this.xmlValidationStatus[meeting._id];
      if (validationStatus) {
        const options = [
          {
            label: `Validation Time: ${validationStatus.time}`,
            value: "time",
          },
        ];

        for (const [fileName, result] of Object.entries(
          validationStatus.results
        )) {
          options.push({
            label: `${fileName}: ${result}`,
            value: fileName,
          });
        }

        const allValid = Object.values(validationStatus.results).every(
          (result) => result === "XML is valid against the XSD schema."
        );

        if (!allValid) {
          options.push({
            label: "Proceed with distribution",
            value: "proceed",
          });
        }

        return options;
      }
      return [{ label: "No validation data", value: null }];
    },
    async handleValidationStatusChange(meeting) {
      if (this.selectedValidationStatus === "proceed") {
        this.confirm.require({
          message:
            "Files will be distributed to clients regardless of persistent errors. Are you sure you want to proceed?",
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            this.proceedWithDistribution(meeting);
          },
          reject: () => {
            this.selectedValidationStatus = null; // Reset the dropdown selection
          },
        });
      }
    },
    onDropdownBeforeShow() {
      nextTick(() => {
        // Force reposition of the dropdown
        window.dispatchEvent(new Event("resize"));
      });
    },
    showProceedButton(meeting) {
      const validationStatus = this.xmlValidationStatus[meeting._id];
      if (!validationStatus) return false;

      return Object.values(validationStatus.results).some(
        (result) => result !== "XML is valid against the XSD schema."
      );
    },
    async proceedWithDistribution(meeting) {
      try {
        const response = await API.put(
          "MrCenApiGateway",
          `/distribute?id=${meeting._id}&xmlValidation=false&meeting_stage=${meeting.meetingStage}`
        );
        console.log("Distribution response:", response);
        if (response.message.message ==='Meeting Distributed') {
          this.$toast.add({
            severity: "success",
            summary: `The meeting at ${meeting.processedMeetingData.meeting.track["@_name"]} was successfully distributed.`,
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "warn",
            summary: response,
            life: 5000,
          });
        }
      } catch (error) {
        console.error("Error in final distribution step:", error);
        this.$toast.add({
          severity: "error",
          summary: `Distribution error for ${meeting.processedMeetingData.meeting.track["@_name"]}`,
          detail: error.toString(),
          life: 5000,
        });
      }
    },
    async finalizeDistribution(meeting) {
      try {
        const response = await API.put(
          "MrCenApiGateway",
          `/distribute?id=${meeting._id}&meeting_stage=${meeting.meetingStage}`
        );
        console.log("Distribution response:", response);

        let result;
        if (typeof response === "string") {
          result = JSON.parse(response);
        } else if (typeof response === "object") {
          result = response;
        } else {
          throw new Error("Unexpected response format");
        }

        if (
          result.message.message === "Meeting Distributed" &&
          Array.isArray(result.message.uploadedFiles)
        ) {
          const fileNames = [
            ...new Set(result.message.uploadedFiles.map((file) => file.filename)),
          ].join(", ");
          this.$toast.add({
            severity: "success",
            summary: `The meeting at ${meeting.processedMeetingData.meeting.track["@_name"]} was successfully distributed.`,
            detail: `Files distributed: ${fileNames}`,
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "warn",
            summary: "Distribution Warning",
            detail: result.message || "Unknown distribution result",
            life: 5000,
          });
        }

        // Update the component's state with the uploaded files information
        this.lastDistributedFiles = result.uploadedFiles || [];
      } catch (error) {
        console.error("Error in final distribution step:", error);
        this.$toast.add({
          severity: "error",
          summary: `Distribution error for ${meeting.processedMeetingData.meeting.track["@_name"]}`,
          detail: error.toString(),
          life: 5000,
        });
      }
    },
    async distributeGears(meeting) {
      return await API.put(
        "MrCenApiGateway",
        `/distribute?id=${meeting._id}&files=GEAR`
      )
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary:
              "The gear for meeting at " +
              meeting.processedMeetingData.meeting.track["@_name"] +
              " was successfully distributed.",
            life: 5000,
          });
        })
        .catch((error) => {
          console.log(
            "An error occurred when trying to distribute this gear: " +
              error.toString()
          );
          this.$toast.add({
            severity: "warn",
            summary:
              "The gear for meeting at " +
              meeting.processedMeetingData.meeting.track["@_name"] +
              " may have distributed (504 usually ok). Error: " +
              error.toString(),
            life: 50000,
          });
        });
    },
    async generateComment(meeting) {
      if (this.selectedMeeting.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      return await API.put(
        "MrCenApiGateway",
        `/comment?meeting_id=${meeting._id}`
      )
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary:
              "Comment was generated for " +
              meeting.processedMeetingData.meeting.track["@_name"],
            life: 5000,
          });
        })
        .catch((error) => {
          console.log(
            "An error occurred when trying to comment this meeting: " +
              error.toString()
          );
          this.$toast.add({
            severity: "error",
            summary:
              "There was a problem generating comment for meeting at " +
              meeting.processedMeetingData.meeting.track["@_name"],
            life: 5000,
          });
        });
    },
    getMeetingsSectionTitle() {
      if (
        this.customDatePicker !== undefined &&
        this.customDatePicker.dates !== null
      )
        return (
          "Meetings on " +
          (this.selectedDate == null
            ? this.customDatePicker.dates[this.customDatePicker.selectedId].date
            : moment(this.selectedDate, "YYYY-MM-DD").format("ddd DD MMM"))
        );
      else return "Please select a date";
    },
    async fetchMeetings(meeting_date) {
      this.show_no_meetings = false;
      this.meetings_data = null;
      this.selectedDate = meeting_date;
      console.log("Fetching meetings for date " + meeting_date);
      return API.get(
        "MrCenApiGateway",
        `/processedmeetings?date=${meeting_date}`
      )
        .then((response) => {
          this.showNoMeetingsAlert = response.data.length === 0;
          if (!this.showNoMeetingsAlert) {
            this.meetings_data = response;
            this.originalMeetingsData = JSON.parse(
              JSON.stringify(this.meetings_data)
            );
          } else {
            this.show_no_meetings = true;
            this.meetings_data = null;
          }
          console.log(response);
          return response;
        })
        .catch((error) => {
          this.errors.push(error);
          this.errored = true;
          this.alert = true;
        });
    },
    async fetchDefaultTracks() {
      const defaultTrack = this.trackOptions.find(
        (track) =>
          track.label ===
          this.selectedMeeting.processedMeetingData.meeting.track["@_name"]
      );
      if (defaultTrack) {
        this.selectedTrack = defaultTrack.value;
      }
    },

    async fetchChangelog(meeting_id, meeting_history) {
      console.log("fetching changes");
      // console.log(this.current_user.username)
      return await API.get("MrCenApiGateway", `/changelog?id=${meeting_id}`)
        .then((response) => {
          if (response.data && response.data.changelog) {
            response = response.data.changelog;
          } else {
            response = meeting_history;
          }
          console.log(response);
          this.meetingHistory = response;
          return "changelog fetched";
        })
        .catch((error) => {
          this.errors.push(error);
          this.errored = true;
          this.alert = true;
        });
    },
    async updateMeeting() {
      if (this.selectedMeeting.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: {
          level: "processed_meeting",
          meetingId: this.selectedMeeting._id,
          stage: this.selectedMeeting.meetingStage,
          tab_indicator:
            this.selectedMeeting.processedMeetingData.meeting.tab_indicator,
          track: this.selectedMeeting.processedMeetingData.meeting.track,
          rail_position:
            this.selectedMeeting.processedMeetingData.meeting.rail_position,
          silksReady:
            this.selectedMeeting.processedMeetingData.meeting.silksReady ??
            false,
          user: this.current_user.username,
        },
      })
        .then((response) => {
          console.log(response);
          if (
            response &&
            response.data &&
            response.data.toString().includes("aborting")
          ) {
            this.$toast.add({
              severity: "error",
              summary: `ERROR: ${response.data}`,
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "success",
              summary:
                "The meeting " +
                this.selectedMeeting.processedMeetingData.meeting.track[
                  "@_name"
                ] +
                " was successfully updated.",
              life: 5000,
            });
          }
          //reloads after update
          this.fetchMeetings(this.selectedDate);
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to update this meeting: " +
              error.toString()
          );
        });

      this.showEditMeetingDialog = false;
    },
    async deleteMeeting(
      id,
      track,
      params = {
        processedMeetingData: true,
        inputMeetingData: false,
      }
    ) {
      var paramsObj = {
        level: "processed_meeting",
        processedMeetingData: params.processedMeetingData,
        inputMeetingData: params.inputMeetingData,
        fullDelete: this.deleteOblit ? true : false,
        user: this.current_user.username,
        meetingId: id,
      };
      console.log(paramsObj);
      await API.del("MrCenApiGateway", `/processedmeetings`, {
        body: paramsObj,
      })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "The meeting " + track + " was successfully deleted.",
            life: 5000,
          });
          //reloads after update
          this.fetchMeetings(this.selectedDate);
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to delete this meeting: " +
              error.toString()
          );
        });
      this.deleteOblit = false;
      this.showDeleteMeetingDialog = false;
    },
    async approveMeetingResults(id, track) {
      var paramsObj = {
        level: "approve_results",
        user: this.current_user.username,
        meetingId: id,
      };
      console.log(paramsObj);
      await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: paramsObj,
      })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "The meeting at " + track + " was successfully approved.",
            life: 5000,
          });
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to delete this meeting: " +
              error.toString()
          );
        });
    },
    async reloadMeeting(payload) {
      await API.post("MrCenApiGateway", `/processedmeetings`, {
        body: {
          level: "processed_meeting",
          file_path: payload.file_path,
          bucket: payload.bucket,
        },
      })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `The meeting reload was successfully trigerred for ${payload.file_path}.`,
            life: 5000,
          });
          //reloads after update
          this.fetchMeetings(this.selectedDate);
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to reload this meeting: " +
              error.toString()
          );
        });
      this.showDeleteMeetingDialog = false;
    },
    async clearMeetingErrors(id, track) {
      if (this.selectedMeeting.meetingLocked != this.current_user.username) {
        alert(
          "Meeting cannot be edited without locking the meeting by pressing the 'Edit Meeting' button"
        );
        return "";
      }
      await API.put("MrCenApiGateway", `/processedmeetings`, {
        body: {
          level: "processed_meeting",
          meetingId: id,
          errors: [],
          clear_errors: true,
          user: this.current_user.username,
        },
      })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "The meeting " + track + " was successfully updated.",
            life: 5000,
          });
          //reloads after update
          this.fetchMeetings(this.selectedDate);
        })
        .catch((error) => {
          alert(
            "An error occurred when trying to approve this meeting: " +
              error.toString()
          );
        });

      this.showEditMeetingDialog = false;
    },
    async scanForLocksAndErrors() {
      var date = this.selectedDate;
      return await API.get("MrCenApiGateway", `/locks?date=${date}`)
        .then((response) => {
          // console.log(response);
          if (response.data) {
            // console.log(response.data)
            for (var meet of response.data) {
              for (var exmeet of this.meetings_data.data) {
                if (meet._id === exmeet._id) {
                  if (meet.meetingLocked) {
                    exmeet.meetingLocked = meet.meetingLocked;
                  } else {
                    exmeet.meetingLocked = "unlocked";
                  }
                  if (meet.meetingErrorCount) {
                    exmeet.meetingErrorCount = meet.meetingErrorCount;
                  } else {
                    exmeet.meetingErrorCount = 0;
                  }
                  if (meet.processedMeetingData.meeting.errors)
                    exmeet.processedMeetingData.meeting.errors =
                      meet.processedMeetingData.meeting.errors;
                  if (meet.validated) {
                    exmeet.validated = meet.validated;
                  } else {
                    exmeet.validated = false;
                  }
                }
              }
            }
          }
          return "changelog fetched";
        })
        .catch((error) => {
          this.errors.push(error);
          this.errored = true;
          this.alert = true;
        });
    },
    async claimMeeting(meetingId, user, stage) {
      if (["save", "cancel"].includes(stage)) {
        this.$confirm.require({
          message: `Are you sure you want to ${
            stage == "cancel" ? "discard" : "save"
          } your changes?`,
          header: `${stage == "cancel" ? "discard" : "save"}`,
          icon: "pi pi-info-circle",
          acceptClass:
            stage == "cancel" ? "p-button-danger" : "p-button-primary",
          accept: () => {
            return this.claimApi(meetingId, user, stage);
          },
          reject: () => {
            return "";
          },
        });
      } else {
        return await this.claimApi(meetingId, user, stage);
      }
    },
    async claimApi(meetingId, user, stage) {
      this.currentMeetingId = meetingId;
      for (var exmeet of this.meetings_data.data) {
        if (meetingId === exmeet._id && stage == "claim") {
          exmeet.meetingLocked = user;
        }
        if (
          meetingId === exmeet._id &&
          (stage == "save" || stage == "cancel")
        ) {
          exmeet.meetingLocked = "unlocked";
        }
      }
      return await API.get(
        "MrCenApiGateway",
        `/claim?id=${meetingId}&user=${user}&${stage}=true`
      )
        .then((response) => {
          console.log(response);
          if (response.data) {
            if (response.data.includes(user)) {
              this.$toast.add({
                severity: "success",
                summary: response.data,
                life: 5000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: response.data,
                life: 5000,
              });
            }
          }
          return response;
        })
        .catch((error) => {
          this.errors.push(error);
          this.errored = true;
          this.alert = true;
        });
    },
    getClassifications(class_letter) {
      const map = new Map([
        ["M", "Metro"],
        ["C", "Country"],
        ["P", "Provincial"],
      ]);
      let result = map.get(class_letter);
      if (result == undefined) result = "Class NA";
      return result;
    },
    getMeetingStage(stage_code) {
      let result = this.stage_map.filter((s) => {
        return s.value == stage_code;
      })[0];
      if (result == undefined) result = "Stage NA";
      else result = result.label;
      return result;
    },
    getTabIndicator(tab_letter) {
      const map = new Map([
        ["T", "Tab"],
        ["Trial", "Trial"],
      ]);
      let result = map.get(tab_letter);
      if (result == undefined) result = "Non-Tab";
      return result;
    },
    getFormattedDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    checkDates(date1, date2) {
      if (moment(date1).isSameOrAfter(moment(date2))) {
        return moment(date1).format("DD/MM/YYYY HH:mm:ss");
      } else {
        return moment(date2).format("DD/MM/YYYY HH:mm:ss");
      }
    },
    prepareDatePicker() {
      console.log("Prepare DatePicker");
      this.customDatePicker = { dates: [], selectedId: null };
      let startDate = moment().subtract(2, "days").format();
      let endDate = moment().add(8, "days").format();
      let today = moment().format();
      let id = 0;
      for (var m = moment(startDate); m.isBefore(endDate); m.add(1, "days")) {
        if (m.format() === today) {
          this.customDatePicker.dates.push({
            id: id,
            date: m.format("ddd DD MMM"),
            sortableDate: m.format("YYYY-MM-DD"),
            today: true,
            color: "warning",
          });
          this.customDatePicker.selectedId = id;
        } else {
          this.customDatePicker.dates.push({
            id: id,
            date: m.format("ddd DD MMM"),
            sortableDate: m.format("YYYY-MM-DD"),
            today: false,
            color: "info",
          });
        }
        id++;
      }
      console.log(this.customDatePicker);
    },
    checkIfFileName(name) {
      console.log(name);
      // console.log(this.files_list)
      // if (this.files_list.includes(name)){
      //   console.log('includes!')
      //   return false
      // } else {
      //   this.files_list.push(name)
      //   console.log(this.files_list)
      //   return true
      // }
      return true;
    },
    async transferMeeting() {
      const transferringMeeting = this.meetings_data.data.find(
        (meeting) => meeting._id === this.transferredMeeting._id
      );
      if (transferringMeeting.meetingStage === "Results") {
        this.$toast.add({
          severity: "error",
          summary: `Meetings at the Results stage cannot be transferred.!!`,
          life: 5000,
        });
        return;
      }
      if (
        new Date(this.transferMeetingDate).toISOString().split("T")[0] ===
          new Date(transferringMeeting.meetingDate)
            .toISOString()
            .split("T")[0] &&
        this.selectedTrack ==
          transferringMeeting.processedMeetingData.meeting.track["@_id"]
      ) {
        this.$toast.add({
          severity: "error",
          summary: `The transfer date or track must be different from the current meeting date and track.!!`,
          life: 5000,
        });
        return;
      }
      await API.post("MrCenApiGateway", `/transfer-meeting`, {
        body: {
          meeting_id: transferringMeeting._id,
          selected_track: this.selectedTrack,
          transfer_meeting_date: this.transferMeetingDate,
        },
      })
        .then((response) => {
          console.log(response);
          this.showTransferMeetingDialog = false;
          this.$toast.add({
            severity: "success",
            summary: `The meeting reload was successfully transferred to ${this.transferMeetingDate}.`,
            life: 5000,
          });
          this.fetchMeetings(this.selectedDate);
        })
        .catch((error) => {
          console.log(error);
          alert(
            "An error occurred when trying to reload this meeting: " +
              error.toString()
          );
        });
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  computed: {
    //this.meetings_data
    computedDateFormatted() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
    tomorrowDate() {
      console.log(this.transferredMeeting._id);
      const transferringMeeting = this.meetings_data.data.find(
        (meeting) => meeting._id === this.transferredMeeting._id
      );
      console.log(transferringMeeting);
      const tomorrow = new Date(transferringMeeting.meetingDate);
      tomorrow.setDate(tomorrow.getDate());
      return tomorrow.toISOString().split("T")[0];
    },
  },
  beforeUnmount: () => {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
.distribute-button-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.distributing-message {
  position: absolute;
  top: -20px; /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50; /* Success color */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.button-distributing {
  margin-top: 20px;
}
.distributing-active .distributing-message {
  opacity: 1;
}
.p-button-warning {
  background-color: #ff9800;
  color: #ffffff;
  border: 1px solid #ff9800;
}

.p-button-warning:hover {
  background-color: #f57c00;
  border-color: #f57c00;
}
::v-deep .p-dropdown-item {
  cursor: pointer;
  padding: 0.5rem 1rem;
}

::v-deep .p-dropdown-item:hover {
  background-color: #f0f0f0;
}

::v-deep .p-dropdown-item[aria-label="Proceed with distribution"] {
  color: #ff9800;
  font-weight: bold;
}

::v-deep .p-dropdown-item[aria-label="Proceed with distribution"]:hover {
  background-color: #fff3e0;
}
::v-deep .p-dropdown {
  width: 100%;
}

::v-deep .p-dropdown-panel {
  max-width: none !important;
}

::v-deep .p-dropdown-items-wrapper {
  max-height: 200px; /* Adjust this value as needed */
}
.p-button-refresh-log {
  color: hsl(155, 95%, 32%);
  background: transparent;
  border: 1px solid hsl(240, 94%, 26%);
  top: -6px;
}
.p-button-refresh-log:enabled:hover {
  color: #03623f;
  background: hsl(240, 3%, 70%);
  border: 1px solid hsl(0, 0%, 100%);
}
.mr-2 {
  color: #2196f3;
  background: transparent;
  border: 1px solid #2196f3;
  top: -6px;
}
.p-button-locked {
  opacity: 0.5;
  cursor: not-allowed;
}
.p-button-details {
  color: rgb(55, 55, 55);
  background: transparent;
  border: 1px solid rgb(55, 55, 55);
  top: -6px;
}
.p-button-details:enabled:hover {
  color: #fff;
  background: rgb(55, 55, 55);
  border: 1px solid rgb(55, 55, 55);
}
.p-button-distribute {
  color: rgb(53, 176, 0);
  background: transparent;
  border: 1px solid rgb(53, 176, 0);
  top: -6px;
}
.status-bar {
  position: fixed;
  top: 80px;
  right: 20px;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 16px;
  border-radius: 4px;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.status-bar-enter-active,
.status-bar-leave-active {
  transition: opacity 0.5s;
}

.status-bar-enter-from,
.status-bar-leave-to {
  opacity: 0;
}
.p-button-distribute:enabled:hover {
  color: #fff;
  background: rgb(32, 105, 0);
  border: 1px solid rgb(32, 105, 0);
}
.p-button-save {
  color: #0f3c26;
  background: transparent;
  border: 1px solid #0f3c26;
  top: -6px;
}
.p-button-save:enabled:hover {
  color: #fff;
  background: #0f3c26;
  border: 1px solid #0f3c26;
}
.p-button-cancel {
  color: #ec2210;
  background: transparent;
  border: 1px solid #ec2210;
  top: -6px;
}
.p-button-cancel:enabled:hover {
  color: #fff;
  background: #ec2210;
  border: 1px solid #ec2210;
}
.p-button-unvalidated {
  background: #fff !important;
  color: #992211 !important;
  border: #992211 !important;
  top: -6px;
}
.file-history-div {
  height: 65px;
  border-bottom: 1px solid rgba(75, 75, 75, 0.5);
}
.file-history-row {
  padding: 13px 0;
}
.file-history-file-name {
  overflow: hidden;
}
.p-locked-meet {
  background: rgba(255, 255, 255, 0.6);
  width: calc(85% - 40px);
  position: absolute;
  height: 48px;
  right: 40px;
  color: #ff6666;
  font-size: 20px;
  line-height: 48px;
  padding-left: 20%;
  border-radius: 6px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
