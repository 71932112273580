<template>
  <Toast/>
  <CommonEntitiesLoader @entities="receiveCommonEntities"/>

    <div class="text-right pt-2">
        <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="createNewEntity()">
      Create New
    </v-btn>
  </div>

    <DataTable :value="searchResults" dataKey="_id" responsiveLayout="scroll" :paginator="true" :stripedRows="true"
               :rowHover="true" :rows="10"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[10,25,50,100]"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records">
      <template #empty>
        No results found.
      </template>
      <template #loading>
        Searching...
      </template>
      <Column v-for="col of setHeadersForEntity()" :field="col.field" :header="col.title" :key="col.field"
              sortable></Column>
      <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
                  @click="editEntity(slotProps.data, this.$route.query.entity)"></Button>
          <Button type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text"
                  @click="deleteEntity(slotProps.data, this.$route.query.entity)"></Button>
        </template>
      </Column>
    </DataTable>

  <EditFormClient :visible="showEditFormClient" :initialItem="editedItem"
                 @visible="onVisibleChanged"/>
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
//import {TabulatorFull as Tabulator} from "tabulator-tables"; //import Tabulator library
import "../../../node_modules/tabulator-tables/dist/css/tabulator_materialize.min.css";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Toast from "primevue/toast";
// import moment from "moment";

// eslint-disable-next-line
import Column from "primevue/column";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from 'primevue/confirmdialog';

import EditFormClient from "@/components/EditFormClient";
import CommonEntitiesLoader from "@/components/commonEntitiesLoader";
import {API} from "aws-amplify";

export default {
  headersData: [],
  components: {
    EditFormClient,
    DataTable,
    Column,
    Button,
    Toast,
    CommonEntitiesLoader,
    ConfirmDialog
  },
 setup() {
         // eslint-disable-next-line 
        const confirm = useConfirm();
  },
  data: () => ({
    showEditFormClient: false,
    commonEntities: null,
    loading: false,  
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: [],
    defaultItem: [],
    emptyClientItem:  {
        "_id": null,
        "account_holder": null,
        "email": null,
        "company": null,
        "status": false,
        "ftp_username": null,
        "ftp_password": null,
        "ftp_address": null,
        "api_key": null,
        "api_key_id": null,
        "region_perms": [],
        "fields_access": [],
        "classifications": [],
        "__v": 0
    }
  }),
  mounted() {
    console.log("Search Results:");
    console.log(this.searchResults);
  },
  props: {
    searchResults: null,
  },
 emits: ['reloadResults'],
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    searchResults(val) {
      console.log("Search Results updated2:");
      console.log(val);
    }
  },

  methods: {
    receiveCommonEntities(event) {
      this.commonEntities = event;
    },

   onVisibleChanged(event){
      this.showEditFormClient = event;   
      if(event == false ) {
        this.$emit('reloadResults', true); 
        }
   },
    async getEntityDetails(entity, entityId) {
      if (entityId !== undefined || entityId !== 0)
        return API.get("MrCenApiGateway", `/admin/${entity.toLowerCase()}?id=${entityId}`);
    },

    async createNewEntity(){
       this.editEntity(null);
    },

    async editEntity(data) {
      console.log(data);
      try {
        let entity = this.getEntityName();
        switch (entity.toLowerCase()) {
          case "client":
            if(data != null){
                  await this.getEntityDetails(entity, data._id).then((entityDetails) => {
                    this.editedItem = entityDetails;
                    this.showEditFormClient = true;
                  });
            }else{
                 this.editedItem = Object.assign({}, this.emptyClientItem);
                 this.showEditFormClient = true;
            }

            break;
          default:
            this.$toast.add({
              severity: 'error',
              summary: 'Cannot Edit Entity',
              detail: 'This entity cannot be edited at this time.',
              life: 6000
            });
            break;
        }
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Cannot Edit Entity',
          detail: 'This entity cannot be edited at this time. Details:' + err.toString(),
          life: 6000
        });

      }

    },

    async deleteEntity(data, entity) {
      console.log(this.getEntityName(entity));
      console.log(data);

       this.$confirm.require({
                message: 'Do you want to delete this record?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                  //Delete the record
                      API.del(
                      "MrCenApiGateway",
                      `/admin/client`,
                      {
                        body: { "email": data.email }
                      });

                    this.$toast.add({severity:'info', summary:'Confirmed', detail:'Record deleted', life: 3000});
                    setTimeout(() =>{
                        this.$emit('reloadResults', true);
                    }, 1000)
                },
                reject: () => {
                    this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
    },
    getAllCountries() {
      return this.allCountries;
    },
    getEntityName() {
      let entityName = this.$route.query.entity.substring(0, this.$route.query.entity.length);

      return this.capitalize(entityName);
    },
    getSchema() {
      switch (this.$route.query.entity) {
        case "horses": {
          let countryCombo = this.horses_schema[2].children.filter((f) => {
            return f.name == "HOR_COUNTRY_OF_ORIGIN";
          })[0];
          if (countryCombo !== undefined)
            countryCombo.options = this.allCountries;
          let trainervSelect = this.horses_schema[6].children.filter((f) => {
            return f.name == "vselectTrainer";
          })[0];
          if (trainervSelect !== undefined)
            trainervSelect.options = this.allTrainers;

          return this.horses_schema;
        }
      }
      return;
    },

    capitalize(str) {
      let cap = str.charAt(0).toUpperCase() + str.slice(1);
      return cap;
    },

    toggleDialog() {
      this.dialogTest = true;
    },

    setHeadersForEntity() {

      switch (this.$route.query.entity) {
        case "client": {
          console.log("test");
          return [
            {
              title: "Account Holder",
              headerSort: true,
              field: "account_holder",
            },
            {title: "Email", field: "email"},
            {title: "Company", field: "company"},
            {title: "Status", field: "status"},
            {title: "FTP Username", field: "ftp_username"},
            {title: "FTP Address", field: "ftp_address"},
            {title: "Api Key Id", field: "api_key_id"},
            {title: "Api Key", field: "api_key"},
            ];
        }

        default: {
          return [];
        }
      }
    },

    editItem(item) {
      console.log("Editing item:");
      console.log(item);
      this.editedIndex = this.searchResults.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.searchResults.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // eslint-disable-next-line
      this.searchResults.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.searchResults[this.editedIndex], this.editedItem);
      } else {
        // eslint-disable-next-line
        this.searchResults.push(this.editedItem);
      }
      this.close();
    },
  }
};
</script>

<style>
.formulate-input {
  min-width: 15em;
}

.flex-wrapper {
  max-width: 20em;
  display: flex;
}

.flex-wrapper .formulate-input:first-child {
  margin-right: 0.5em;
}

.flex-wrapper .formulate-input:last-child {
  margin-left: 0.5em;
}

.formulate-input-full-width .formulate-input-element {
  min-width: 30em !important;
  max-width: 100% !important;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 20px;
}

.formulate-input-dropdown {
  background-color: #fff;
  margin: -3px 0 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid #cecece;
  border-top-color: #41b883;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.formulate-input-dropdown li {
  padding: 0.5em 1em;
  margin: 0;
}

.formulate-input-dropdown li:hover,
.formulate-input-dropdown li[data-is-selected] {
  background-color: rgba(0, 0, 0, 0.05);
}

.page .theme-default-content .formulate-input {
  position: relative;
  z-index: 2;
}
</style>
