// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import {
  createVuetify
} from 'vuetify'


const myCustomLightTheme = {
  dark: false,
  colors: {
    info: '#0a1726',
    primary: '#35b000'
  }
}

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    }
  }
});
