<template>
  <Dialog
    v-model="editedItem"
    v-model:visible="isVisible"
    :style="{ width: '800px' }"
    header="Track Details"
    :modal="true"
    :closeOnEscape="true"
    @hide="closeForm()"
    class="p-fluid"
  >
    <div class="formgrid grid">
      <div class="field col">
        <label for="trkid">Track ID</label>
        <InputText
          id="trkid"
          v-model="editedItem.TRK_TRACK_DB_ID"
          required="true"
          :class="{
            'invalid-field': !isTrackIDValid(editedItem.TRK_TRACK_DB_ID),
          }"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="trkname">Track Name</label>
        <InputText
          id="trkname"
          v-model="editedItem.TRK_TRACK_NAME"
          required="true"
          :class="{
            'invalid-field': !isTrackNameValid(editedItem.TRK_TRACK_NAME),
          }"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="trk3abbrev">3char Abbrevation</label>
        <InputText
          id="trk3abbrev"
          v-model="editedItem.TRK_TRACK_3CHAR_ABBREV"
          required="true"
          :class="{
            'invalid-field': !is3CharValid(editedItem.TRK_TRACK_3CHAR_ABBREV),
          }"
        />
      </div>
      <!-- <div class="field col"></div>
    </div>
    <div class="formgrid grid"> -->
      <div class="field col">
        <label for="trk6abbrev">6char Abbrevation</label>
        <InputText
          id="trk6abbrev"
          v-model="editedItem.TRK_TRACK_6CHAR_ABBREV"
          required="true"
          :class="{
            'invalid-field': !is6CharValid(editedItem.TRK_TRACK_6CHAR_ABBREV),
          }"
        />
      </div>
      <!-- <div class="field col"></div> -->
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="trkraabbrev">RA Abbrevation</label>
        <InputText
          id="trkraabbrev"
          v-model="editedItem.TRK_RSB_TRACK_ABBREV"
          required="true"
        />
      </div>
      <div class="field col">
        <label for="trk6abbrev">Matching Name</label>
        <InputText
          id="trk6abbrev"
          v-model="editedItem.TRK_ALT_NAME"
          required="true"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="trksurface">Surface</label>
        <Dropdown
          id="trksurface"
          v-model="editedItem.TRK_TRACK_SURFACE_TYPE"
          :options="surface_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="trkstraight">Length straight</label>
        <InputText
          id="trkstraight"
          v-model="editedItem.TRK_LENGTH_OF_STRAIGHT"
          required="true"
        />
      </div>
      <div class="field col"></div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="trkcircuit">Length circuit</label>
        <InputText
          id="trkcircuit"
          v-model="editedItem.TRK_LENGTH_OF_CIRCUIT"
          required="true"
        />
      </div>
      <div class="field col"></div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="trkdirection">Direction</label>
        <Dropdown
          id="trkdirection"
          v-model="editedItem.TRK_DIRECTION_OF_RUNNING"
          :options="direction_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="trkclass">Classification</label>
        <Dropdown
          id="trkclass"
          v-model="editedItem.TRK_LOCATION"
          :options="location_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="state">State</label>
        <Dropdown
          id="state"
          v-model="editedItem.TRK_STATE_OF_TRACK"
          :options="state_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="country">Country</label>
        <AutoComplete
          v-model="editedItem.TRK_COUNTRY_OF_TRACK"
          id="country"
          :suggestions="this.filteredCountries"
          @complete="searchCountry($event)"
          :dropdown="true"
          field="label"
          autoHighlight
          forceSelection
        >
        </AutoComplete>
      </div>
    </div>
    <!-- <label for="apprentice">Apprentice Claim</label>
     <div>
       <label>Yes</label>
       <input type="checkbox"  value="Y" v-mode="Apprentice_choice"/>

       <label>No</label>
       <input type="checkbox"  value="N"  v-model="Apprentice_choice"/>
     </div>
   </div>
 </div>
<div class="formgrid grid">
   <div class="field col">
     <label for="amateur">Amateur</label>
     <div>
       <label>Yes</label>
       <input type="checkbox" value="Y" v-model="Amateur_Choice"/>
       <label>No</label>
       <input type="checkbox" value="N"  v-model="Amateur_Choice"/>
     </div>
   </div>
 </div>
 <div class="formgrid grid">
   <div class="field col">
     <label for="active">Active</label>
     <div>
       <label>Yes</label>
       <input type="checkbox" value="Y" v-model="Active_Choice"/>
       <label>No</label>
       <input type="checkbox" value="N" v-model="Active_Choice"/>
     </div>
   </div>
 </div>-->
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeForm()"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        :disabled="!isFormValid || user_role > 4"
        @click="saveForm()"
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import { API } from "aws-amplify";
import AutoComplete from "primevue/autocomplete";

export default {
  components: {
    Dialog,
    Button,
    InputText,
    Dropdown,
    AutoComplete,
  },
  data: () => ({
    editedItem: {},
    isVisible: null,
    filteredCountries: null,
    state_options: [
      { label: "", value: 0 },
      { label: "ACT", value: 1 },
      { label: "NSW", value: 2 },
      { label: "VIC", value: 3 },
      { label: "QLD", value: 4 },
      { label: "SA", value: 5 },
      { label: "WA", value: 6 },
      { label: "TAS", value: 7 },
      { label: "NT", value: 8 },
      { label: "NZ", value: 9 },
      { label: "HK", value: 10 },
      { label: "SGP", value: 11 },
      { label: "AUS", value: 99 },
    ],
    surface_options: [
      { value: "G", label: "Grass" },
      { value: "Y", label: "Synthetic" },
      { value: "D", label: "Dirt" },
      { value: "S", label: "Sand" },
    ],
    direction_options: [
      { value: "A", label: "Anti-clockwise" },
      { value: "C", label: "Clockwise" },
    ],
    location_options: [
      { value: "M", label: "Metro" },
      { value: "C", label: "Country" },
      { value: "P", label: "Provincial" },
      { value: "N/A", label: "N/A" },
    ],
  }),
  props: {
    initialItem: {},
    visible: null,
    commonEntities: null,
    current_user: null,
    user_role: null
  },
  methods: {
    async saveForm() {
      this.trimFields()
      console.log(this.editedItem);
      if (typeof this.editedItem.TRK_COUNTRY_OF_TRACK === "object") {
        this.editedItem.TRK_COUNTRY_OF_TRACK = this.convertObject(
          this.editedItem.TRK_COUNTRY_OF_TRACK,
          "value"
        );
      }

      console.log(this.editedItem);
      if (this.editedItem._id === "") {
        await API.post("MrCenApiGateway", `/track`, {
          body: this.editedItem,
        }).then(() => {
          this.$toast.add({
            severity: "success",
            summary: `the track ${this.editedItem.TRK_TRACK_NAME} was created`,
            life: 5000,
          });
        });
      } else {
        await API.put("MrCenApiGateway", `/track`, {
          body: this.editedItem,
        }).then(() => {
          this.$toast.add({
            severity: "success",
            summary: `the track ${this.editedItem.TRK_TRACK_NAME} was updated`,
            life: 5000,
          });
        });
      }
      this.closeForm();
    },
    closeForm() {
      this.isVisible = false;
      this.$emit("visible", false);
    },
    searchCountry(event) {
      this.filteredCountries = this.commonEntities.allCountries.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    convertObject(object, key) {
      return object[key];
    },
    isTrackNameValid(trackName) {
      return trackName && trackName.trim().length > 0;
    },
    is3CharValid(threeCharAbb) {
      return !threeCharAbb || threeCharAbb.trim().length === 3;
    },
    is6CharValid(sixCharAbb) {
      return !sixCharAbb || sixCharAbb.trim().length === 6;
    },
    isTrackIDValid(trackID) {
      const num = Number(trackID);
      return trackID && !isNaN(num) && num > 0;
    },
    setCountryToAustralia() {
      this.editedItem.TRK_COUNTRY_OF_TRACK = {
        label: "Australia",
        value: "AUS",
      }; // Adjust this based on how countries are structured in your data
    },
    trimFields(){
      for (const key in this.editedItem) if (typeof this.editedItem[key] === 'string' || this.editedItem[key] instanceof String) this.editedItem[key] = this.editedItem[key].trim()
    }
  },
  watch: {
    initialItem(val) {
      this.editedItem = val;
      if (!this.editedItem._id) {
        this.editedItem = {
          TRK_COUNTRY_OF_TRACK: "",
          TRK_DIRECTION_OF_RUNNING: "",
          TRK_FILLER_1: "",
          TRK_IN_BETWEEN_MARGINS: "",
          TRK_LENGTH_OF_CIRCUIT: 0,
          TRK_LENGTH_OF_STRAIGHT: 0,
          TRK_LOCATION: "",
          TRK_ROW_STATUS: "A",
          TRK_RSB_TRACK_ABBREV: "",
          TRK_STATE_OF_TRACK: 0,
          TRK_TRACK_3CHAR_ABBREV: "",
          TRK_TRACK_6CHAR_ABBREV: "",
          TRK_TRACK_DB_ID: 0,
          TRK_TRACK_NAME: "",
          TRK_TRACK_SURFACE_TYPE: "",
          _id: "",
        };
      }
      console.log(this.editedItem);
    },
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      this.$emit("visible", val);
    },
    "editedItem.TRK_STATE_OF_TRACK"(newValue) {
      const australianStates = [1, 2, 3, 4, 5, 6, 7, 8, 99]; // Numeric values for Australian states
      if (australianStates.includes(newValue)) {
        this.setCountryToAustralia();
      }
      if (newValue === 9) {
        this.editedItem.TRK_COUNTRY_OF_TRACK = {
          label: "New Zealand",
          value: "NZ",
        };
      }
      if (newValue === 10) {
        this.editedItem.TRK_COUNTRY_OF_TRACK = {
          label: "Hong Kong",
          value: "HK",
        };
      }
      if (newValue === 11) {
        this.editedItem.TRK_COUNTRY_OF_TRACK = {
          label: "Singapore",
          value: "SGP",
        };
      }
    },
  },
  computed: {
    isFormValid() {
      return (
        this.editedItem.TRK_TRACK_DB_ID &&
        this.isTrackIDValid(this.editedItem.TRK_TRACK_DB_ID) &&
        this.editedItem.TRK_TRACK_NAME &&
        this.isTrackNameValid(this.editedItem.TRK_TRACK_NAME) &&
        this.is3CharValid(this.editedItem.TRK_TRACK_3CHAR_ABBREV) &&
        this.is6CharValid(this.editedItem.TRK_TRACK_6CHAR_ABBREV) &&
        true
      );
    },
  },
  mounted() {},
};
</script>

<style scoped>
#checkboxes {
  display: inline-block;
  margin-right: 5px;
}
#checkboxes label {
  display: inline-block;
}
.invalid-field {
  border-color: red;
  background-color: #ffe6e6;
}
</style>
