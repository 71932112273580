<template>
  <Toast/>
  <div>
    <v-row style="margin-top: initial !important;">
      <!-- <v-col cols="3" class="d-flex align-center justify-center">
      </v-col>
      <v-col cols="2" class="ma-n4 d-flex align-center justify-center">
        <v-chip
            class="ma-2"
            color="green"
            text-color="black"
        >
          {{ queuesData.completed }}
        </v-chip>
        <p>Processed</p>
      </v-col>
      <v-col cols="2" class="d-flex align-center justify-center">
        <v-chip
            class="ma-2"
            color="amber"
            text-color="black"
        >
          {{ queuesData.processing }}
        </v-chip>
        <p>In-Progress</p>
      </v-col>
      <v-col cols="2" class="d-flex align-center justify-center">
        <v-chip
            class="ma-2"
            color="red"
            text-color="black"
        >
          {{ queuesData.failed }}
        </v-chip>
        <p>Failed</p>
      </v-col>
      <v-col cols="3" class="d-flex align-center justify-center">
      </v-col> -->

      <!-- <v-col>
        <v-link :href="activeLink">Download</v-link>
      </v-col> -->
    </v-row>

    <v-card width="90%" class="mx-auto mt-9">
      <DataTable :value="filteredFilesData" :paginator="true" :rows="10"
                 dataKey="_id" :rowHover="true" :loading="loading"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,25,50]"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 :globalFilterFields="['name','fileType','createdAt','status']" responsiveLayout="scroll">
        <template #header>
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div class="field-checkbox col active-section">
              <h5 class="m-0" style="font-size: 17px;">Received Files</h5>
            </div>
            <div class="field-checkbox col active-section">
                <Checkbox id="filterFields" v-model="filterFields" :binary="true" />
                <label for="filterFields">Fields</label>
            </div>
            <div class="field-checkbox col active-section">
                <Checkbox id="filterResults" v-model="filterResults" :binary="true" />
                <label for="filterResults">Results</label>
            </div>
            <div class="field-checkbox col active-section">
                <Checkbox id="filterUpdates" v-model="filterUpdates" :binary="true" />
                <label for="filterUpdates">Scratchings</label>
            </div>
            <div class="field-checkbox col active-section">
                <Checkbox id="filterChanges" v-model="filterChanges" :binary="true" />
                <label for="filterChanges">Gear Changes</label>
            </div>
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="namefilter" placeholder="Search this table"/>
            </span>
          </div>
        </template>
        <template #empty>
          No files available.
        </template>
        <template #loading>
          Loading files data, please wait...
        </template>
        <template #filter="{filterModel}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
        </template>
        <Column field="meetingDate" header="Meeting Date" :sortable="true"/>
        <Column field="name" header="Name" :sortable="true"/>
        <Column field="fileType" header="File Type" :sortable="true"/>
        <Column field="createdAt" header="Date Received" :sortable="true"/>
        <Column field="" header="Is Processed" :sortable="true"/>
        <Column field="s3file" header="Download" :sortable="false">
          <template #body="{data}">
            <Button  class="p-button-link" icon="pi pi-download" label="Download File"
                    @click="downloadFile(data.s3file)"/>
          </template>
        </Column>

      </DataTable>

    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import {saveAs} from "file-saver";
import {API} from "aws-amplify";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Checkbox from 'primevue/checkbox';

export default {
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    Toast,
    Checkbox
  },
  data: () => ({
    loading: true,
    search: "",
    errors: [],
    errored: false,
    alert: true,
    namefilter: '',
    // typeFilter:{
    //   // "Nominations": true,
    //   // "Weights": true,
    //   // "Acceptances": true,
    //   // "FinalFields": true,
    //   "Fields": true,
    //   "Update": true,
    //   "Changes": true,
    //   // "InterimResults": true,
    //   "Results": true
    // },
    filterFields: true,
    filterResults: true,
    filterUpdates: true,
    filterChanges: true,
    activeFilters: {},
    filesData: [],
    filteredFilesData: [],
    queuesData: {}
  }),
  computed: {
    headers() {
      return [
        {
          text: "Meeting Date",
          align: "start",
          value: "meetingDate",
        },
        {text: "File Name", value: "name"},
        {
          text: "File Type",
          value: "fileType",
          filter: (value) => {
            return this.activeFilters.fileType
                ? this.activeFilters.fileType.includes(value)
                : true;
          },
        },
        {text: "File Received", value: "createdAt"},
        {
          text: "Processed",
          value: "processedStatus",
          filter: (value) => {
            return this.activeFilters.processedStatus
                ? this.activeFilters.processedStatus.includes(value)
                : true;
          },
        },
        {text: " ", value: "s3file", sortable: false},
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  async created() {
    this.filesData = await this.getFilesList();
    this.filteredFilesData = this.filesData
    this.queuesData = await this.fetchQueueCount();
  },
  methods: {
    async getFilesList() {
      moment.locale('en-au');
      this.loading = true;
      return await API.get("MrCenApiGateway", `/admin/files/list`).then((response) => {
        this.loading = false;
        return response.map(
            (p) => {
              p.createdAt = moment.unix(p.modifyTime/1000).local().format("lll");
              // p.createdAt = moment.utc(p.createdAt).local().format("lll");
              p.meetingDate = moment.utc(p.meetingDate).local().format("lll");
              return p;
            });
      });
    },
    async fetchQueueCount() {
      return await API.get("MrCenApiGateway", `/admin/queues/list`).then((response) => {
        console.log('fetching queue count')
        console.log(response)
        return response
      })
    },
    async downloadFile(file) {
      this.errors = [];

      let filename = file;//.split('?')[0].split('/').slice(-1)[0];
      await API.get("MrCenApiGateway", `/admin/files/download?filename=${filename}`)
          .then((res) => {
            console.log('fetching file dl')
            console.log(res)
            this.activeLink = res.data
            // saveAs(res.data, filename);
            // window.open(res.data, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
           if (res !== undefined && res !== '')
            fetch(res, {
              responseType: "blob",
            }).then((response) => {
              console.log(response)
              saveAs(response.url, filename);
              // window.open(response.data, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
            }).catch((error) => {
              this.errors.push(error);
              console.log(error);
              this.errored = true;
              this.alert = true;
            });
            else {
              this.$toast.add({
                severity: 'error',
                summary: 'Cannot Download File',
                detail: 'This file cannot be downloaded at this time.',
                life: 6000
              });
           } 
          })
    },
    filterFilesData() {
      this.filteredFilesData = []
      var filterPush = []
      if (this.filterFields) filterPush.push('Nominations','Weights','Acceptances','FinalFields','Raci')
      if (this.filterResults) filterPush.push('Results','InterimResults')
      if (this.filterUpdates) filterPush.push('Update')  
      if (this.filterChanges) filterPush.push('Changes')  
      for (const file of this.filesData) {
        if ((this.namefilter == '' || file.name.toLowerCase().includes(this.namefilter.toLowerCase())) && filterPush.includes(file.fileType)) this.filteredFilesData.push(file) 
      }
    }
  },
  watch: {
    namefilter() {
      this.filterFilesData();
    },
    filterFields() {
      this.filterFilesData();
    },
    filterResults() {
      this.filterFilesData();
    },
    filterUpdates() {
      this.filterFilesData();
    },
    filterChanges() {
      this.filterFilesData();
    }
  }
}
</script>

<style scoped>
.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 20px !important;
  height: 2rem;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0.5rem !important;
}

.v-list-item--link {
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list-item--link:hover {
  background-color: rgba(0, 0, 0, 0.13);
}
</style>
