<template>
  <Dialog
    v-model="errorSummary"
    v-model:visible="showErrorDialog"
    :style="{ width: '600px' }"
    header="Can't save form"
    :modal="true"
    class="p-fluid"
    :closeable="false"
    @hide="errorSummary = null"
  >
    <div>
      {{ errorSummary }}
    </div>

    <template #footer>
      <Button
        label="Close"
        icon="pi pi-times"
        class="p-button-text"
        @click="
          errorSummary = null;
          showErrorDialog = false;
        "
      />
    </template>
  </Dialog>
  <Dialog
    v-model="editedItem"
    v-model:visible="isVisible"
    :style="{ width: '800px' }"
    header="Horse Details"
    :modal="true"
    :closeOnEscape="true"
    @hide="closeForm()"
    class="p-fluid"
  >
    <div class="formgrid grid">
      <div class="field col">
        <label for="name">Name</label>
        <InputText
          id="name"
          v-model="editedItem.HRN_HORSE_NAME"
          required="true"
          autofocus
          :class="{
            'invalid-field': !isHorseNameValid(editedItem.HRN_HORSE_NAME),
          }"
        />
      </div>

      <div class="field col">
        <label for="id">ID</label>
        <!-- <InputText id="id" v-model="editedItem.HRN_HORSE_ID" required="true" :disabled="true"/> -->
        <!-- <InputText id="id" v-model="editedItem.HRN_HORSE_ID" required="true" :class="{
            'invalid-field': !isIDValid(editedItem.HRN_HORSE_ID),
          }" /> -->
          <InputText id="id" v-model="editedItem.HRN_HORSE_ID" required="true" />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="name">Display Name</label>
        <InputText
          id="name"
          v-model="editedItem.HRN_DISPLAY_NAME"
          required="true"
          autofocus
        />
      </div>
      <div class="field col-8">
        <label for="name"
          >Previous Names (multiple names can be joined with ';')</label
        >
        <InputText
          id="name"
          v-model="editedItem.HRN_PREV_NAMES"
          required="true"
          autofocus
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="foalingdate">Foaling Date (YYYY-MM-DD)</label>
        <!-- <Calendar id="foalingdate" v-model="editedItem.HOR_FOALING_DATE" dateFormat="yy-mm-dd"
                  :showIcon="true"/> -->
        <InputText
          id="foalingdate"
          v-model="editedItem.HOR_FOALING_DATE"
          required="true"
        />
      </div>
      <div class="field col">
        <label for="country">Country of Origin</label>
        <AutoComplete
          v-model="editedItem.HOR_COUNTRY_OF_ORIGIN"
          id="country"
          :suggestions="this.filteredCountries"
          @complete="searchCountry($event)"
          :dropdown="true"
          field="label"
          autoHighlight
          forceSelection
        >
        </AutoComplete>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="sex">Sex</label>
        <Dropdown
          id="sex"
          v-model="editedItem.HOR_SEX"
          :options="horse_sexOptions"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
      <div class="field col">
        <label for="colour">Colour</label>
        <Dropdown
          id="colour"
          v-model="editedItem.HOR_COLOUR"
          :options="horse_colourOptions"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="trainer">Trainer</label>
        <!-- <AutoComplete
          id="trainer"
          v-model="selectedTrainer"
          :dropdown="true"
          :suggestions="filteredTrainers"
          field="label"
          @complete="searchTrainer"
          @item-select="onTrainerSelect"
          >{{ this.getTrainerById(editedItem.HOR_TRAINER_ID) }}
        </AutoComplete> -->
        <AutoComplete
          id="trainer"
          v-model="editedItem.HOR_TRAINER_ID"
          :dropdown="true"
          :suggestions="filteredTrainers"
          field="label"
          @complete="searchTrainer">
          {{ this.getTrainerById(editedItem.HOR_TRAINER_ID) }}
        </AutoComplete>

      </div>
      <div class="field col">
        <label for="training_loc">Training Location</label>
        <InputText
          id="training_loc"
          v-model="editedItem.HOR_TRAINING_LOCATION"
        />
      </div>
      <div class="field col">
        <label for="trainerId">Trainer ID</label>
        <InputText
          id="trainerId"
          v-model="editedItem.HOR_TRAINER_ID.value"
          disabled="true"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="sireId">Sire</label>
        <AutoComplete
          id="sireId"
          v-model="editedItem.HOR_SIRE_NAME"
          :suggestions="filteredHorses"
          @complete="searchHorse($event)"
          :dropdown="true"
          field="label"
          minLength="3"
          forceSelection
        >
        </AutoComplete>
      </div>
      <!-- <div class="field col">
        <label for="sireName">Sire Name</label>
        <InputText id="sireName" v-model="editedItem.HOR_SIRE_NAME.HRN_HORSE_ID" disabled="true"/>
      </div> -->
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="damId">Dam</label>
        <AutoComplete
          id="damId"
          v-model="editedItem.HOR_DAM_NAME"
          :suggestions="filteredHorses"
          @complete="searchHorse($event)"
          :dropdown="true"
          field="label"
          minLength="3"
          forceSelection
        >
        </AutoComplete>
      </div>
      <!-- <div class="field col">
        <label for="damName">Dam Name</label>
        <InputText id="damName" v-model="editedItem.HOR_DAM_ID.HRN_HORSE_NAME" disabled="true"/>
      </div> -->
      <div class="field col">
        <label for="damsire">Sire of Dam</label>
        <InputText
          id="damsire"
          :value="editedItem.HOR_SIRE_OF_DAM_NAME"
          disabled="true"
        ></InputText>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="owners">Owners</label>
        <Textarea
          id="owners"
          v-model="editedItem.HOR_OWNER_NAMES"
          rows="2"
          cols="30"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="silks">Silks</label>
        <Textarea
          id="silks"
          v-model="editedItem.HOR_RACING_COLOURS"
          rows="2"
          cols="30"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="starts">Starts</label>
        <InputNumber id="starts" v-model="editedItem.HOR_START_TOTAL" />
      </div>
      <div class="field col">
        <label for="starts">Wins</label>
        <InputNumber id="wins" v-model="editedItem.HOR_H_1STS_TOTAL" />
      </div>
      <div class="field col">
        <label for="seconds">Seconds</label>
        <InputNumber id="seconds" v-model="editedItem.HOR_H_2NDS_TOTAL" />
      </div>
      <div class="field col">
        <label for="thirds">Thirds</label>
        <InputNumber id="thirds" v-model="editedItem.HOR_H_3RDS_TOTAL" />
      </div>
      <div class="field col">
        <label for="prizemoney">Prizemoney</label>
        <InputNumber
          id="prizemoney"
          v-model="editedItem.HOR_TOTAL_PRIZEMONEY"
          mode="currency"
          currency="USD"
          locale="en-US"
          :maxFractionDigits="0"
        />
      </div>
      
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="mr1">mr1</label>
        <InputNumber id="mr1" v-model="editedItem.HOR_RATINGS.mr1" :maxFractionDigits="1" />
      </div>
      <div class="field col">
        <label for="mr1_d">mr1_d</label>
        <InputNumber id="wins" v-model="editedItem.HOR_RATINGS.mr1_d" :maxFractionDigits="1" />
      </div>
      <div class="field col">
        <label for="mr2">mr2</label>
        <InputNumber id="mr2" v-model="editedItem.HOR_RATINGS.mr2" :maxFractionDigits="1" />
      </div>
      <div class="field col">
        <label for="mr2_d">mr2_d</label>
        <InputNumber id="mr2_d" v-model="editedItem.HOR_RATINGS.mr2_d" :maxFractionDigits="1" />
      </div>
      <div class="field col">
        <label for="mr3">mr3</label>
        <InputNumber id="mr3" v-model="editedItem.HOR_RATINGS.mr3" :maxFractionDigits="1" />
      </div>
      <div class="field col">
        <label for="mr3_d">mr3_d</label>
        <InputNumber id="mr3_d" v-model="editedItem.HOR_RATINGS.mr3_d" :maxFractionDigits="1" />
      </div>
      <div class="field col">
        <label for="mr4">mr4</label>
        <InputNumber id="mr4" v-model="editedItem.HOR_RATINGS.mr4" :maxFractionDigits="1" />
      </div>
      <div class="field col">
        <label for="mr4_d">mr4_d</label>
        <InputNumber id="mr4_d" v-model="editedItem.HOR_RATINGS.mr4_d" :maxFractionDigits="1" />
      </div>
      <div class="field col">
        <label for="mrj">mrj</label>
        <InputNumber id="mrj" v-model="editedItem.HOR_RATINGS.mrj" :maxFractionDigits="1" />
      </div>
      
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeForm()"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        :disabled="!isFormValid || user_role > 4"
        @click="validateAndSave()"
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
//import Calendar from "primevue/calendar";
import AutoComplete from "primevue/autocomplete";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import { API } from "aws-amplify";
import moment from "moment";

export default {
  components: {
    Dialog,
    Button,
    InputText,
    InputNumber,
    //Calendar,
    AutoComplete,
    Textarea,
    Dropdown,
  },
  data: () => ({
    editedItem: {},
    isVisible: null,
    filteredCountries: null,
    filteredHorses: null,
    filteredTrainers: null,
    selectedCountry: null,
    selectedTrainer: null,
    errorSummary: null,
    showErrorDialog: false,
    horse_sexOptions: [
      { value: "F", label: "Filly" },
      { value: "C", label: "Colt" },
      { value: "G", label: "Gelding" },
      { value: "M", label: "Mare" },
      { value: "R", label: "Rig" },
      { value: "H", label: "Horse" },
    ],
    horse_colourOptions: [
      { value: 1, label: "CHESTNUT" },
      { value: 2, label: "BAY" },
      { value: 3, label: "BROWN" },
      { value: 4, label: "BLACK" },
      { value: 5, label: "GREY" },
      { value: 9, label: "WHITE" },
      { value: 14, label: "BAY OR BROWN" },
      { value: 15, label: "BROWN OR BLACK" },
      { value: 16, label: "GREY-CHESTNUT" },
      { value: 17, label: "GREY-BAY" },
      { value: 18, label: "GREY-BROWN" },
      { value: 19, label: "GREY-BLACK" },
      { value: 20, label: "GREY-ROAN" },
      { value: 21, label: "ROAN"}
    ],
  }),
  props: {
    initialItem: {},
    visible: null,
    commonEntities: null,
    current_user: null,
    user_role: null
  },
  methods: {
    validateAndSave() {
      const horseName = this.editedItem.HRN_HORSE_NAME;
      const displayName = this.editedItem.HRN_DISPLAY_NAME;
      if (typeof horseName !== "string" || typeof displayName !== "string") {
      this.errorSummary = `Horse name or display name is missing`;
      this.showErrorDialog = true;
      return;
      }
      if (horseName.toLowerCase() !== displayName.toLowerCase()) {
        this.errorSummary = `Horse name and display name do not match."`;
        this.showErrorDialog = true;
        return;
      }
      var foal_moment = moment(this.editedItem.HOR_FOALING_DATE);
      if (
        foal_moment.isAfter(moment()) ||
        foal_moment.isBefore("1971-01-01")
      ) {
        // alert('The date here seems well off, either its in the future or before 1980, try again.')
        this.errorSummary =
          "The foaling date here seems well off, either its in the future or before 1971, try again.";
        this.showErrorDialog = true;

        return;
      }
      this.saveForm();
    },
    async saveForm() {
      console.log(this.editedItem);

      if (typeof this.editedItem.HOR_COUNTRY_OF_ORIGIN === "object") {
        this.editedItem.HOR_COUNTRY_OF_ORIGIN = this.convertObject(
          this.editedItem.HOR_COUNTRY_OF_ORIGIN,
          "value"
        );
      }
      if (typeof this.editedItem.HOR_TRAINER_ID === "object") {
        this.editedItem.HOR_TRAINER_ID = this.convertObject(
          this.editedItem.HOR_TRAINER_ID,
          "value"
        );
      }
      if (typeof this.editedItem.HOR_SIRE_NAME === "object") {
        this.editedItem.HOR_SIRE_NAME = this.convertObject(
          this.editedItem.HOR_SIRE_NAME,
          "value"
        );
      } else if (typeof this.editedItem.HOR_SIRE_NAME === "string") {
        this.editedItem.HOR_SIRE_NAME = this.editedItem.HOR_SIRE_ID;
      }
      if (typeof this.editedItem.HOR_DAM_NAME === "object") {
        this.editedItem.HOR_DAM_NAME = this.convertObject(
          this.editedItem.HOR_DAM_NAME,
          "value"
        );
      } else if (typeof this.editedItem.HOR_DAM_NAME === "string") {
        this.editedItem.HOR_DAM_NAME = this.editedItem.HOR_DAM_ID;
      }
      if (
        this.editedItem.HRN_PREV_NAMES &&
        this.editedItem.HRN_PREV_NAMES != "" &&
        typeof this.editedItem.HRN_PREV_NAMES === "string"
      ) {
        this.editedItem.HRN_PREV_NAMES =
          this.editedItem.HRN_PREV_NAMES.split(";");
      }
      if (this.editedItem.HOR_RATINGS.mr1 === 0){
        this.editedItem.HOR_RATINGS = {}
      } else{
        let baseRating = this.editedItem.HOR_RATINGS.mr1;
        if (this.editedItem.HOR_RATINGS.mr1_d === 0) {
          this.editedItem.HOR_RATINGS.mr1_d = baseRating
        }
        let baseRating_d = this.editedItem.HOR_RATINGS.mr1_d

        this.editedItem.HOR_RATINGS = {
          "mr1": baseRating,
          "mr1_d": baseRating_d,
          "mr2": this.editedItem.HOR_RATINGS.mr2 != 0 ? this.editedItem.HOR_RATINGS.mr2 : baseRating,
          "mr2_d": this.editedItem.HOR_RATINGS.mr2_d ? this.editedItem.HOR_RATINGS.mr2_d : baseRating_d,
          "mr3": this.editedItem.HOR_RATINGS.mr3 ? this.editedItem.HOR_RATINGS.mr3 : baseRating,
          "mr3_d": this.editedItem.HOR_RATINGS.mr3_d ? this.editedItem.HOR_RATINGS.mr3_d : baseRating_d,
          "mr4": this.editedItem.HOR_RATINGS.mr4 ? this.editedItem.HOR_RATINGS.mr4 : baseRating,
          "mr4_d": this.editedItem.HOR_RATINGS.mr4_d ? this.editedItem.HOR_RATINGS.mr4_d : baseRating_d,
          "mrj": this.editedItem.HOR_RATINGS.mrj ? this.editedItem.HOR_RATINGS.mrj : baseRating
        }
      }
      if (this.editedItem._id === "") {
        await API.post("MrCenApiGateway", `/horse`, {
          body: this.editedItem,
        }).then(() => {
          this.$toast.add({
            severity: "success",
            summary: `the horse ${this.editedItem.HRN_HORSE_NAME} was created`,
            life: 5000,
          });
        });
      } else {
        await API.put("MrCenApiGateway", `/horse`, {
          body: this.editedItem,
        }).then(() => {
          this.$toast.add({
            severity: "success",
            summary: `the horse ${this.editedItem.HRN_HORSE_NAME} was updated`,
            life: 5000,
          });
        });
      }
      this.closeForm();
    },
    closeForm() {
      this.isVisible = false;
      this.$emit("visible", false);
    },
    getTrainerById(trainerId) {
      return this.commonEntities.allTrainers.find((t) => t.value === trainerId)
        .label;
    },
    searchCountry(event) {
      this.filteredCountries = this.commonEntities.allCountries.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    searchTrainer(event) {
      this.filteredTrainers = this.commonEntities.allTrainers.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    getDamSire(damName) {
      return API.get(
        "MrCenApiGateway",
        `/admin/search/horse?id=${damName}`
      ).then((response) => {
        return response.HOR_SIRE_NAME;
      });
    },
    async searchHorse(event) {
      setTimeout(async () => {
        if (!event.query.trim().length) {
          this.filteredHorses = [];
        } else {
          this.filteredHorses = await API.get(
            "MrCenApiGateway",
            `/admin/search/horse?s="${event.query}"`
          ).then((response) => {
            var searchresponse = [];
            for (var horse of response) {
              searchresponse.push({
                value: horse.HRN_HORSE_ID,
                label:
                  horse.HRN_HORSE_NAME +
                  " (" +
                  horse.HOR_COUNTRY_OF_ORIGIN +
                  ") : " +
                  horse.HOR_FOALING_DATE +
                  " by " +
                  horse.HOR_SIRE_NAME,
              });
            }
            return searchresponse;
          });
        }
      }, 250);
    },
    convertObject(object, key) {
      return object[key];
    },
    isHorseNameValid(horseName) {
      return !horseName || horseName.trim().length > 0;
    },
    isIDValid(ID) {
      const num = Number(ID);
      return ID && !isNaN(num) && num > 0;
    },
    // onTrainerSelect(trainer) {
    //   if (trainer && trainer.value && trainer.value.value) {
    //     this.editedItem.HOR_TRAINER_ID = trainer.value.value;
    //   } else {
    //     this.editedItem.HOR_TRAINER_ID = null;
    //   }
    // },
    /*  setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredCountries = this.commonEntities.allCountries;
        }
        else {
          this.filteredCountries = this.commonEntities.allCountries.filter((country) => {
            return country.value.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },*/
  },
  watch: {
    initialItem(val) {
      this.editedItem = val;
      if (!this.editedItem._id) {
        this.editedItem = {
          HOR_ALT_SIRE_ID: 0,
          HOR_BOBS_STATE: 0,
          HOR_COLOUR: 0,
          HOR_COUNTRY_OF_ORIGIN: "",
          HOR_CURRENT_BLINKER_IND: "",
          HOR_DAM_ID: 0,
          HOR_DAM_NAME: "",
          HOR_DRY_1ST_TOTAL: 0,
          HOR_DRY_2ND_TOTAL: 0,
          HOR_DRY_3RD_TOTAL: 0,
          HOR_DRY_START_TOTAL: 0,
          HOR_ENSCRIBE_DB_ID: -1,
          HOR_FOALING_DATE: "1900-01-01",
          HOR_HORSE_DB_ID: 0,
          HOR_HORSE_INCOMPLETE_FORM_FLAG: "",
          HOR_H_1STS_TOTAL: 0,
          HOR_H_2NDS_TOTAL: 0,
          HOR_H_3RDS_TOTAL: 0,
          HOR_H_4THS_TOTAL: 0,
          HOR_H_5THS_TOTAL: 0,
          HOR_OWNER_NAMES: "",
          HOR_RACING_COLOURS: "",
          HOR_ROW_STATUS: "A",
          HOR_SEX: "F",
          HOR_SIRE_ID: 0,
          HOR_SIRE_NAME: "",
          HOR_SIRE_OF_DAM_NAME: "",
          HOR_START_TOTAL: 0,
          HOR_TOTAL_PRIZEMONEY: 0,
          HOR_TRAINER_ID: 0,
          HOR_TRAINING_LOCATION: "",
          HOR_WET_1ST_TOTAL: 0,
          HOR_WET_2ND_TOTAL: 0,
          HOR_WET_3RD_TOTAL: 0,
          HOR_WET_START_TOTAL: 0,
          HRN_COUNTRY_OF_NAME: "",
          HRN_HORSE_ID: 0,
          HRN_HORSE_NAME: "",
          HRN_DISPLAY_NAME: "",
          HRN_ROW_STATUS: "A",
          HRN_PREV_NAMES: [],
          HOR_RATINGS: {},
          _id: "",
        };
      }
      if (
        this.editedItem.HRN_PREV_NAMES &&
        this.editedItem.HRN_PREV_NAMES.length > 1
      ) {
        this.editedItem.HRN_PREV_NAMES =
          this.editedItem.HRN_PREV_NAMES.join(";");
      }
      if (!this.editedItem.HOR_RATINGS || !this.editedItem.HOR_RATINGS.mr1) {
        this.editedItem.HOR_RATINGS = {
          "mr1": 0,
          "mr1_d": 0,
          "mr2": 0,
          "mr2_d": 0,
          "mr3": 0,
          "mr3_d": 0,
          "mr4": 0,
          "mr4_d": 0,
          "mrj": 0
        }
      }
      console.log(this.editedItem);
    },
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      this.$emit("visible", val);
    },
    "editedItem.HRN_HORSE_ID"(newVal) {
      this.isIDValid(newVal);
    },
  },
  computed: {
    isFormValid() {
      return (
        // this.editedItem.HRN_HORSE_ID &&
        // this.isIDValid(this.editedItem.HRN_HORSE_ID) &&
        this.isHorseNameValid(this.editedItem.HRN_HORSE_NAME) &&
        true
      );
    },
  },
  mounted() {},
};
</script>

<style scoped>
.invalid-field {
  border-color: red;
  background-color: #ffe6e6;
}
</style>
