<template>
  <v-card-title style="margin-bottom: 20px;">Search for
    {{
       capitalize(this.$route.query.entity)
    }}
  </v-card-title
  >
  <div class="text-center pt-2">
    <span class="p-float-label">
            <InputText id="searchField" type="text" style="width:99%;" v-model="searchTerm"
                       @keydown.enter="searchEntity(searchTerm)"/>
            <label for="searchField">Search terms</label>
    </span>

    <v-btn
        color="primary"
        dark
        class="mb-2"
        style="margin-top: 30px"
        @click="searchEntity(searchTerm)">
      Search
    </v-btn>
  </div>
  <v-progress-linear
      v-model="progressBarValue"
      color="primary"
      indeterminate
      :active="showProgressBar"
  ></v-progress-linear>
</template>

<script>
import {API} from "aws-amplify";
import InputText from "primevue/inputtext";
// import moment from "moment";


export default {
  components: {
    InputText
  },
  data: () => ({
    searchTerm: "",
    showProgressBar: false,
    progressBarValue: 0,
    results: [],

  }),
  props: {
    triggerSearch: null
  },
  emits: ['searchResults'],
  watch: {
    searchResults(searchTerm) {
      console.log("Emitting results (watch)");
      this.$emit("searchResults", searchTerm);
    },
  triggerSearch(val) {
       if(val == true){
         if(this.searchTerm == ""){
           this.fetchAllRecords();
         }else{
            this.searchEntity(this.searchTerm);
         }
       }
    }
  },
  methods: {
    async searchEntity(searchTerm) {
      if (searchTerm == "") {
       // alert("Please enter a search term.")
        this.fetchAllRecords();
        return;
      }

      this.showProgressBar = true;
      this.results = [];
      switch (this.$route.query.entity) {
        case "client": {
          await API.get(
              "MrCenApiGateway",
              `/admin/client?search=${searchTerm}`
          ).then((response) => {
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        default:
          break;
      }
    },

    async  fetchAllRecords(){
      this.showProgressBar = true;
      this.results = [];
      switch (this.$route.query.entity) {
        case "client": {
          await API.get(
              "MrCenApiGateway",
              `/admin/client`
          ).then((response) => {
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        default:
          break;
      }
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },

    capitalize(str) {
      if (str !== undefined) return str.charAt(0).toUpperCase() + str.slice(1);
    },


  },

  mounted() {

  },
};
</script>

<style>
</style>
