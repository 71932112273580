<template>
    <v-tabs v-model="currentTab" fixed-tabs background-color="primary"  slider-color="info"
          >
      <v-tab
        v-for="(item, i) in db_items"
        :key="i"
        :value="item.entity"
        :title="item.text"
        @click="navigateTo(item.entity)"
        ><v-icon>{{ item.icon }}</v-icon
        >&nbsp;&nbsp;{{ item.text }}
      </v-tab>
    </v-tabs>
    <v-card width="90%" class="mx-auto mt-4" elevation="0">
      <DatabaseSearch
        @searchResults="searchEntity"
      />
      <!-- v-bind="results" -->
    </v-card>
    <v-card width="90%" class="mx-auto mt-2" elevation="0">
      <DatabaseResults
        :searchResults="searchResults"
        :current_user="current_user"
        :user_role="user_role"
      />
      <!--  v-bind="DatabaseSearch.results" -->
    </v-card>
</template>

<script>
import DatabaseSearch from "./DatabaseSearch.vue";
import DatabaseResults from "./DatabaseResults.vue";
import {Auth} from "aws-amplify"

export default {
  data: () => ({
    searchResults: [],
    currentTab: null,
    db_items: [
      {
        icon: "mdi-horse",
        text: "Horses",
        entity: "horses",
      },
      {
        icon: "mdi-form-select",
        text: "Horse Form",
        entity: "horseforms",
      },
      {
        icon: "mdi-horse-human",
        text: "Jockeys",
        entity: "jockeys",
      },
      {
        icon: "mdi-human-male-board",
        text: "Trainers",
        entity: "trainers",
      },
      {
        icon: "mdi-road-variant",
        text: "Tracks",
        entity: "tracks",
      },
      {
        icon: "mdi-account-group",
        text: "Clubs",
        entity: "clubs",
      }
    ],
    current_user: null,
    user_role: null
  }),
  async created() {
    this.current_user = await Auth.currentUserInfo();
    this.user_role = (this.current_user && this.current_user.attributes && this.current_user.attributes["custom:permissions"]) ? this.current_user.attributes["custom:permissions"] : null;
  },
  methods: {
    receiveTracks(event) {
      this.allTracks = event;
    },
    receiveCountries(event) {
      this.allCountries = event;
    },
    receiveClubs(event) {
      this.allClubs = event;
    },
    receiveJockeys(event) {
      this.allJockeys = event;
    },
     receiveTrainers(event) {
      this.allTrainers = event;
    },
    searchEntity(searchResults) {
      this.searchResults = searchResults;
    },
    navigateTo(entity) {
      try {
        const destination = "#/db?entity=" + entity;
        this.currentTab = entity;
        if (window.location.pathname + window.location.search !== destination)
          this.searchResults = []
          this.$router.push(destination);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted(){
    this.currentTab = this.$route.query.entity;
  },
  watch:{
    '$route'(to, ){
      this.currentTab = to.query.entity;
    }
  },
  components: {
    DatabaseSearch,
    DatabaseResults,
  },
};
</script>
