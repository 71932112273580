<template>
  <v-app>
    <TopNav/>
    <v-main class="layout-wrapper layout-wrapper-dark">
      <router-view/>
    </v-main>

    <v-card>
      <v-footer>
        <v-card flat tile width="100%" class="primary lighten-1 text-center">
          <!-- <v-card-text>
            <v-btn v-for="icon in icons" :key="icon" class="mx-4" icon>
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-card-text> -->
          <v-card-text class="white--text">
            &copy; {{ new Date().getFullYear() }}
            <strong>Mediality.</strong>
            All Rights Reserved
          </v-card-text>
        </v-card>
      </v-footer>
    </v-card>
  </v-app>
</template>

<script>
import TopNav from "./components/TopNav.vue";

export default {
  name: "app",
  components: {
    TopNav,
  },
  data: () => ({
  }),
 
};
</script>
