<template>
    <v-tabs fixed-tabs background-color="primary"  slider-color="info">
      <v-tab
        v-for="(item, i) in admin_items"
        :key="i"
        :title="item.text"
        @click="navigateTo(item.entity)">
        <v-icon>{{ item.icon }}</v-icon>&nbsp;&nbsp;{{ item.text }}
      </v-tab>
    </v-tabs>
    <v-card width="90%" class="mx-auto mt-9" elevation="0">
      <AdminSearch :triggerSearch="trigSearch"
        @searchResults="searchEntity"/>
    </v-card>
    <v-card width="90%" class="mx-auto mt-9" elevation="0">
    </v-card>
    <v-card width="90%" class="mx-auto mt-9" elevation="0">
      <AdminResults @reloadResults="resultsRefresh"
        :searchResults="searchResults"/>
    </v-card>
</template>

<script>
  import AdminSearch from "./AdminSearch.vue";
  import AdminResults from "./AdminResults.vue";
  export default  {

    data:() => ({
    searchResults: [],
    trigSearch : false,
    admin_items:[
       {
        icon: "mdi-account-multiple",
        text: "Clients",
        entity: "client",
      },
    ],
    }),
    methods: {
      searchEntity(searchResults) {
        this.searchResults = searchResults;
        this.trigSearch = false;
      },
      resultsRefresh(val){
        if(val == true){
          this.trigSearch = val
        }
      }
    },
    computed: {

    },
    watch:{

    },
    mounted() {
      this.resultsRefresh(true);
    },
    components: {
      AdminSearch,
      AdminResults,
  },
}
</script>

