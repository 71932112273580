<template>
  <div>
    <v-main>
      <div class="amplify-flex" style="display: flex; align-items: center; flex-direction: column;">
        <Authenticator :hide-sign-up="true"> </Authenticator>
      </div>
      <!-- <form @submit.prevent="login">
        <v-card width="500" class="mx-auto mt-9">
          <v-card-title>Login</v-card-title>
          <v-card-text>
            <v-text-field
              label="Email"
              v-model="email"
              prepend-icon="mdi-account-circle"
            />
            <v-text-field
              label="Password"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @keydown.enter="login()"
            />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
             <v-btn color="success">Register</v-btn>
            <v-btn color="info" @click="login">Login</v-btn>
          </v-card-actions>
        </v-card>
      </form> -->
    </v-main>
    <!-- <v-main>
      <router-view />
    </v-main> -->
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
//import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";

export default {
  name: "SigninScreen",
  data: () => ({
    email: "",
    password: "",
    showPassword: false,
  }),
  methods: {
    async login() {
      try {
        const user = await Auth.signIn(this.email, this.password);
        console.log("user", user);
        //alert("Successfully logged in");
        this.$router.push("/meetings");
      } catch (error) {
        alert(error.message);
      }
    },
    redirectToMeetings() {
      this.$router.push("/meetings");
    },
  },
  // services: {
  //   async handleSignIn(formData) {
  //     let { username, password } = formData;
  //     this.$router.push("#/meetings");
  //     console.log("handleSignIn");
  //     return Auth.signIn({
  //       username,
  //       password,
  //     });
  //   },
  // }
};
</script>
