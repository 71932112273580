<template>
  <Dialog
    v-model="editedItem"
    v-model:visible="isVisible"
    :style="{ width: '800px' }"
    header="Trainer Details"
    :modal="true"
    :closeOnEscape="true"
    @hide="closeForm()"
    class="p-fluid"
  >
    <div class="formgrid grid">
      <div class="field col">
        <label for="traiid">Trainer ID</label>
        <!-- <InputText id="traiid" v-model="editedItem.TRN_TRAINER_ID" required="true" :disabled="true"/> -->
        <InputNumber
          :useGrouping="false"
          id="traiid"
          v-model="editedItem.TRN_TRAINER_ID"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="sname">Surname</label>
        <InputText
          id="sname"
          v-model="editedItem.TRN_TRAINER_SURNAME"
          required="true"
        />
      </div>
      <div class="field col"></div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="fname">First Name</label>
        <InputText
          id="fname"
          v-model="editedItem.TRN_TRAINER_FIRSTNAME"
          required="true"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="dname">Display Name</label>
        <InputText
          id="dname"
          v-model="editedItem.TRN_TRAINER_DISPLAYNAME"
          required="true"
          :class="{
            'invalid-field': !isDisplayNameValid(
              editedItem.TRN_TRAINER_DISPLAYNAME
            ),
          }"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="psdname">Partnership Display Name</label>
        <InputText
          id="psdname"
          v-model="editedItem.TRN_PARTNERSHIP_DISPLAYNAME"
          required="true"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="trailoc">Location</label>
        <InputText id="trailoc" v-model="editedItem.Location" required="true" />
      </div>
      <div class="field col"></div>

      <div class="field col">
        <label for="state">State</label>
        <Dropdown
          id="state"
          v-model="editedItem.State"
          :options="state_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="traiRAid">Trainer RA ID</label>
        <InputText
          id="traiRAid"
          v-model="editedItem.RISATrainerID"
          required="true"
        />
      </div>
      <div class="field col">
        <label for="partRAid">RA Partner ID</label>
        <InputText
          id="partRAid"
          v-model="editedItem.RISAPartnerID"
          required="true"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="traiRAname">Trainer RISA Name</label>
        <InputText id="traiRAname" v-model="editedItem.Name" required="true" />
      </div>
      <div class="field col">
        <label for="traiNZname">Trainer NZ Name</label>
        <InputText
          id="traiNZname"
          v-model="editedItem.NZ_Name"
          required="true"
        />
      </div>
    </div>

    <div class="formgrid grid">
      <div class="field col">
        <label for="traiactive">Active</label>
        <Dropdown
          id="traiactive"
          v-model="editedItem.TRN_ROW_STATUS"
          :options="active_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    </div>

    <!-- <label for="apprentice">Apprentice Claim</label>
     <div>
       <label>Yes</label>
       <input type="checkbox"  value="Y" v-mode="Apprentice_choice"/>

       <label>No</label>
       <input type="checkbox"  value="N"  v-model="Apprentice_choice"/>
     </div>
   </div>
 </div>
<div class="formgrid grid">
   <div class="field col">
     <label for="amateur">Amateur</label>
     <div>
       <label>Yes</label>
       <input type="checkbox" value="Y" v-model="Amateur_Choice"/>
       <label>No</label>
       <input type="checkbox" value="N"  v-model="Amateur_Choice"/>
     </div>
   </div>
 </div>
 <div class="formgrid grid">
   <div class="field col">
     <label for="active">Active</label>
     <div>
       <label>Yes</label>
       <input type="checkbox" value="Y" v-model="Active_Choice"/>
       <label>No</label>
       <input type="checkbox" value="N" v-model="Active_Choice"/>
     </div>
   </div>
 </div>-->
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeForm()"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        :disabled="!isFormValid || user_role > 4"
        @click="saveForm()"
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import { API } from "aws-amplify";

export default {
  components: {
    Dialog,
    Button,
    InputText,
    Dropdown,
    InputNumber,
  },
  data: () => ({
    editedItem: {},
    isVisible: null,
    filteredCountries: null,
    filteredHorses: null,
    filteredTrainers: null,
    selectedCountry: null,
    Apprentice_choice: [],
    Amateur_Choice: [],
    Active_Choice: [],
    active_options: [
      { value: "A", label: "Active" },
      { value: "I", label: "Inactive" },
    ],
    state_options: [
      { value: 0, label: "" },
      { value: 1, label: "ACT" },
      { value: 2, label: "NSW" },
      { value: 3, label: "VIC" },
      { value: 4, label: "QLD" },
      { value: 5, label: "SA" },
      { value: 6, label: "WA" },
      { value: 7, label: "TAS" },
      { value: 8, label: "NT" },
      { value: 9, label: "NZ" },
      { value: 10, label: "HK" },
      { value: 11, label: "SGP" },
      { value: 99, label: "AUS" },
    ],
  }),
  props: {
    initialItem: {},
    visible: null,
    commonEntities: null,
    current_user: null,
    user_role: null,
  },
  methods: {
    async saveForm() {
      this.trimFields();
      console.log(this.editedItem);
      if (this.editedItem._id === "") {
        await API.post("MrCenApiGateway", `/trainer`, {
          body: this.editedItem,
        }).then(() => {
          this.$toast.add({
            severity: "success",
            summary: `the trainer ${this.editedItem.TRN_TRAINER_DISPLAYNAME}${this.editedItem.TRN_PARTNERSHIP_DISPLAYNAME} was created`,
            life: 5000,
          });
        });
      } else {
        await API.put("MrCenApiGateway", `/trainer`, {
          body: this.editedItem,
        }).then(() => {
          this.$toast.add({
            severity: "success",
            summary: `the trainer ${this.editedItem.TRN_TRAINER_DISPLAYNAME}${this.editedItem.TRN_PARTNERSHIP_DISPLAYNAME} was updated`,
            life: 5000,
          });
        });
      }
      this.closeForm();
    },
    closeForm() {
      this.isVisible = false;
      this.$emit("visible", false);
    },
    getTrainerById(trainerId) {
      return this.commonEntities.allTrainers.find((t) => t.value === trainerId)
        .label;
    },
    searchCountry(event) {
      this.filteredCountries = this.commonEntities.allCountries.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    searchTrainer(event) {
      this.filteredTrainers = this.commonEntities.allTrainers.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    isDisplayNameValid(displayName) {
      return displayName && /^[&'A-Za-z\s'-]+$/.test(displayName.trim());
    },
    isTrainerIdValid(trainerId) {
      const num = Number(trainerId);
      return trainerId && !isNaN(num) && num > 0;
    },
    trimFields() {
      for (const key in this.editedItem)
        if (
          typeof this.editedItem[key] === "string" ||
          this.editedItem[key] instanceof String
        )
          this.editedItem[key] = this.editedItem[key].trim();
    },
  },
  watch: {
    initialItem(val) {
      this.editedItem = val;
      if (!this.editedItem._id) {
        this.editedItem = {
          Location: "",
          Name: "",
          NZ_Name: "",
          RISATrainerID: 0,
          RISAPartnerID: 0,
          State: 0,
          TRN_PARTNERSHIP_DISPLAYNAME: "",
          TRN_ROW_STATUS: "A",
          TRN_TRAINER_DISPLAYNAME: "",
          TRN_TRAINER_FIRSTNAME: "",
          TRN_TRAINER_ID: 0,
          TRN_TRAINER_IN_USE_IND: "Y",
          TRN_TRAINER_SURNAME: "",
          _id: "",
        };
      }
      console.log(this.editedItem);
    },
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      this.$emit("visible", val);
    },
  },
  computed: {
    isFormValid() {
      return (
        this.editedItem.TRN_TRAINER_DISPLAYNAME &&
        this.isDisplayNameValid(this.editedItem.TRN_TRAINER_DISPLAYNAME) &&
        true
      );
    },
  },
  mounted() {},
};
</script>

<style scoped>
#checkboxes {
  display: inline-block;
  margin-right: 5px;
}
#checkboxes label {
  display: inline-block;
}
.invalid-field {
  border-color: red;
  background-color: #ffe6e6;
}
.invalid-number { /* Target the input element inside the InputNumber */
  border: 1px solid red;    /* Add a border when invalid */
} 
</style>
