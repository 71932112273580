<template>
  <v-card-title style="margin-bottom: 20px"
    >Search for
    {{
      this.$route.query.entity === "horseforms"
        ? "Horse-Form"
        : capitalize(this.$route.query.entity)
    }}
  </v-card-title>

  <div class="row">
    <div class="v-col-3">
      <span class="p-float-label">
        <InputText
          id="searchField"
          type="text"
          style="width: 99%"
          v-model="searchTerm"
          @keydown.enter="searchEntity(searchTerm)"
        />
        <label for="searchField">Search terms</label>
      </span>
    </div>
    <div class="v-col-1">
      <v-btn
        :disabled="isSearchDisabled"
        color="primary"
        dark
        class="mb-2"
        @click="searchEntity(searchTerm)"
      >
        Search
      </v-btn>
    </div>
    <div v-if="this.$route.query.entity == 'horses'" class="v-col-5">
      <div class="row">
        <div class="v-col">
          <RadioButton
            v-model="search_type"
            inputId="horseSch"
            name="search_type"
            value="horse"
          />
          <label for="horseSch" class="ml-2">Horse</label>
        </div>
        <div class="v-col">
          <RadioButton
            v-model="search_type"
            inputId="damSch"
            name="search_type"
            value="dam"
          />
          <label for="damSch" class="ml-2">Dam</label>
        </div>
        <div class="v-col">
          <RadioButton
            v-model="search_type"
            inputId="sireSch"
            name="search_type"
            value="sire"
          />
          <label for="sireSch" class="ml-2">Sire</label>
        </div>
      </div>
    </div>
    <div
      v-if="
        this.$route.query.entity === 'jockeys' ||
        this.$route.query.entity === 'trainers' ||
        this.$route.query.entity === 'tracks'
      "
      class="row mt-4"
    >
      <div class="v-col-3">
        <v-text-field
          v-model="fromDate"
          label="From Date"
          type="date"
          outlined
          dense
        ></v-text-field>
      </div>
      <div class="v-col-3">
        <v-text-field
          v-model="toDate"
          label="To Date"
          type="date"
          outlined
          dense
        ></v-text-field>
      </div>
      <div class="v-col-3">
        <v-text-field
        :label="getEntityLabel"
        variant="outlined"
        v-model="entityId"
        dense
      ></v-text-field>
      </div>
      <div class="v-col-3">
        <v-btn
          color="secondary"
          dark
          class="mb-2"
          @click="getEntityForm"
          :disabled="isGetFormDisabled"
        >
          Get Form
        </v-btn>
      </div>
    </div>
  </div>
  <div v-if="formResults.length > 0" class="mt-4">
    <h3>Form Results</h3>
    <table class="form-results-table">
      <thead>
        <tr>
          <th>Horse ID</th>
          <th>Meeting Date</th>
          <th>Race No</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="result in formResults" :key="result.horse_id + result.meeting_date + result.race_no">
          <td>{{ result.horse_id }}</td>
          <td>{{ result.meeting_date }}</td>
          <td>{{ result.race_no }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else-if="!showProgressBar" class="mt-4">
  <p>No results found.</p>
</div>
  <v-progress-linear
    v-model="progressBarValue"
    color="primary"
    indeterminate
    :active="showProgressBar"
  ></v-progress-linear>
</template>

<script>
import { API } from "aws-amplify";
import InputText from "primevue/inputtext";
import moment from "moment";
import RadioButton from "primevue/radiobutton";

export default {
  components: {
    InputText,
    RadioButton,
  },
  data: () => ({
    searchTerm: "",
    showProgressBar: false,
    progressBarValue: 0,
    results: [],
    search_type: "horse",
    fromDate: "",
    toDate: "",
    entityId: "",
    formResults: [],
    initialLoad: true, 
  }),
  emits: ["searchResults"],
  watch: {
    searchResults(searchTerm) {
      console.log("Emitting results (watch)");
      this.$emit("searchResults", searchTerm);
    },
    '$route.query.entity': {
  handler(newEntity, oldEntity) {
    if (!this.initialLoad && newEntity !== oldEntity) {
      this.formResults = [];
      this.fromDate = "";
      this.toDate = "";
      this.entityId = "";
    }
    this.initialLoad = false;
  },
  immediate: true
}
  },
  methods: {
    async searchEntity(searchTerm) {
      if (searchTerm == "") {
        alert("Please enter a search term.");
        return [];
      }

      this.showProgressBar = true;
      this.results = [];
      switch (this.$route.query.entity) {
        case "horses": {
          var horse_params = "s=";
          switch (this.search_type) {
            case "dam": {
              horse_params = "dam=true&s=";
              break;
            }
            case "sire": {
              horse_params = "sire=true&s=";
              break;
            }
            default:
              break;
          }
          await API.get(
            "MrCenApiGateway",
            `/admin/search/horse?${horse_params}${searchTerm}`
          ).then((response) => {
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        case "horseforms": {
          await API.get(
            "MrCenApiGateway",
            `/admin/search/horse-form?s=${searchTerm}`
          ).then(async (response) => {
            this.results = response;
            let flatHf = [];
            this.results.forEach((r) => {
              if (r.horse_id !== undefined && r.form !== undefined) {
                r.form.forEach((f) => {
                  f.horse_id = r.horse_id;
                  f.horse_name = r.horse_name;
                  f.form_count = r.form.length;
                  f.distance_metres = f.distance["@_metres"];
                  f.meeting_date = moment(f.meeting_date).format("YYYY-MM-DD");
                  if (f.track !== undefined) f.track_name = f.track["@_name"];
                  if (f.classes !== undefined) f.class_name = f.classes.class;
                  flatHf.push(f);
                });
              }
              // if (r.horse_name !== undefined)
              //   r.form.horse_name = r.horse_name;
            });
            console.log("HF Results");
            console.log(flatHf);

            this.$emit("searchResults", flatHf);
            this.showProgressBar = false;
          });
          break;
        }
        case "trainers": {
          await API.get(
            "MrCenApiGateway",
            `/admin/search/trainer?s=${searchTerm}`
          ).then(async (response) => {
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        case "jockeys": {
          await API.get(
            "MrCenApiGateway",
            `/admin/search/jockey?s=${searchTerm}`
          ).then(async (response) => {
            response.forEach((item) => {
              item.JOC_STATE_REGISTERED = this.getState(
                item.JOC_STATE_REGISTERED
              );
              item.JOC_JOCKEY_APPRENTICE_IND === "Y"
                ? (item.JOC_JOCKEY_APPRENTICE_IND = "Yes")
                : (item.JOC_JOCKEY_APPRENTICE_IND = "No");
            });
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        case "tracks": {
          await API.get(
            "MrCenApiGateway",
            `/admin/search/track?s=${searchTerm}`
          ).then(async (response) => {
            response.forEach((item) => {
              item.TRK_STATE_OF_TRACK = this.getState(item.TRK_STATE_OF_TRACK);
            });
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        case "clubs": {
          await API.get(
            "MrCenApiGateway",
            `/admin/search/club?s=${searchTerm}`
          ).then(async (response) => {
            response.forEach((item) => {
              item.CLB_STATE = this.getState(item.CLB_STATE);
            });
            this.results = response;
            this.$emit("searchResults", this.results);
            this.showProgressBar = false;
          });
          break;
        }
        default:
          break;
      }
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    getState(stateId) {
      switch (stateId) {
        case 0:
          return "";
        case 1:
          return "ACT";
        case 2:
          return "NSW";
        case 3:
          return "VIC";
        case 4:
          return "QLD";
        case 5:
          return "SA";
        case 6:
          return "WA";
        case 7:
          return "TAS";
        case 8:
          return "NT";
        case 9:
          return "NZ";
        case 10:
          return "HK";
        case 11:
          return "SGP";
        case 99:
          return "Aus";
      }
    },
    capitalize(str) {
      if (str !== undefined) return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async getEntityForm() {
      if (!this.entityId || !this.fromDate || !this.toDate) {
        alert("Please enter ID, from date, and to date.");
        return;
      }

      this.showProgressBar = true;
      this.formResults = [];
      const entityType = this.getEntityType;

      try {
        const response = await API.get(
          "MrCenApiGateway",
          "/admin/formIndex/search",
          {
            queryStringParameters: {
              fromDate: this.fromDate,
              toDate: this.toDate,
              [`${entityType}_id`]: this.entityId
            }
          }
        );

        if (Array.isArray(response)) {
          this.formResults = response.map(item => ({
            ...item,
            meeting_date: moment(item.meeting_date).format("YYYY-MM-DD")
          }));
          console.log("Form results:", this.formResults);
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      } finally {
        this.showProgressBar = false;
      }
    },
  },
  computed: {
    isSearchDisabled() {
      return this.searchTerm.trim().length === 0;
    },
    isGetFormDisabled() {
      return !this.fromDate || !this.toDate || !this.entityId;
    },
    getEntityLabel() {
      switch (this.$route.query.entity) {
        case 'jockeys':
          return 'Jockey ID';
        case 'trainers':
          return 'Trainer ID';
        case 'tracks':
          return 'Track ID';
        default:
          return 'Entity ID';
      }
    },

    getEntityType() {
      switch (this.$route.query.entity) {
        case 'jockeys':
          return 'jockey';
        case 'trainers':
          return 'trainer';
        case 'tracks':
          return 'track';
        default:
          return '';
      }
    },
  },
  mounted() {
    this.initialLoad = false;
  },
};
</script>

<style>
.form-results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.form-results-table th,
.form-results-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.form-results-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.form-results-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.form-results-table tr:hover {
  background-color: #f5f5f5;
}</style>
