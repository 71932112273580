<template>
  <Dialog
    v-model="editedItem"
    v-model:visible="isVisible"
    :style="{ width: '800px' }"
    header="Jockey Details"
    :modal="true"
    :closeOnEscape="true"
    @hide="closeForm()"
    class="p-fluid"
  >
    <div class="formgrid grid">
      <div class="field col">
        <label for="jocid">Jockey ID</label>
        <!-- <InputText id="jocid" v-model="editedItem.JOC_JOCKEY_ID" required="true" :disabled="true"/> -->
        <InputText
          id="jocid"
          v-model="editedItem.JOC_JOCKEY_ID"
          required="true"
          :class="{
            'invalid-field': !isJockeyIdValid(editedItem.JOC_JOCKEY_ID),
          }"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col-6">
        <label for="dname">Display Name</label>
        <InputText
          id="dname"
          v-model="editedItem.JOC_JOCKEY_DISPLAYNAME"
          required="true"
        />
      </div>
      <div class="field col">
        <label for="state">State</label>
        <Dropdown
          id="state"
          v-model="editedItem.JOC_STATE_REGISTERED"
          :options="state_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
      <div class="field col">
        <label for="country">Country</label>
        <AutoComplete
          v-model="editedItem.JOC_COUNTRY_CODE"
          id="country"
          :suggestions="this.filteredCountries"
          @complete="searchCountry($event)"
          :dropdown="true"
          field="label"
          autoHighlight
          forceSelection
        >
        </AutoComplete>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="sname">Surname</label>
        <InputText
          id="sname"
          v-model="editedItem.JOC_JOCKEY_SURNAME"
          required="true"
          :class="{
            'invalid-field': !isSurNameValid(editedItem.JOC_JOCKEY_SURNAME),
          }"
        />
      </div>

      <div class="field col">
        <label for="fname">First Name</label>
        <InputText
          id="fname"
          v-model="editedItem.JOC_JOCKEY_FIRSTNAME"
          required="true"
          :class="{
            'invalid-field': !isFirstNameValid(editedItem.JOC_JOCKEY_FIRSTNAME),
          }"
        />
      </div>
    </div>
    <div class="formgrid grid">
      
      <div class="field col">
        <label for="raname">RA Name</label>
        <InputText
          id="raname"
          v-model="editedItem.JOC_JOCKEY_RANAME"
          required="true"
        />
      </div>
      <div class="field col">
        <label for="nzname">NZ Name</label>
        <InputText
          id="nzname"
          v-model="editedItem.JOC_JOCKEY_NZNAME"
          required="true"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="apprentice">Apprentice Claim</label>
        <Dropdown
          id="apprentice"
          v-model="editedItem.JOC_JOCKEY_APPRENTICE_IND"
          :options="bool_choice"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
      <div class="field col">
        <label for="amateur">Amateur</label>
        <Dropdown
          id="amateur"
          v-model="editedItem.JOC_JOCKEY_AMATEUR_IND"
          :options="bool_choice"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
      <div class="field col">
        <label for="inuse">Active</label>
        <Dropdown
          id="inuse"
          v-model="editedItem.JOC_JOCKEY_IN_USE_IND"
          :options="bool_choice"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    </div>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeForm()"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        :disabled="!isFormValid || user_role > 4"
        @click="saveForm()"
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import { API } from "aws-amplify";
import AutoComplete from "primevue/autocomplete";
export default {
  components: {
    Dialog,
    Button,
    InputText,
    Dropdown,
    AutoComplete,
  },
  data: () => ({
    editedItem: {},
    isVisible: null,
    filteredCountries: null,
    selectedCountry: null,
    bool_choice: [
      { value: "", label: "" },
      { value: "Y", label: "Y" },
    ],
    state_options: [
      { label: "", value: 0 },
      { label: "ACT", value: 1 },
      { label: "NSW", value: 2 },
      { label: "VIC", value: 3 },
      { label: "QLD", value: 4 },
      { label: "SA", value: 5 },
      { label: "WA", value: 6 },
      { label: "TAS", value: 7 },
      { label: "NT", value: 8 },
      { label: "NZ", value: 9 },
      { label: "HK", value: 10 },
      { label: "SGP", value: 11 },
      { label: "AUS", value: 99 },
    ],
  }),
  props: {
    initialItem: {},
    visible: null,
    commonEntities: null,
    current_user: null,
    user_role: null
  },
  methods: {
    async saveForm() {
      this.trimFields()
      console.log(this.editedItem);
      if (typeof this.editedItem.JOC_COUNTRY_CODE === "object") {
        this.editedItem.JOC_COUNTRY_CODE = this.convertObject(
          this.editedItem.JOC_COUNTRY_CODE,
          "value"
        );
      }
      if (this.editedItem._id === "") {
        await API.post("MrCenApiGateway", `/jockey`, {
          body: this.editedItem,
        }).then(() => {
          this.$toast.add({
            severity: "success",
            summary: `the jockey ${this.editedItem.JOC_JOCKEY_DISPLAYNAME} was created`,
            life: 5000,
          });
        });
      } else {
        await API.put("MrCenApiGateway", `/jockey`, {
          body: this.editedItem,
        }).then(() => {
          this.$toast.add({
            severity: "success",
            summary: `the jockey ${this.editedItem.JOC_JOCKEY_DISPLAYNAME} was updated`,
            life: 5000,
          });
        });
      }
      this.closeForm();
    },
    closeForm() {
      this.isVisible = false;
      this.$emit("visible", false);
    },
    searchCountry(event) {
      console.log("Searching for country:", event.query);
      this.filteredCountries = this.commonEntities.allCountries.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
      console.log("Filtered countries:", this.filteredCountries);
    },
    
    isSurNameValid(surName) {
      return surName && /^[A-Za-z\s-']+$/.test(surName.trim());
    },
    isFirstNameValid(firstName) {
      return firstName && /^[A-Za-z\s-']+$/.test(firstName.trim());
    },
    isJockeyIdValid(jockeyId) {
      const num = Number(jockeyId);
      return jockeyId && !isNaN(num) && num > 0;
    },
    convertObject(object, key) {
      return object[key];
    },
    trimFields(){
      for (const key in this.editedItem) if (typeof this.editedItem[key] === 'string' || this.editedItem[key] instanceof String) this.editedItem[key] = this.editedItem[key].trim()
    }
  },
  watch: {
    initialItem(val) {
      this.editedItem = val;
      if (!this.editedItem._id) {
        this.editedItem = {
          JOC_COUNTRY_CODE: "",
          JOC_JOCKEY_AMATEUR_IND: "",
          JOC_JOCKEY_APPRENTICE_IND: "",
          JOC_JOCKEY_DISPLAYNAME: "",
          JOC_JOCKEY_DISTRICT: null,
          JOC_JOCKEY_FIRSTNAME: "",
          JOC_JOCKEY_RANAME: "",
          JOC_JOCKEY_NZNAME: "",
          JOC_JOCKEY_ID: 0,
          JOC_JOCKEY_IN_USE_IND: "Y",
          JOC_JOCKEY_SURNAME: "",
          JOC_JOCKEY_WEIGHT: null,
          JOC_ROW_STATUS: "A",
          JOC_STATE_REGISTERED: 0,
          _id: "",
        };
      }
      console.log(this.editedItem);
    },
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      this.$emit("visible", val);
    },
  },
  computed: {
    isFormValid() {
      return (
        this.editedItem.JOC_JOCKEY_ID &&
        this.isJockeyIdValid(this.editedItem.JOC_JOCKEY_ID) &&
        this.editedItem.JOC_JOCKEY_SURNAME &&
        this.isSurNameValid(this.editedItem.JOC_JOCKEY_SURNAME) &&
        this.editedItem.JOC_JOCKEY_FIRSTNAME &&
        this.isFirstNameValid(this.editedItem.JOC_JOCKEY_FIRSTNAME) &&
        true
      );
    },
  },
  mounted() {},
};
</script>

<style scoped>
#checkboxes {
  display: inline-block;
  margin-right: 5px;
}
#checkboxes label {
  display: inline-block;
}

.invalid-field {
  border-color: red;
  background-color: #ffe6e6;
}
</style>
