<template>
  <Dialog
    v-model="errorSummary"
    v-model:visible="showErrorDialog"
    :style="{ width: '600px' }"
    header="Can't save form"
    :modal="true"
    class="p-fluid"
    :closeable="false"
    @hide="errorSummary = null"
  >
    <div>
      {{ errorSummary }}
    </div>

    <template #footer>
      <Button
        label="Close"
        icon="pi pi-times"
        class="p-button-text"
        @click="
          errorSummary = null;
          showErrorDialog = false;
        "
      />
    </template>
  </Dialog>
  <Dialog
    v-model="editedItem"
    v-model:visible="isVisible"
    :style="{ width: '1050px' }"
    header="Horse Form Details"
    :modal="true"
    :closeOnEscape="true"
    @hide="closeForm()"
    class="p-fluid"
  >
    <div class="formgrid grid">
      <div class="field col">
        <label for="horseid">HorseID</label>
        <InputNumber
          id="horseid"
          v-model="editedItem.horse_id"
          :useGrouping="false"
          :class="{
            'invalid-field': !isHorseIDValidComputed,
          }"
        />
      </div>
      <div class="field col">
        <label for="meetingdate">Meeting Date</label>
        <Calendar
          id="meetingdate"
          v-model="editedItem.meeting_date"
          dateFormat="yy-mm-dd"
          :showIcon="true"
        />
      </div>
      <div class="field col">
        <label for="meetingtrack">Track</label>
        <AutoComplete
          v-model="editedItem.track['@_name']"
          id="meetingtrack"
          :suggestions="this.filteredTracks"
          @complete="searchTrack($event)"
          :dropdown="true"
          field="label"
          autoHighlight
          forceSelection
        >
        </AutoComplete>
      </div>
      <div class="field col">
        <label for="eventid">EventID</label>
        <InputNumber
          id="eventid"
          v-model="editedItem.event_id"
          :useGrouping="false"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col-2">
        <label for="racenumber">Race Number</label>
        <InputNumber id="racenumber" v-model="editedItem.race['@_number']" />
      </div>
      <div class="field col">
        <label for="racename">Race Name</label>
        <InputText id="racename" v-model="editedItem.race['@_name']" />
      </div>
      <div class="field col">
        <Button
          label="Run Script"
          icon="pi pi-play"
          class="p-button-text"
          @click="runScript(true)"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="raceclass">Class</label>
        <AutoComplete
          v-model="editedItem.classes.class"
          id="raceclass"
          :suggestions="this.filteredClasses"
          @complete="searchClass($event)"
          :dropdown="true"
          field="label"
          autoHighlight
          forceSelection
          >{{ this.getClassById(editedItem.classes.class_id) }}
        </AutoComplete>
      </div>
      <div class="field col">
        <label for="raceclass2">Secondary Class</label>
        <AutoComplete
          v-model="editedItem.classes.second_class"
          id="raceclass2"
          :suggestions="this.filteredClasses"
          @complete="searchClass($event)"
          :dropdown="true"
          field="label"
          autoHighlight
          forceSelection
          >{{ this.getClassById(editedItem.classes.second_class_id) }}
        </AutoComplete>
      </div>

      <div class="field col">
        <label for="raceclass3">Tertiary Class</label>
        <AutoComplete
          v-model="editedItem.classes.third_class"
          id="raceclass3"
          :suggestions="this.filteredClasses"
          @complete="searchClass($event)"
          :dropdown="true"
          field="label"
          autoHighlight
          forceSelection
          >{{ this.getClassById(editedItem.classes.third_class_id) }}
        </AutoComplete>
      </div>
      <!-- <div class="field col">
        
        <label for="raceclass">Class</label>
        <AutoComplete v-model="editedItem.classes.class" id="raceclass" 
                      :suggestions="this.filteredClasses" @complete="searchClass($event)"
                      :dropdown="true" field="label" autoHighlight forceSelection>{{ this.getClassById(editedItem.classes.class_id) }}
        </AutoComplete>
      </div> -->
    </div>
    <div class="formgrid grid">
      <div class="field col-2">
        <label for="trackcond">Track Condition</label>
        <Dropdown
          id="trackcond"
          v-model="editedItem.track['@_condition']"
          :options="condition_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
      <div class="field col-1">
        <label for="trackgrad">Grading</label>
        <Dropdown
          id="trackgrad"
          v-model="editedItem.track['@_grading']"
          :options="grading_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
      <div class="field col">
        <label for="racedist">Distance</label>
        <InputNumber
          id="racedist"
          v-model="editedItem.distance['@_metres']"
          :useGrouping="false"
        />
      </div>

      <div class="field col">
        <label for="racegroup">Group</label>
        <Dropdown
          id="racegroup"
          v-model="editedItem.group"
          :options="group_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
      <div class="field col">
        <label for="raceweighttype">Weight type</label>
        <Dropdown
          id="raceweighttype"
          v-model="editedItem.weight_type"
          :options="weight_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="raceagerest">Age Restriction</label>
        <Dropdown
          id="raceagerest"
          v-model="editedItem.restrictions['@_age']"
          :options="age_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
      <div class="field col">
        <label for="racesexrest">Sex Restriction</label>
        <Dropdown
          id="racesexrest"
          v-model="editedItem.restrictions['@_sex']"
          :options="sex_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
      <div class="field col">
        <label for="racejockeyrest">Jockey Restriction</label>
        <Dropdown
          id="racejockeyrest"
          v-model="editedItem.restrictions['@_jockey']"
          :options="jockey_options"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="duration">Duration</label>
        <InputText id="duration" v-model.trim="editedItem.event_duration" />
      </div>
      <div class="field col">
        <label for="margin1">Official margin 1</label>
        <AutoComplete
          v-model="editedItem.official_margin_1"
          id="margin1"
          :suggestions="filteredMargins"
          @complete="searchMargin"
          :dropdown="true"
          autoHighlight
          field="label"
          :invalid="offMarginIsBad(editedItem.official_margin_1)"
        />
      </div>
      <div class="field col">
        <label for="margin2">Official margin 2</label>
        <AutoComplete
          v-model="editedItem.official_margin_2"
          id="margin2"
          :suggestions="filteredMargins"
          @complete="searchMargin"
          :dropdown="true"
          autoHighlight
          field="label"
          :invalid="offMarginIsBad(editedItem.official_margin_2)"
        />
      </div>
      <div class="field col">
        <label for="raceprize">Race Prizemoney</label>
        <InputNumber id="raceprize" v-model="editedItem.event_prizemoney" />
      </div>
      <div class="field col">
        <label for="secttime">Sectional Time</label>
        <InputText id="secttime" v-model="editedItem.sectional['@_time']" />
      </div>
      <div class="field col">
        <label for="sectdist">Sectional Distance</label>
        <InputNumber
          id="sectdist"
          v-model="editedItem.sectional['@_distance']"
        />
      </div>
      <div class="field col">
        <label for="sectloca">Sectional Location</label>
        <InputText
          id="sectloca"
          v-model.trim="editedItem.sectional['@_location']"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="trainer">Trainer</label>
        <AutoComplete
          id="trainer"
          v-model="editedItem.trainer_id"
          :dropdown="true"
          :suggestions="filteredTrainers"
          field="label"
          @complete="searchTrainer"
          >{{ this.getTrainerById(editedItem.HOR_TRAINER_ID) }}
        </AutoComplete>
      </div>
      <div class="field col">
        <label for="jockey">Jockey</label>
        <AutoComplete
          id="jockey"
          v-model="editedItem.jockey['@_id']"
          :dropdown="true"
          :suggestions="filteredJockeys"
          field="label"
          @complete="searchJockey"
          >{{ this.getJockeyById(editedItem.jockey["@_id"]) }}
        </AutoComplete>
      </div>
      <div class="field col">
        <label for="horsebarrier">Barrier</label>
        <InputNumber id="horsebarrier" v-model="editedItem.barrier" />
      </div>
      <div class="field col">
        <label for="weightcarried">Weight Carried</label>
        <InputNumber
          id="weightcarried"
          :minFractionDigits="1"
          v-model="editedItem.weight_carried"
          mode="decimal"
          :maxFractionDigits="1"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="finishposition">Position</label>
        <Dropdown
          id="finishposition"
          v-model="editedItem.finish_position"
          :options="positionOptions"
          optionLabel="label"
          optionValue="value"
        />
      </div>
      <div class="field col">
        <label for="margin">Margin</label>
        <InputText id="margin" v-model="editedItem.margin" />
      </div>
      <div class="field col">
        <label for="beatenmargin">Beaten Margin</label>
        <InputText id="beatenmargin" v-model="editedItem.beaten_margin" />
      </div>
      <div class="field col-3">
        <label for="horseprizemoney">Horse Prizemoney</label>
        <InputNumber
          id="horseprizemoney"
          v-model="editedItem.horse_prizemoney"
        />
      </div>
      <div class="field col-3">
        <label for="horseprizemoneybonus">Horse Bonus</label>
        <InputNumber
          id="horseprizemoneybonus"
          v-model="editedItem.horse_prizemoney_bonus"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="racestarters">Starters</label>
        <InputNumber id="racestarters" v-model="editedItem.starters" />
      </div>
      <div class="field col">
        <label for="settlingpostiton">Settling down</label>
        <InputNumber
          id="settlingpostiton"
          v-model="editedItem.positions['@_settling_down']"
        />
      </div>
      <div class="field col">
        <label for="1200postiton">1200m</label>
        <InputNumber
          id="1200postiton"
          v-model="editedItem.positions['@_m1200']"
        />
      </div>
      <div class="field col">
        <label for="800postiton">800m</label>
        <InputNumber
          id="800postiton"
          v-model="editedItem.positions['@_m800']"
        />
      </div>
      <div class="field col">
        <label for="400postiton">400m</label>
        <InputNumber
          id="400postiton"
          v-model="editedItem.positions['@_m400']"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="openprice">Opening price</label>
        <InputText
          id="openprice"
          v-model="editedItem.decimalprices['@_opening']"
        />
      </div>
      <div class="field col">
        <label for="midprice">Mid price</label>
        <InputText id="midprice" v-model="editedItem.decimalprices['@_mid']" />
      </div>
      <div class="field col">
        <label for="startprice">Starting price</label>
        <InputText
          id="startprice"
          v-model.trim="editedItem.decimalprices['@_starting']"
        />
      </div>
      <div class="field col">
        <label for="favouriteind">Favourite</label>
        <Dropdown
          id="favouriteind"
          v-model="editedItem.favourite_indicator"
          :options="favouriteOptions"
          optionLabel="label"
          optionValue="value"
        ></Dropdown>
      </div>
    </div>

    <div class="formgrid grid">
      <div class="field col">
        <label for="gear_changes">Gear Changes</label>
        <InputText id="gear_changes" v-model.trim="editedItem.gear" />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="running_gear">Running Gear</label>
        <InputText id="running_gear" v-model.trim="editedItem.ran_gear" />
      </div>
    </div>

    <div class="formgrid grid">
      <div class="field col">
        <label for="stewards1">Stewards report 1</label>
        <InputText
          id="stewards1"
          v-model.trim="editedItem.stewards_report[0]"
        />
      </div>
      <div class="field col">
        <label for="stewards2">Stewards report 2</label>
        <InputText
          id="stewards2"
          v-model.trim="editedItem.stewards_report[1]"
        />
      </div>
      <div class="field col">
        <label for="stewards3">Stewards report 3</label>
        <InputText
          id="stewards3"
          v-model.trim="editedItem.stewards_report[2]"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <i>Stewards reports should be codes seperated by spaces</i>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col">Other runners</div>
      <hr />
    </div>
    <div class="formgrid grid">
      <div class="field col">
        <label for="other1pos">position 1</label>
        <InputNumber
          id="other1pos"
          v-model="editedItem.other_runners.other_runner[0]['@_position']"
        />
      </div>
      <div class="field col-3">
        <label for="other1name">horse name 1</label>
        <InputText
          id="other1name"
          v-model="editedItem.other_runners.other_runner[0]['@_horse']"
        />
      </div>
      <div class="field col">
        <label for="other1country">country 1</label>
        <InputText
          id="other1country"
          v-model="editedItem.other_runners.other_runner[0]['@_country']"
        />
      </div>
      <div class="field col-3">
        <label for="other1joc">jockey 1</label>
        <AutoComplete
          id="other1joc"
          v-model="editedItem.other_runners.other_runner[0]['@_jockey']"
          :dropdown="true"
          :suggestions="filteredJockeys"
          field="label"
          value="label"
          @complete="searchJockey"
        />
      </div>
      <div class="field col">
        <label for="other1weight">weight 1</label>
        <InputNumber
          id="other1weight"
          :minFractionDigits="1"
          v-model="editedItem.other_runners.other_runner[0]['@_weight']"
        />
      </div>
      <div class="field col">
        <label for="other1margin">margin 1</label>
        <InputText
          id="other1margin"
          v-model="editedItem.other_runners.other_runner[0]['@_margin']"
        />
      </div>
      <div class="field col">
        <label for="other1barrier">barrier 1</label>
        <InputText
          id="other1barrier"
          v-model="editedItem.other_runners.other_runner[0]['@_barrier']"
        />
      </div>
    </div>
    <div class="formgrid grid" v-if="editedItem.other_runners.other_runner[1]">
      <div class="field col">
        <label for="other2pos">position 2</label>
        <InputNumber
          id="other2pos"
          v-model="editedItem.other_runners.other_runner[1]['@_position']"
        />
      </div>
      <div class="field col-3">
        <label for="other2name">horse name 2</label>
        <InputText
          id="other2name"
          v-model="editedItem.other_runners.other_runner[1]['@_horse']"
        />
      </div>
      <div class="field col">
        <label for="other2country">country 2</label>
        <InputText
          id="other2country"
          v-model="editedItem.other_runners.other_runner[1]['@_country']"
        />
      </div>
      <div class="field col-3">
        <label for="other2joc">jockey 2</label>
        <AutoComplete
          id="other2joc"
          v-model="editedItem.other_runners.other_runner[1]['@_jockey']"
          :dropdown="true"
          :suggestions="filteredJockeys"
          field="label"
          value="label"
          @complete="searchJockey"
        />
      </div>
      <div class="field col">
        <label for="other2weight">weight 2</label>
        <InputNumber
          id="other2weight"
          :minFractionDigits="1"
          v-model="editedItem.other_runners.other_runner[1]['@_weight']"
        />
      </div>
      <div class="field col">
        <label for="other2margin">margin 2</label>
        <InputText
          id="other2margin"
          v-model="editedItem.other_runners.other_runner[1]['@_margin']"
        />
      </div>
      <div class="field col">
        <label for="other2barrier">barrier 2</label>
        <InputText
          id="other2barrier"
          v-model="editedItem.other_runners.other_runner[1]['@_barrier']"
        />
      </div>
    </div>
    <div class="formgrid grid" v-if="editedItem.other_runners.other_runner[2]">
      <div class="field col">
        <label for="other3pos">position 3</label>
        <InputNumber
          id="other3pos"
          v-model="editedItem.other_runners.other_runner[2]['@_position']"
        />
      </div>
      <div class="field col-3">
        <label for="other3name">horse name 3</label>
        <InputText
          id="other3name"
          v-model="editedItem.other_runners.other_runner[2]['@_horse']"
        />
      </div>
      <div class="field col">
        <label for="other3country">country 3</label>
        <InputText
          id="other3country"
          v-model="editedItem.other_runners.other_runner[2]['@_country']"
        />
      </div>
      <div class="field col-3">
        <label for="other3joc">jockey 3</label>
        <AutoComplete
          id="other3joc"
          v-model="editedItem.other_runners.other_runner[2]['@_jockey']"
          :dropdown="true"
          :suggestions="filteredJockeys"
          field="label"
          value="label"
          @complete="searchJockey"
        />
      </div>
      <div class="field col">
        <label for="other3weight">weight 3</label>
        <InputNumber
          id="other3weight"
          :minFractionDigits="1"
          v-model="editedItem.other_runners.other_runner[2]['@_weight']"
        />
      </div>
      <div class="field col">
        <label for="other3margin">margin 3</label>
        <InputText
          id="other3margin"
          v-model="editedItem.other_runners.other_runner[2]['@_margin']"
        />
      </div>
      <div class="field col">
        <label for="other3barrier">barrier 3</label>
        <InputText
          id="other3barrier"
          v-model="editedItem.other_runners.other_runner[2]['@_barrier']"
        />
      </div>
    </div>

    <div class="formgrid grid" v-if="editedItem.other_runners.other_runner[3]">
      <div class="field col">
        <label for="other4pos">position 4</label>
        <InputNumber
          id="other4pos"
          v-model="editedItem.other_runners.other_runner[3]['@_position']"
        />
      </div>
      <div class="field col-3">
        <label for="other4name">horse name 4</label>
        <InputText
          id="other4name"
          v-model="editedItem.other_runners.other_runner[3]['@_horse']"
        />
      </div>
      <div class="field col">
        <label for="other4country">country 4</label>
        <InputText
          id="other4country"
          v-model="editedItem.other_runners.other_runner[3]['@_country']"
        />
      </div>
      <div class="field col-3">
        <label for="other4joc">jockey 4</label>
        <AutoComplete
          id="other4joc"
          v-model="editedItem.other_runners.other_runner[3]['@_jockey']"
          :dropdown="true"
          :suggestions="filteredJockeys"
          field="label"
          value="label"
          @complete="searchJockey"
        />
      </div>
      <div class="field col">
        <label for="other4weight">weight 4</label>
        <InputNumber
          id="other4weight"
          :minFractionDigits="1"
          v-model="editedItem.other_runners.other_runner[3]['@_weight']"
        />
      </div>
      <div class="field col">
        <label for="other4margin">margin 4</label>
        <InputText
          id="other4margin"
          v-model="editedItem.other_runners.other_runner[3]['@_margin']"
        />
      </div>
      <div class="field col">
        <label for="other4barrier">barrier 4</label>
        <InputText
          id="other3barrier"
          v-model="editedItem.other_runners.other_runner[3]['@_barrier']"
        />
      </div>
    </div>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeForm()"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        :disabled="!this.isFormValid || this.isSaving || this.user_role > 4"
        @click="validateAndSave()"
      />
    </template>
  </Dialog>
  <!-- Popup Dialog for displaying script results -->
  <Dialog
    v-model="showResultsDialog"
    :visible="showResultsDialog"
    :style="{ width: '800px' }"
    header="Results"
    :modal="true"
    class="p-fluid"
    :closeable="true"
    @hide="showResultsDialog = false"
  >
    <div v-if="results.length > 0">
      <DataTable :value="results" scrollable scrollHeight="200px">
        <Column field="horse_name" header="Horse Name"></Column>
        <Column field="horse_id" header="Horse ID"></Column>
        <Column field="race_number" header="Race Number">
          <template #body="slotProps">
            <span :class="{ 'duplicate-race': slotProps.data.isDuplicate }">
              {{ slotProps.data.race_number }}
            </span>
          </template></Column
        >
        <Column field="race_name" header="Race Name"></Column>
        <Column field="finish_position" header="Finish Position">
          <template #body="slotProps">
            <span :class="{ 'duplicate-race': slotProps.data.isDuplicate }">
              {{ slotProps.data.finish_position }}
            </span>
          </template></Column
        >
      </DataTable>
    </div>
    <template #footer>
      <Button
        label="Close"
        icon="pi pi-times"
        class="p-button-text"
        @click="showResultsDialog = false"
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import DataTable from "primevue/datatable";
import Calendar from "primevue/calendar";
import Column from "primevue/column";
import AutoComplete from "primevue/autocomplete";
import Dropdown from "primevue/dropdown";
import { API } from "aws-amplify";
import moment from "moment";

export default {
  emits: ["visible"],
  components: {
    Dialog,
    Button,
    InputText,
    InputNumber,
    Calendar,
    AutoComplete,
    Dropdown,
    DataTable,
    Column,
  },
  data: () => ({
    isSaving: false,
    positionOptions: [],
    editedItem: {},
    isVisible: null,
    filteredCountries: null,
    filteredClasses: null,
    filteredTracks: null,
    filteredHorses: null,
    filteredTrainers: null,
    filteredJockeys: null,
    filteredMargins: null,
    selectedCountry: null,
    errorSummary: null,
    showErrorDialog: false,
    showResultsDialog: false,
    results: [],
    group_options: [
      { value: "", label: "" },
      { value: 1, label: "Group 1" },
      { value: 2, label: "Group 2" },
      { value: 3, label: "Group 3" },
      { value: "LR", label: "Listed Race" },
    ],
    sex_options: [
      { value: 0, label: "" },
      { value: 1, label: "Fillies" },
      { value: 2, label: "Mares" },
      { value: 3, label: "Colts" },
      { value: 4, label: "Geldings" },
      { value: 5, label: "Horses" },
      { value: 6, label: "Fillies & Mares" },
      { value: 7, label: "Colts & Horses" },
      { value: 8, label: "Colts & Geldings" },
      { value: 9, label: "Horses & Geldings" },
      { value: 10, label: "Horses & Mares" },
      { value: 11, label: "Colts & Fillies" },
      { value: 12, label: "Colts, Horses & Geldings" },
      { value: 13, label: "Fillies, Colts & Geldings" },
      { value: 14, label: "Mares, Horses & Geldings" },
      { value: 15, label: "Fillies, Mares, Colts & Horses" },
      { value: 16, label: "Fillies, Mares, Colts & Geldings" },
    ],
    age_options: [
      { value: 0, label: "" },
      { value: 1, label: "2yo" },
      { value: 2, label: "2yo+" },
      { value: 3, label: "2,3,4yo" },
      { value: 4, label: "3yo" },
      { value: 5, label: "3yo+" },
      { value: 6, label: "4yo" },
      { value: 7, label: "4yo+" },
      { value: 8, label: "5yo" },
      { value: 9, label: "5yo+" },
      { value: 10, label: "6yo" },
      { value: 11, label: "Aged" },
      { value: 12, label: "2,3yo" },
      { value: 13, label: "3,4yo" },
      { value: 14, label: "3,4,5yo" },
      { value: 15, label: "4,5yo" },
      { value: 16, label: "4,5,6yo" },
      { value: 17, label: "3,4,5,6" },
    ],
    jockey_options: [
      { value: "C", label: "Apprentices Can Claim" },
      { value: "A", label: "Apprentice Riders Only" },
      { value: "E", label: "Eligible Riders Can Claim" },
      { value: "G", label: "Gentlemen Riders Only" },
      { value: "I", label: "Invited Riders" },
      { value: "L", label: "Lady Riders Only" },
      { value: "N", label: "Apprentices Cannot Claim" },
      { value: "X", label: "Eligible Riders Cannot Claim" },
      { value: "M", label: "Amateur Riders" },
      { value: "H", label: "Hurdle Jockeys Only" },
    ],
    weight_options: [
      { value: "H", label: "Handicap" },
      { value: "W", label: "Weight For Age" },
      { value: "X", label: "Weight For Age With Penalties" },
      { value: "S", label: "Set Weight" },
      { value: "T", label: "Set Weight With Penalties" },
      { value: "U", label: "Set Weight With Penalties and Allowances" },
      { value: "P", label: "Special Weight" },
      { value: "C", label: "Catch Weight" },
      { value: "F", label: "Fly Weight" },
      { value: "A", label: "Set Weight With Allowances" },
      { value: "Q", label: "Quality" },
    ],
    condition_options: [
      { value: "Y", label: "Synthetic" },
      { value: "M", label: "Firm" },
      { value: "G", label: "Good" },
      { value: "O", label: "Soft" },
      { value: "H", label: "Heavy" },
      { value: "Fast", label: "Fast" },
      { value: "Dirt", label: "Dirt" },
      { value: "Sand", label: "Sand" },
      { value: "Dead", label: "Dead" },
      { value: "Slow", label: "Slow" },
      { value: "Wet", label: "Wet" },
      { value: "F", label: "Fast - old" },
      { value: "S", label: "Slow - old" },
      { value: "R", label: "Fair - old" },
      { value: "D", label: "Dead - old" },
      { value: "A", label: "Sand - old" },
      { value: "L", label: "Holding - old" },
      { value: "V", label: "Very Heavy - old" },
    ],
    grading_options: [
      { value: "0", label: "0" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "10", label: "10" },
      { value: "11", label: "11" },
    ],
    favouriteOptions: [
      { label: "E", value: "E" },
      { label: "F", value: "F" },
    ],
    official_margins: [
      { value: "DH", label: "DH" },
      { value: "NS", label: "NS" },
      { value: "SHH", label: "SHH" },
      { value: "HH", label: "HH" },
      { value: "SH", label: "SH" },
      { value: "HD", label: "HD" },
      { value: "LH", label: "LH" },
      { value: "HN", label: "HN" },
      { value: "SN", label: "SN" },
      { value: "NK", label: "NK" },
      { value: "LN", label: "LN" },
    ],
  }),
  props: {
    initialItem: {},
    visible: null,
    commonEntities: null,
    current_user: null,
    user_role: null,
  },
  methods: {
    isHorseIDValid(horseId) {
      // Add your validation conditions here
      return (
        horseId !== null &&
        horseId !== undefined &&
        !isNaN(horseId) &&
        horseId > 0
      );
    },
    async runScript(create = false) {
      console.log("runScript", create);
      try {
        let meetingDate = this.editedItem.meeting_date;
        const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateFormatRegex.test(meetingDate)) {
          meetingDate = moment(meetingDate).format("YYYY-MM-DD");
        }
        let trackId = this.editedItem.track["@_id"];
        console.log("trackId", trackId);
        console.log(meetingDate, trackId);
        if (!trackId) {
          console.log("no track id");
          console.log(this.editedItem.track["@_name"].value);
          const responseTrack = await API.get("MrCenApiGateway", `/track`, {
            queryStringParameters: {
              track_id: this.editedItem.track["@_name"].value,
            },
          });
          if (
            responseTrack &&
            responseTrack.data &&
            responseTrack.data.TRK_COUNTRY_OF_TRACK
          ) {
            this.editedItem.track["@_country"] =
              responseTrack.data.TRK_COUNTRY_OF_TRACK;
          } else {
            this.errorSummary =
              "Please select track Or the track you selected does not have a country in DB";
            this.showErrorDialog = true;
            return;
          }
        }
        // API call to get all items that share meeting_date and track["@_id"]
        const response = await API.get("MrCenApiGateway", `/formIndex`, {
          queryStringParameters: {
            meeting_date: meetingDate,
            track_id: trackId,
          },
        });

        this.results = response.data;
        console.log(this.results);
        await this.highlightDuplicates();
        const hasDuplicates = this.results.some((result) => result.isDuplicate);
        if (this.results.length === 0 && create) {
          console.log(this.results.length);
          this.errorSummary = "No races found for the given date and track.";
          this.showErrorDialog = true;
        }
        if (
          (this.results.length > 0 && create) ||
          (this.results.length > 0 && hasDuplicates)
        ) {
          this.results.sort((a, b) => a.race_number - b.race_number);
          this.showResultsDialog = true;
        }

        return hasDuplicates;
      } catch (error) {
        console.error("Error running script:", error);
        this.errorSummary = "Failed to run script.";
        this.showErrorDialog = true;
      }
    },
    async highlightDuplicates() {
      console.log("highlightDuplicates");
      const raceMap = {};
      const editedRaceNumber = this.editedItem.race["@_number"];
      console.log(`editedRaceNumber`);
      console.log(this.editedItem);
      this.results.forEach((result) => {
        const key = result.race_number;
        if (raceMap[key]) {
          raceMap[key].isDuplicate = true;
          result.isDuplicate = true;
        } else {
          raceMap[key] = result;
          result.isDuplicate = false;
        }

        // Check if the race number from editedItem exists in the results
        if (key === editedRaceNumber) {
          result.isDuplicate = true;
        }
      });
    },
    async validateAndSave() {
      let meetingDate = moment(this.editedItem.meeting_date);

      // Set the time to the start of the day (midnight) in local time
      meetingDate.startOf("day");

      // Convert to UTC while preserving the correct date
      let utcMeetingDate = moment.utc(
        meetingDate.format("YYYY-MM-DDTHH:mm:ss.SSS")
      );

      // Format the adjusted date to include the `Z` timezone offset
      let formattedMeetingDate = utcMeetingDate.format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      this.editedItem.meeting_date = formattedMeetingDate;

      console.log(this.editedItem.meeting_date);

      if (typeof this.editedItem.trainer_id === "object") {
        this.editedItem.trainer_id = this.convertObject(
          this.editedItem.trainer_id,
          "value"
        );
      }
      if (typeof this.editedItem.jockey["@_id"] === "object") {
        this.editedItem.jockey["@_id"] = this.convertObject(
          this.editedItem.jockey["@_id"],
          "value"
        );
      }

      if (typeof this.editedItem.track["@_name"] === "object") {
        this.editedItem.track["@_id"] = this.convertObject(
          this.editedItem.track["@_name"],
          "value"
        );
        this.editedItem.track["@_name"] = this.convertObject(
          this.editedItem.track["@_name"],
          "label"
        );
        const selectedTrack = this.commonEntities.allTracks.find(
          (track) => track.value === this.editedItem.track["@_id"]
        );
        if (selectedTrack) {
          this.editedItem.track["@_country"] = selectedTrack.country;
        }
      }
      if (typeof this.editedItem.official_margin_1 === "object") {
        this.editedItem.official_margin_1 = this.convertObject(
          this.editedItem.official_margin_1,
          "value"
        );
      }
      if (typeof this.editedItem.official_margin_2 === "object") {
        this.editedItem.official_margin_2 = this.convertObject(
          this.editedItem.official_margin_2,
          "value"
        );
      }

      if (typeof this.editedItem.classes.class === "object") {
        this.editedItem.classes.class_id = this.editedItem.classes.class.value;
        this.editedItem.classes.class = this.editedItem.classes.class["label"];
      }
      if (
        this.editedItem.classes.second_class &&
        typeof this.editedItem.classes.second_class === "object"
      ) {
        this.editedItem.classes.second_class_id =
          this.editedItem.classes.second_class.value;
        this.editedItem.classes.second_class =
          this.editedItem.classes.second_class["label"];
      }
      if (
        this.editedItem.classes.third_class &&
        typeof this.editedItem.classes.third_class === "object"
      ) {
        this.editedItem.classes.third_class_id =
          this.editedItem.classes.third_class.value;
        this.editedItem.classes.third_class =
          this.editedItem.classes.third_class["label"];
      }

      if (
        this.editedItem.other_runners.other_runner[0] &&
        typeof this.editedItem.other_runners.other_runner[0]["@_jockey"] ===
          "object"
      ) {
        // this.editedItem.other_runners.other_runner[0]['@_jockey'] = this.editedItem.other_runners.other_runner[0]['@_jockey']['label']
        this.editedItem.other_runners.other_runner[0]["@_jockey"] =
          this.convertObject(
            this.editedItem.other_runners.other_runner[0]["@_jockey"],
            "label"
          );
      }
      if (
        this.editedItem.other_runners.other_runner[1] &&
        typeof this.editedItem.other_runners.other_runner[1]["@_jockey"] ===
          "object"
      ) {
        // this.editedItem.other_runners.other_runner[1]['@_jockey'] = this.editedItem.other_runners.other_runner[1]['@_jockey']['label']
        this.editedItem.other_runners.other_runner[1]["@_jockey"] =
          this.convertObject(
            this.editedItem.other_runners.other_runner[1]["@_jockey"],
            "label"
          );
      }
      if (
        this.editedItem.other_runners.other_runner[2] &&
        typeof this.editedItem.other_runners.other_runner[2]["@_jockey"] ===
          "object"
      ) {
        //this.editedItem.other_runners.other_runner[2]['@_jockey'] = this.editedItem.other_runners.other_runner[2]['@_jockey']['label']
        this.editedItem.other_runners.other_runner[2]["@_jockey"] =
          this.convertObject(
            this.editedItem.other_runners.other_runner[2]["@_jockey"],
            "label"
          );
      }
      if (
        this.editedItem.other_runners.other_runner[3] &&
        typeof this.editedItem.other_runners.other_runner[3]["@_jockey"] ===
          "object"
      ) {
        // this.editedItem.other_runners.other_runner[3]['@_jockey'] = this.editedItem.other_runners.other_runner[3]['@_jockey']['label']
        this.editedItem.other_runners.other_runner[3]["@_jockey"] =
          this.convertObject(
            this.editedItem.other_runners.other_runner[3]["@_jockey"],
            "label"
          );
      }

      console.log(this.editedItem);

      if (!this.editedItem.event_id || this.editedItem.event_id == "0") {
        var themeetdate = new Date(this.editedItem.meeting_date);
        var dd = moment(this.editedItem.meeting_date).format("DDD").toString();
        if (parseInt(dd) < 10) {
          dd = "00" + dd;
        } else if (parseInt(dd) < 100) {
          dd = "0" + dd;
        }
        var stripmeetdate = themeetdate.getFullYear().toString().slice(2) + dd;
        var track_no = parseInt(this.editedItem.track["@_id"]);
        if (
          this.editedItem.classes.class_id === 90 ||
          (this.editedItem.classes.second_class_id &&
            this.editedItem.classes.second_class_id === 90) ||
          (this.editedItem.classes.third_class_id &&
            this.editedItem.classes.third_class_id === 90)
        )
          track_no = track_no + 5000;
        if (track_no < 1000) {
          track_no = "0" + track_no.toString();
        } else {
          track_no = track_no.toString();
        }
        var makenewID =
          stripmeetdate +
          (this.editedItem.race["@_number"] < 10
            ? "0" + this.editedItem.race["@_number"].toString()
            : this.editedItem.race["@_number"].toString()) +
          track_no;
        // alert(`You need to enter an event ID, suggested ID: ${makenewID}`)
        this.errorSummary = `You need to enter an event ID, suggested ID: ${makenewID}`;
        this.showErrorDialog = true;
        return;
      }
      var meeting_moment = moment(this.editedItem.meeting_date);
      if (
        meeting_moment.isAfter(moment()) ||
        meeting_moment.isBefore("1980-01-01")
      ) {
        // alert('The date here seems well off, either its in the future or before 1980, try again.')
        this.errorSummary =
          "The date here seems well off, either its in the future or before 1980, try again.";
        this.showErrorDialog = true;
        if (!this.isFormValid) {
          this.isSaving = false; // Re-enable the button if form is not valid
          return;
        }
        return;
      }
      var distance_check = this.editedItem.distance["@_metres"].toString();
      if (
        !this.isNumeric(distance_check) ||
        parseInt(distance_check) < 400 ||
        parseInt(distance_check) > 6500
      ) {
        // alert('Distance is not valid, please input a number between 400 and 6500')
        this.errorSummary =
          "Distance is not valid, please input a number between 400 and 6500";
        this.showErrorDialog = true;

        return;
      }
      // Update the event duration handling
      if (this.editedItem.event_duration) {
        const formattedTime = this.checkTime(this.editedItem.event_duration);
        if (formattedTime) {
          this.editedItem.event_duration = formattedTime;
        } else {
          this.errorSummary =
            "Unable to parse the duration. Please check your input.";
          this.showErrorDialog = true;
          return;
        }
      }
      if (
        this.offMarginIsBad(this.editedItem.official_margin_1) ||
        this.offMarginIsBad(this.editedItem.official_margin_2)
      ) {
        console.log(this.editedItem.official_margin_1);
        console.log(this.editedItem.official_margin_2);
        this.errorSummary =
          "Please double check the official_margins, they need to match the dropdown text, or be a valid number/decimal (.3, .5, .8)";
        this.showErrorDialog = true;
        return;
      }

      // if (
      //   !this.editedItem.track["@_country"] ||
      //   this.editedItem.track["@_country"].trim() === ""
      // ) {
      //   //API call to get track info
      //   const response = await API.get("MrCenApiGateway", `/track`, {
      //     queryStringParameters: {
      //       track_id: this.editedItem.track["@_id"],
      //     },
      //   });

      //   if (response && response.data && response.data.TRK_COUNTRY_OF_TRACK) {
      //     this.editedItem.track["@_country"] =
      //       response.data.TRK_COUNTRY_OF_TRACK;
      //   } else {
      //     this.errorSummary =
      //       "Please select track Or the track you selected does not have a country in DB";
      //     this.showErrorDialog = true;
      //     return;
      //   }
      // }
      // console.log('this.editedItem.track["@_country"]');
      // console.log(this.editedItem.track["@_country"]);
      // if (
      //   this.editedItem.track["@_country"] &&
      //   !["AUS", "HK", "NZ", "SGP"].includes(
      //     this.editedItem.track["@_country"].toUpperCase()
      //   )
      // ) {
      //   const hasDuplicates = await this.runScript();
      //   if (hasDuplicates) {
      //     this.showResultsDialog = true;
      //     return;
      //   }
      // }

      try {
        this.isSaving = true; // Disable the button
        await this.saveForm();
      } catch (error) {
        this.isSaving = false; // Enable the button
        console.error("Failed to save form:", error);
      } finally {
        this.isSaving = false;
      }
    },
    async saveForm() {
      console.log(this.editedItem.meeting_date);
      if (this.editedItem.meeting_id === "") {
        await API.post("MrCenApiGateway", `/horse-form`, {
          body: this.editedItem,
        }).then(() => {
          this.$toast.add({
            severity: "success",
            summary: `the form item for ${this.editedItem.horse_id} on ${this.editedItem.meeting_date} was created`,
            life: 5000,
          });
        });
      } else {
        await API.put("MrCenApiGateway", `/horse-form`, {
          body: this.editedItem,
        }).then(() => {
          this.$toast.add({
            severity: "success",
            summary: `the form item for ${this.editedItem.horse_id} on ${this.editedItem.meeting_date} was updated`,
            life: 5000,
          });
        });
      }
      this.closeForm();
    },
    closeForm() {
      this.isVisible = false;
      this.$emit("visible", false);
    },
    getTrainerById(trainerId) {
      return this.commonEntities.allTrainers.find((t) => t.value === trainerId)
        .label;
    },
    getJockeyById(jockeyId) {
      return this.commonEntities.allJockeys.find((t) => t.value === jockeyId)
        .label;
    },
    getClassById(classId) {
      return this.commonEntities.allClasses.find((t) => t.value === classId)
        .label;
    },
    searchCountry(event) {
      this.filteredCountries = this.commonEntities.allCountries.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    searchTrack(event) {
      this.filteredTracks = this.commonEntities.allTracks.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    searchClass(event) {
      this.filteredClasses = this.commonEntities.allClasses.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    searchTrainer(event) {
      this.filteredTrainers = this.commonEntities.allTrainers.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    searchJockey(event) {
      this.filteredJockeys = this.commonEntities.allJockeys.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    searchMargin(event) {
      this.filteredMargins = this.official_margins.filter((t) => {
        return t.label.toLowerCase().includes(event.query.toLowerCase());
      });
    },
    async searchHorse(event) {
      setTimeout(async () => {
        if (!event.query.trim().length) {
          this.filteredHorses = [];
        } else {
          this.filteredHorses = await API.get(
            "MrCenApiGateway",
            `/admin/search/horse?s=${event.query}`
          ).then((response) => {
            return response;
          });
        }
      }, 250);
    },
    convertObject(object, key) {
      return object[key];
    },
    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    },
    checkTime(str) {
      // Return false if str is undefined, null, or empty
      if (!str || typeof str !== "string" || str.trim().length === 0) {
        return false;
      }

      if (str.match(/^\d{1,2}:\d{1,2}\.\d{1,3}$/)) {
        return str;
      }

      // Check for HH:MM:SS.mmm format
      const matchLong = str.match(/^(\d{1,3}):(\d{1,3}):(\d{1,3})\.(\d{1,3})$/);
      if (matchLong) {
        let [, hours, minutes, seconds, milliseconds] = matchLong;
        hours = parseInt(hours, 10);
        minutes = parseInt(minutes, 10) + hours * 60;
        seconds = parseInt(seconds, 10);
        milliseconds = milliseconds.slice(0, 2); // Take the first two digits

        return `${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}.${milliseconds.padEnd(2, "0")}`;
      }

      // Check for MM:SS:hh format
      const match = str.match(/^(\d{1,3}):(\d{1,2}):(\d{1,3})$/);
      if (!match) {
        return false;
      }

      // Extract minutes, seconds, and hundredths
      let [, minutes, seconds, hundredths] = match;

      // Convert to integers for further processing
      minutes = parseInt(minutes, 10);
      seconds = parseInt(seconds, 10);
      hundredths = hundredths.slice(0, 2); // Take the first two digits

      // Ensure seconds and hundredths are within valid ranges
      if (seconds >= 60) {
        return false;
      }

      // Format the result as MM:SS.hh
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}.${hundredths.padEnd(2, "0")}`;
    },
    offMarginIsBad(margin) {
      let marginBad = false;
      if (!margin) return false;
      if (margin.value) margin = margin.value;
      margin = margin.toString();
      if (margin.value) margin = margin.value;
      margin = margin.toString();
      if (
        this.isNumeric(margin) &&
        margin.split(".")[1] &&
        (margin.split(".")[1].length > 1 ||
          ["1", "2", "4", "6", "7", "9"].includes(margin.split(".")[1]))
      ) {
        marginBad = true;
      }

      if (
        !this.isNumeric(margin) &&
        ![
          "DH",
          "NS",
          "SHH",
          "HH",
          "SH",
          "HD",
          "LH",
          "HN",
          "SN",
          "NK",
          "LN",
        ].includes(margin)
      ) {
        marginBad = true;
        console.log("marginBad", margin);
      }

      return marginBad;
    },
    generatePositionOptions() {
      const numericOptions = Array.from({ length: 24 }, (_, i) => ({
        label: (i + 1).toString(),
        value: i + 1,
      }));

      const specialOptions = [
        { label: "FF", value: 25 },
        { label: "FL", value: 27 },
        { label: "RO", value: 28 },
        { label: "DQ", value: 29 },
        { label: "NP", value: 30 },
        { label: "LR", value: 32 },
        { label: "BD", value: 35 },
        { label: "UN", value: 36 },
      ];
      this.positionOptions = [...numericOptions, ...specialOptions];
    },
  },
  watch: {
    initialItem(val) {
      this.editedItem = val;
      if (!this.editedItem.horse_id) {
        this.editedItem = {
          barrier: 0,
          beaten_margin: "",
          classes: {
            class: "",
            class_id: 0,
          },
          days_since_last_run: 0,
          decimalprices: {
            "@_opening": 0,
            "@_mid": 0,
            "@_starting": 0,
          },
          distance: {
            "@_metres": 0,
          },
          distance_metres: 0,
          event_prizemoney: 0,
          finish_position: 0,
          group: 0,
          horse_id: 0,
          horse_prizemoney: 0,
          jockey: {
            "@_firstname": "",
            "@_id": 0,
            "@_name": "",
            "@_surname": "",
          },
          margin: "",
          meeting_date: "1900-01-01",
          meeting_id: "",
          official_margin_1: "",
          official_margin_2: "",
          other_runners: {
            other_runner: [
              {
                "@_country": "",
                "@_horse": "",
                "@_jockey": "",
                "@_margin": "",
                "@_position": 0,
                "@_weight": 0,
              },
              {
                "@_country": "",
                "@_horse": "",
                "@_jockey": "",
                "@_margin": "",
                "@_position": 0,
                "@_weight": 0,
              },
              {
                "@_country": "",
                "@_horse": "",
                "@_jockey": "",
                "@_margin": "",
                "@_position": 0,
                "@_weight": 0,
              },
              {
                "@_country": "",
                "@_horse": "",
                "@_jockey": "",
                "@_margin": "",
                "@_position": 0,
                "@_weight": 0,
              },
            ],
          },
          positions: {
            "@_finish": 0,
            "@_m1200": 0,
            "@_m400": 0,
            "@_m800": 0,
            "@_settling_down": 0,
          },
          prices: {
            "@_opening": "",
            "@_mid": "",
            "@_starting": "",
          },
          race: {
            "@_name": "",
            "@_number": 0,
          },
          rail_position: "",
          rating: "",
          restrictions: {
            "@_age": 0,
            "@_jockey": "",
            "@_sex": 0,
          },
          sectional: {
            "@_distance": 0,
            "@_location": "",
            "@_time": 0,
          },
          stewards_report: ["", "", ""],
          track: {
            "@_condition": "",
            "@_grading": "",
            "@_id": 0,
            "@_location": "",
            "@_name": "",
            "@_track_3char_abbrev": "",
            "@_track_4char_abbrev": "",
            "@_track_6char_abbrev": "",
            "@_track_surface": "",
          },
          track_name: "",
          trainer_id: 0,
          weight_carried: 0,
          ran_gear: "",
          gear_changes: "",
        };
      } else {
        if (this.editedItem.gear_changes !== undefined) {
          var theGear = "";
          if (
            this.editedItem.gear_changes.gear_change &&
            this.editedItem.gear_changes.gear_change[0]
          ) {
            var j = 0;
            for (var gearItem of this.editedItem.gear_changes.gear_change) {
              if (gearItem["@_name"] && gearItem["@_option"]) {
                if (j > 0) {
                  theGear = theGear + ";";
                }
                theGear =
                  theGear + gearItem["@_name"] + ":" + gearItem["@_option"];
                j++;
              } else if (gearItem["@_name"]) {
                if (j > 0) {
                  theGear = theGear + ";";
                }
                theGear = theGear + gearItem["@_name"];
                j++;
              }
            }
            this.editedItem.gear = theGear;
          }
        }
        if (this.editedItem.running_gear !== undefined) {
          if (
            this.editedItem.running_gear.gear_item &&
            this.editedItem.running_gear.gear_item[0]
          ) {
            this.editedItem.ran_gear =
              this.editedItem.running_gear.gear_item.join(";");
          }
        }
        if (
          this.editedItem.group !== undefined &&
          ["1", "2", "3"].includes(this.editedItem.group)
        ) {
          this.editedItem.group = parseInt(this.editedItem.group);
        }
        if (
          this.editedItem.finish_position !== undefined &&
          this.isNumeric(this.editedItem.finish_position)
        ) {
          this.editedItem.finish_position = parseInt(this.editedItem.finish_position);
        }
      }
      console.log(this.editedItem);
    },
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      this.$emit("visible", val);
    },
  },
  computed: {
    isFormValid() {
      const isValid = this.isHorseIDValid(this.editedItem.horse_id); // Add other conditions as needed
      console.log("isFormValid:", isValid);
      return isValid;
    },
    isHorseIDValidComputed() {
      return this.isHorseIDValid(this.editedItem.horse_id);
    },
    convertedFinishPosition() {
      if (typeof this.editedItem.finish_position === "number") {
        return this.editedItem.finish_position;
      } else {
        const selectedOption = this.positionOptions.find(
          (option) => option.label === this.editedItem.finish_position
        );
        return selectedOption ? selectedOption.value : null;
      }
    },
  },
  mounted() {},
  created() {
    this.generatePositionOptions();
  },
};
</script>

<style scoped>
.duplicate-race {
  background-color: #ffcccc;
}
.p-fluid .invalid-field {
  border-color: red;
  background-color: #ffe6e6;
}
</style>
