<template>
  <section class="multi-select">
    <h3>{{title}}</h3>
          <ul>
            <li v-for="item in items" :key="item" class="item">
              <input type="checkbox" :id="item" :value="item" v-model="selItems" @change="handleItems(item)">
              <label :for="item" class="item-label">{{item}}</label>
            </li>
          </ul>
  </section>

</template>

<script>
  export default  {
    name: 'multiSelect',
    mounted () {
    },
    data: function () {
        return {
            selItems: this.selectedItems
        }
    },
    props:{
     items: null,
     title: null,
     selectedItems: null
    },
    emits: ['changedItems'],
    methods: {
      // eslint-disable-next-line
      handleItems(item){
       this.$emit("changedItems", this.selItems)
      }
    },
    computed: {

    }
}


</script>

<style>
  .item {
    margin: 5px;
  }
  .item-label{
    margin-left:10px
  }

  ul{
    list-style-type: none;
  }

  input[type=checkbox] {
    transform: scale(1.5);
  }
</style>
